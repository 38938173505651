import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import iconUser from '../../assets/img/icon-user.svg';
import iconCart from '../../assets/img/icon-cart.svg';
import LoginSubMenu from './LoginSubMenu';
import { useCart } from '../../contexts/cart.context';
import { useAuth } from '../../contexts/auth.context';
import useWindowSize from '../../hooks/useWindowSize';
import { HashLink } from 'react-router-hash-link';
import { useLang } from './../../contexts/lang.context';
// import { useSearch } from './../../contexts/search.context';

export default function Functions(props) {
  const history = useHistory();
  const { mediaMode } = useWindowSize();
  const { lang } = useLang();
  const { cart } = useCart();
  const [cartCounter, setCartCounter] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState('');
  const { currentUser, userIsAdmin } = useAuth();

  const text = {
    SIGNIN: {
      langTC: '登入',
      langSC: '登入',
      langEN: 'Sign In',
    },
    USER: {
      langTC: '帳戶',
      langSC: '帐户',
      langEN: 'User',
    },
    CART: {
      langTC: '購物籃',
      langSC: '购物篮',
      langEN: 'Cart',
    },
    SEARCH: {
      langTC: '搜尋',
      langSC: '搜寻',
      langEN: 'Search',
    },
  };

  useEffect(() => {
    if (!cart) return setCartCounter(0);

    setCartCounter(cart.length);
  }, [cart]);

  function handleSubmitSearch(e) {
    e.preventDefault();
    history.push(`/products?search=${searchKeyword}`);
  }

  return (
    <Wrapper {...props}>
      {/* {userIsAdmin && (
        <div>
          <HashLink to='/admin' target='_blank'>
            CMS
          </HashLink>
        </div>
      )} */}

      <div>
        <IconBtn className='signin'>
          {/* <div> */}
          <HashLink to={`${currentUser ? '/myaccount#' : '/signin#'}`}>
            <img src={iconUser} alt='' />

            {mediaMode !== 'mobile' && mediaMode !== 'tablet' && (
              <span>
                {currentUser
                  ? text.USER[`lang${lang}`]
                  : text.SIGNIN[`lang${lang}`]}
              </span>
            )}
          </HashLink>
          <LoginSubMenu className='login__subMenu' />
          {/* </div> */}
        </IconBtn>
      </div>

      <HashLink to='/cart#'>
        <IconBtn>
          <img src={iconCart} alt='' />
          {cartCounter > 0 && <span className='badge'>{cartCounter}</span>}

          {mediaMode !== 'mobile' && mediaMode !== 'tablet' && (
            <span>{text.CART[`lang${lang}`]}</span>
          )}
        </IconBtn>
      </HashLink>

      {mediaMode !== 'mobile' && mediaMode !== 'tablet' && (
        <form onSubmit={handleSubmitSearch}>
          <SearchBar
            placeholder={text.SEARCH[`lang${lang}`]}
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
        </form>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  a {
    color: var(--color-default-text);
    text-decoration: none;
  }
`;

const IconBtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  gap: 2px;
  position: relative;

  img {
    height: 30px;
  }

  span {
    font-size: 1.3rem;
    font-weight: 300;
  }

  &.signin {
    position: relative;

    &:hover .login__subMenu {
      display: block;
    }

    a {
      text-align: center;
      /* background-color: yellow; */
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;
    }
  }

  .badge {
    position: absolute;
    background-color: red;
    color: white;
    border-radius: 50%;
    top: -6px;
    right: -7px;
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login__subMenu {
  }
`;

const SearchBar = styled.input`
  width: 160px;
  font-style: 1.6rem;
  padding: 3px 10px;
  border: 1px solid #58595b;
  border-radius: 5px;
`;
