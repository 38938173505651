import { HashLink } from 'react-router-hash-link';

import { useLang } from './../../contexts/lang.context';
import iconFb from '../../assets/img/icon-fb.svg';
import iconIns from '../../assets/img/icon-ins.svg';
import iconStripe from '../../assets/img/icon-stripe.svg';
import './styles.scss';

export default function FooterBar() {
  const { lang } = useLang();

  const text = {
    HOME: {
      langTC: '主頁',
      langSC: '主页',
      langEN: 'HOME',
    },
    ABOUT_US: {
      langTC: '關於我們',
      langSC: '关于我们',
      langEN: 'ABOUT US',
    },
    NEWS: {
      langTC: '最新消息',
      langSC: '最新消息',
      langEN: 'NEWS',
    },
    CONTACT_US: {
      langTC: '聯絡我們',
      langSC: '联络我们',
      langEN: 'CONTACT US',
    },
    DELIVERY_METHOD: {
      langTC: '送貨方式',
      langSC: '送货方式',
      langEN: 'DELIVERY METHOD',
    },
    PRIVACY_POLICY: {
      langTC: '隱私政策',
      langSC: '隐私政策',
      langEN: 'Privacy Policy',
    },
    RETURN_POLICY: {
      langTC: '退貨政策',
      langSC: '退货政策',
      langEN: 'Return Policy',
    },
    TERMS_AND_CONDITIONS: {
      langTC: '服務條款',
      langSC: '服务条款',
      langEN: 'Terms Of Service',
    },
    NOTICE_OF_DISCLAIMER: {
      langTC: '免責聲明',
      langSC: '免责声明',
      langEN: 'Notice Of Disclaimer',
    },
    ABOUT_DELIVERY: {
      langTC: '關於送貨',
      langSC: '关于送货',
      langEN: 'About Delivery',
    },
    COPYRIGHT_INFO: {
      langTC: '版權資訊',
      langSC: '版权资讯',
      langEN: 'Copyright Information',
    },
    FOLLOW_US: {
      langTC: '追蹤我們',
      langSC: '追踪我们',
      langEN: 'Follow us',
    },
    PAYMENT_BY: {
      langTC: '收費系統',
      langSC: '收费系统',
      langEN: 'Payment by',
    },
    ADDRESS: {
      langTC: '香港九龍九龍灣宏通街1號啟福工業中心地舖1號',
      langSC: '香港九龙九龙湾宏通街1号启福工业中心地铺1号',
      langEN:
        'Unit 1, G/F., Kai Fuk Industrial Centre, 1 Wang Tung Street, Kowloon Bay, Kowloon, Hong Kong',
    },
    TEL: {
      langTC: '電話:',
      langSC: '电话:',
      langEN: 'Tel:',
    },
    FAX: {
      langTC: '傳真:',
      langSC: '传真:',
      langEN: 'Fax:',
    },
    EMAIL: {
      langTC: '電郵:',
      langSC: '电邮:',
      langEN: 'Email:',
    },
  };

  return (
    <div id='pageFooter'>
      <section className='links'>
        <div className='linksWrapper'>
          <div className='largeLinks'>
            <HashLink to='/#'>{text.HOME[`lang${lang}`]}</HashLink>
            &nbsp;/&nbsp;
            <HashLink to='/aboutus#'>{text.ABOUT_US[`lang${lang}`]}</HashLink>
            &nbsp;/&nbsp;
            <HashLink to='/news#'>{text.NEWS[`lang${lang}`]}</HashLink>
            &nbsp;/&nbsp;
            <HashLink to='/contact#'>{text.CONTACT_US[`lang${lang}`]}</HashLink>
            &nbsp;/&nbsp;
            <HashLink to='/deliverymethod#'>
              {text.DELIVERY_METHOD[`lang${lang}`]}
            </HashLink>
          </div>
          <div className='smallLinks'>
            <HashLink to='/privacypolicy#'>
              {text.PRIVACY_POLICY[`lang${lang}`]}
            </HashLink>
            &nbsp;/&nbsp;
            <HashLink to='/returnpolicy#'>
              {text.RETURN_POLICY[`lang${lang}`]}
            </HashLink>
            &nbsp;/&nbsp;
            <HashLink to='/termsconditions#'>
              {text.TERMS_AND_CONDITIONS[`lang${lang}`]}
            </HashLink>
            {/*
            &nbsp;/&nbsp;
            <HashLink to='/disclaimer#'>
              {text.NOTICE_OF_DISCLAIMER[`lang${lang}`]}
            </HashLink>
            &nbsp;/&nbsp; */}
            {/* <HashLink to='/aboutdelivery#'>
              {text.ABOUT_DELIVERY[`lang${lang}`]}
            </HashLink>
            &nbsp;/&nbsp; */}
            {/* <HashLink to='/copyrightinfo#'>
              {text.COPYRIGHT_INFO[`lang${lang}`]}
            </HashLink> */}
          </div>
        </div>

        <div className='supportsWrapper'>
          <div>
            <span>Payment by</span>
            <a href='https://stripe.com' target='_blank' rel='noreferrer'>
              <img
                src={iconStripe}
                alt='Stripe Payment'
                className='iconStripe'
              />
            </a>
          </div>
          <div>
            <span>Follow us</span>
            <a
              href='https://www.facebook.com/Fordex-Electric-Co-Ltd-624889564228523/'
              target='_blank'
              rel='noreferrer'
            >
              <img src={iconFb} alt='Stripe Payment' className='iconFb' />
            </a>
            <a
              href='https://instagram.com/fordex_electric_hk?igshid=YmMyMTA2M2Y'
              target='_blank'
              rel='noreferrer'
            >
              <img src={iconIns} alt='Stripe Payment' className='iconIns' />
            </a>
          </div>
        </div>
      </section>

      <section className='contactInfo'>
        <span>{text.ADDRESS[`lang${lang}`]}</span>
        <span>{text.TEL[`lang${lang}`]}&nbsp;+852 3188 4612</span>
        <span>{text.FAX[`lang${lang}`]}&nbsp;+852 3188 4611</span>
        <span>
          {text.EMAIL[`lang${lang}`]}&nbsp;
          <a href='mailto:sales@fordexelectric.com'>sales@fordexelectric.com</a>
        </span>
      </section>

      <section className='copyright'>
        Copyright &copy; {new Date().getFullYear()} Ebasell.com, a subsidiary of
        Fordex Electric Company Limited. All rights reserved.
      </section>
    </div>
  );
}
