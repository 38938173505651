import './styles.scss';

export default function PopupBox({
  children,
  handleClosePopupBox,
  backdropClickable = true,
}) {
  function handleBackdropOnClick(e) {
    if (!backdropClickable) return;

    if (e.target.className === 'backdrop') handleClosePopupBox();
  }

  return (
    // <div id='popupBox' className={`${show ? 'show' : ''}`}>
    <div id='popupBoxb'>
      <div className='backdrop' onClick={handleBackdropOnClick}>
        <div className='foreground'>
          <div className='foreground__closeBtn' onClick={handleClosePopupBox}>
            &#10006;
          </div>

          <div className='foreground__canvas'>{children}</div>
        </div>
      </div>
    </div>
  );
}
