import { useState, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useHistory } from 'react-router-dom';
import Recaptcha from 'react-recaptcha';

import { useLang } from '../../contexts/lang.context';
import { useAuth } from '../../contexts/auth.context';
import { setDbData } from '../../utils';
import Button from '../../components/form/Button';
import TextInput from '../../components/form/TextInput';
import Dropdown from '../../components/form/Dropdown';
import text from './text';
import countries from '../../data/countries.json';
import './styles.scss';

export default function SignUp() {
  const history = useHistory();
  const { lang } = useLang();
  const formRef = useRef();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [locationTelCode, setLocationTelCode] = useState('');
  const [tel, setTel] = useState('');
  const [mobile, setMobile] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyTel, setCompanyTel] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressLine3, setAddressLine3] = useState('');
  // const [district, setDistrict] = useState('');
  const [region, setRegion] = useState('');
  const [country, setCountry] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const { signUp } = useAuth();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function showParagraph1() {
    switch (lang) {
      case 'TC':
        return (
          <div>
            已有帳戶？<HashLink to='/signin'>按此登入</HashLink>。
          </div>
        );
      case 'SC':
        return (
          <div>
            已有帐户？<HashLink to='/signin'>按此登入</HashLink>。
          </div>
        );
      default:
        return (
          <div>
            Already have an account?{' '}
            <HashLink to='/signin'>Sign in here</HashLink>.
          </div>
        );
    }
  }

  const recaptchaLoaded = () => {
    // console.log('recaptcha loaded');
  };

  const verifyCallback = (response) => {
    if (response) {
      // console.log(response);
      setIsVerified(true);
    }
  };

  async function handleFormSubmit(e) {
    e.preventDefault();

    setError('');
    setIsLoading(true);

    if (password !== confirmPassword) {
      const error = {
        langTC: '密碼與再次輸入密碼必須相同。',
        langSC: '密码与再次输入密码必须相同。',
        langEN: 'Password and Confirm Password must be same.',
      };
      setError(error[`lang${lang}`]);
      setIsLoading(false);
      return formRef.current.scrollIntoView();
    }

    if (isVerified) {
      try {
        const result = await signUp(email, password);

        saveUserInfo(result.uid);

        history.push('/myaccount');
      } catch (error) {
        setError(error.message);
        return formRef.current.scrollIntoView();
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function saveUserInfo(userId) {
    if (!userId) return;

    const newData = {
      userId: userId,
      createdAt: Date.now(),
      displayName: '',
      firstName,
      lastName,
      email,
      locationTelCode,
      tel,
      mobile,
      companyName,
      companyTel,
      addressLine1,
      addressLine2,
      addressLine3,
      region,
      country,
      roles: ['user'],
      isDisable: false,
    };

    try {
      await setDbData({
        collection: 'users',
        docId: userId,
        data: newData,
      });
    } catch (error) {
      setError(error.message);
    }
  }

  return (
    <div id='pageSignUp' className='container'>
      <div className='row'>
        <h1 className='pageTitle'>{text.PAGE_TITLE[`lang${lang}`]}</h1>

        <div className='formWrapper' ref={formRef}>
          {error && <div className='messageBox'>{error}</div>}

          <form onSubmit={handleFormSubmit}>
            <div className='remarks'>
              {text.MUST_BE_COMPLETED[`lang${lang}`]}
            </div>

            <h3>{text.CONTACT_INFORMATION[`lang${lang}`]}</h3>

            <div className='formGroup'>
              <TextInput
                type='text'
                placeholder={`${text.FIRST_NAME[`lang${lang}`]} *`}
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                required
                autoFoucs
              />
              <TextInput
                type='text'
                placeholder={`${text.LAST_NAME[`lang${lang}`]} *`}
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                required
              />
            </div>

            <div className='formGroup'>
              <TextInput
                type='email'
                placeholder={`${text.EMAIL[`lang${lang}`]} *`}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                autoFoucs
              />
            </div>

            <div className='formGroup'>
              {/* <div style={{ width: '120px' }}>
                <TextInput
                  type='text'
                  placeholder='+852'
                  onChange={(e) => setLocationTelCode(e.target.value)}
                  value={locationTelCode}
                />
              </div> */}
              <TextInput
                type='tel'
                placeholder={text.TEL[`lang${lang}`]}
                onChange={(e) => setTel(e.target.value)}
                value={tel}
              />
            </div>

            <br />
            <h3>{text.COMPANY_INFORMATION[`lang${lang}`]}</h3>

            <div className='formGroup'>
              <TextInput
                type='text'
                placeholder={`${text.COMPANY_NAME[`lang${lang}`]}`}
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName}
              />
            </div>

            <div className='formGroup'>
              <TextInput
                type='tel'
                placeholder={`${text.COMPANY_TELEPHONE[`lang${lang}`]}`}
                onChange={(e) => setCompanyTel(e.target.value)}
                value={companyTel}
              />
            </div>

            <br />
            <h3>{text.ADDRESS[`lang${lang}`]}</h3>

            <div className='formGroup'>
              <Dropdown
                options={countries}
                id='code'
                label='name'
                prompt={text.COUNTRY[`lang${lang}`]}
                value={country}
                onChange={(val) => setCountry(val)}
              />
            </div>

            <div className='formGroup'>
              <TextInput
                type='text'
                placeholder={text.ADDRESS_LINE_1[`lang${lang}`]}
                onChange={(e) => setAddressLine1(e.target.value)}
                value={addressLine1}
              />
            </div>

            <div className='formGroup'>
              <TextInput
                type='text'
                placeholder={text.ADDRESS_LINE_2[`lang${lang}`]}
                onChange={(e) => setAddressLine2(e.target.value)}
                value={addressLine2}
              />
            </div>

            <div className='formGroup'>
              <TextInput
                type='text'
                placeholder={text.ADDRESS_LINE_3[`lang${lang}`]}
                onChange={(e) => setAddressLine3(e.target.value)}
                value={addressLine3}
              />
            </div>

            {/* <div className='formGroup'>
              <TextInput
                type='text'
                placeholder={text.REGION[`lang${lang}`]}
                onChange={(e) => setRegion(e.target.value)}
                value={region}
              />
            </div> */}

            <br />

            <div className='remarksx'>
              {text.CHARACTER_LENGTH[`lang${lang}`]}
            </div>

            <div className='formGroup'>
              <TextInput
                type='password'
                placeholder={`${text.PASSWORD[`lang${lang}`]} *`}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                minLength='8'
                maxLength='20'
              />
            </div>

            <div className='formGroup'>
              <TextInput
                type='password'
                placeholder={`${text.CONFIRM_PASSWORD[`lang${lang}`]} *`}
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                required
                minLength='8'
                maxLength='20'
              />
            </div>

            <div className='formGroup'>
              <div className='recaptcha'>
                <Recaptcha
                  sitekey='6LeeNlocAAAAACefFS5vvWLowerLqD59vupYPgl9'
                  render='explicit'
                  onloadCallback={recaptchaLoaded}
                  verifyCallback={verifyCallback}
                />
              </div>
            </div>

            <div className='formGroup'>
              <Button
                type='submit'
                variant='contained'
                color='secondary'
                size='sm'
                fontWeight='bold'
                text={text.SUBMIT[`lang${lang}`]}
                isLoading={isLoading}
              />
            </div>
          </form>

          {showParagraph1()}
        </div>
      </div>
    </div>
  );
}
