import { useState, useEffect } from 'react';
import styled from 'styled-components';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import Button from '../../../components/form/Button';
import PopupBox from '../../../components/PopupBox';
import LoadingSpin from '../../../components/LoadingSpin';
import useListenDbList from './../../../hooks/useListenDbList';
import TransactionDetails from './../../../components/TransactionDetails/index';
import { TextInput } from '../../../components/form';

const customColumnSort = (rowA, rowB) => {
  if (rowA.createdAt > rowB.createdAt) {
    return -1;
  } else if (rowA.createdAt < rowB.createdAt) {
    return 1;
  }
  return 0;
};

function showPaymentStatus(paymentStatus) {
  switch (paymentStatus) {
    case 'bankin':
      return 'bank in';
    case 'pending':
      return 'incomplete';
    default:
      return paymentStatus;
  }
}

const columns = [
  {
    name: 'View',
    selector: (row) => row.btnView,
    maxWidth: '40px',
  },
  {
    name: 'Date',
    selector: (row) => moment(row.createdAt).format('YYYY-MM-DD HH:mm'),
    sortable: true,
  },
  {
    name: 'Transaction ID',
    selector: (row) => row.formatedTransactionId,
    // selector: (row) => row.transactionId.toUpperCase(),
    sortable: true,
  },
  {
    name: 'Contact Name',
    selector: (row) => row.contactName,
    sortable: true,
  },
  {
    name: 'User Email',
    selector: (row) => row.email,
    sortable: true,
  },
  {
    name: 'Payment Status',
    selector: (row) => showPaymentStatus(row.paymentDetails.paymentStatus),
    sortable: true,
  },
  {
    name: 'Amount',
    selector: (row) => (
      <CurrencyFormat
        value={row.amount}
        displayType={'text'}
        thousandSeparator={true}
        fixedDecimalScale
        decimalScale={2}
        prefix='$'
      />
    ),
  },
];

export default function Transactions() {
  const [isShowPopup, setIsShowPopup] = useState(false);
  // const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [searchTransactionId, setSearchTransactionId] = useState('');
  const [searchUser, setSearchUser] = useState('');

  const [transaction, setTransaction] = useState({});
  const [fullTableData, setFullTableData] = useState([]);
  const { data, fetchError, isFetching } = useListenDbList({
    collection: 'transactions',
  });

  // console.log({ searchTransactionId, searchUser });

  useEffect(() => {
    if (isFetching) return;

    let tempData = [];
    setIsLoading(true);

    // const noOfItems = data.ranges.length;

    // if (noOfItems > 0) {
    data.forEach((doc) => {
      tempData.push({
        ...doc,
        formatedTransactionId: createTransactionID(doc),
        btnView: createOpenButton(doc),
      });
    });

    tempData.sort(customColumnSort);
    setFullTableData(tempData);
    setTableData(tempData);
    setIsLoading(false);
  }, [data, isFetching]);

  useEffect(() => {
    function filterTransactionId(transaction) {
      if (!searchTransactionId) return true;
      return (
        transaction.transactionId
          .toLowerCase()
          .search(searchTransactionId.toLowerCase()) !== -1
      );
    }

    function filterUser(transaction) {
      if (!searchUser) return true;
      return (
        transaction.billingName
          ?.toLowerCase()
          .search(searchUser.toLowerCase()) !== -1 ||
        (transaction.email &&
          transaction.email?.toLowerCase().search(searchUser.toLowerCase()) !==
            -1)
      );
    }

    const filteredData = fullTableData.filter((transaction) => {
      return filterTransactionId(transaction) && filterUser(transaction);
    });

    setTableData(filteredData);
  }, [searchTransactionId, searchUser, fullTableData]);

  function createOpenButton(data) {
    return (
      <OpenInNewIcon
        fontSize='large'
        className='clickable'
        // color=''
        style={{ color: '#33a35e' }}
        onClick={() => handleViewItem(data)}
      />
    );
  }

  function createTransactionID(doc) {
    const formatedTransactionID = formatTransactionID(doc.docId);
    const output = (
      <span className='clickable' onClick={() => handleViewItem(doc)}>
        {formatedTransactionID}
      </span>
    );
    return output;
  }

  function formatTransactionID(docId) {
    const upperCaseDocId = docId.toUpperCase();

    const stringHead = upperCaseDocId.substring(0, 8);
    const stringTail = upperCaseDocId.substring(8);
    const output = (
      <>
        <Highlight>{stringHead}</Highlight>
        {stringTail}
      </>
    );
    return output;
  }

  function handleViewItem(data) {
    setTransaction(data);
    setIsShowPopup(true);
  }

  function handleClosePopupBox() {
    setIsShowPopup(false);
  }

  function handleResetSearch() {
    setSearchTransactionId('');
    setSearchUser('');
  }

  return (
    <div className='container-full'>
      <div className='row'>
        <h1>Payment Transactions</h1>

        <div className='paper mb-1'>
          <h3>Search</h3>
          <SearchArea>
            <div>
              <label>
                Transaction ID
                <TextInput
                  value={searchTransactionId}
                  onChange={(e) => setSearchTransactionId(e.target.value)}
                />
              </label>
            </div>

            <div>
              <label>
                Billing Name or Email
                <TextInput
                  value={searchUser}
                  onChange={(e) => setSearchUser(e.target.value)}
                />
              </label>
            </div>

            <div>
              <Button color='secondary' size='sm' onClick={handleResetSearch}>
                Reset
              </Button>
            </div>
          </SearchArea>
        </div>
      </div>

      {!isLoading && !isFetching ? (
        <div className='row'>
          <div className='paper'>
            <DataTable
              columns={columns}
              data={tableData}
              pagination
              defaultSortFieldId={1}
              progressPending={isLoading}
            />
          </div>
        </div>
      ) : (
        <LoadingSpin />
      )}

      {isShowPopup && (
        <PopupBox
          handleClosePopupBox={handleClosePopupBox}
          backdropClickable={true}
        >
          <TransactionDetails
            selectedLang='EN'
            data={transaction}
            handleClosePopupBox={handleClosePopupBox}
          />
        </PopupBox>
      )}
    </div>
  );
}

const SearchArea = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  align-items: flex-end;
`;

const Highlight = styled.span`
  color: var(--color-secondary-dark);
  background-color: var(--color-secondary-light);
  padding: 2px;
`;
