const text = {
  PAGE_TITLE: {
    langTC: '註冊帳戶',
    langSC: '注册帐户',
    langEN: 'SIGN UP',
  },
  MUST_BE_COMPLETED: {
    langTC: '* 必須填寫',
    langSC: '* 必须填写',
    langEN: '* must be completed',
  },
  CONTACT_INFORMATION: {
    langTC: '聯絡資料',
    langSC: '联络资料',
    langEN: 'CONTACT INFORMATION',
  },
  FIRST_NAME: {
    langTC: '名字',
    langSC: '名字',
    langEN: 'First name',
  },
  LAST_NAME: {
    langTC: '姓氏',
    langSC: '姓氏',
    langEN: 'Last name',
  },
  EMAIL: {
    langTC: '電郵地址',
    langSC: '电邮地址',
    langEN: 'Email',
  },
  TEL: {
    langTC: '聯絡電話',
    langSC: '联络电话',
    langEN: 'Contact phone',
  },
  MOBILE: {
    langTC: '手提電話',
    langSC: '手提电话',
    langEN: 'Mobile',
  },
  COMPANY_INFORMATION: {
    langTC: '公司資料',
    langSC: '公司资料',
    langEN: 'COMPANY INFORMATION',
  },
  COMPANY_NAME: {
    langTC: '公司名稱',
    langSC: '公司名称',
    langEN: 'Company name',
  },
  COMPANY_TELEPHONE: {
    langTC: '公司電話',
    langSC: '公司电话',
    langEN: 'Company telephone',
  },
  ADDRESS: {
    langTC: '地址',
    langSC: '地址',
    langEN: 'ADDRESS',
  },
  ADDRESS_LINE_1: {
    langTC: '地址行1',
    langSC: '地址行1',
    langEN: 'Address line 1',
  },
  ADDRESS_LINE_2: {
    langTC: '地址行2',
    langSC: '地址行2',
    langEN: 'Address line 2',
  },
  ADDRESS_LINE_3: {
    langTC: '地址行3',
    langSC: '地址行3',
    langEN: 'Address line 3',
  },
  DISTRICT: {
    langTC: '區',
    langSC: '区',
    langEN: 'District',
  },
  REGION: {
    langTC: '地區',
    langSC: '地区',
    langEN: 'Region',
  },
  COUNTRY: {
    langTC: '國家或地區',
    langSC: '国家或地区',
    langEN: 'Country/Location',
  },
  SELECT_COUNTRY: {
    langTC: '選擇國家或地區...',
    langSC: '选择国家或地区...',
    langEN: 'Select country/location...',
  },
  CHARACTER_LENGTH: {
    langTC: '長度必須於8至20個字符之間',
    langSC: '长度必须于8至20个字符之间',
    langEN: 'Must be 8 to 20 character length',
  },
  PASSWORD: {
    langTC: '密碼',
    langSC: '密码',
    langEN: 'Password',
  },
  CONFIRM_PASSWORD: {
    langTC: '再次輸入密碼',
    langSC: '再次输入密码',
    langEN: 'Confirm password',
  },
  SUBMIT: {
    langTC: '送出',
    langSC: '送出',
    langEN: 'SUBMIT',
  },
};

export default text;