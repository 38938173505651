const text = {
  PAGE_TITLE: {
    langTC: '聯絡我們',
    langSC: '联络我们',
    langEN: 'CONTACT US',
  },
  MUST_BE_COMPLETED: {
    langTC: '* 必須填寫',
    langSC: '* 必须填写',
    langEN: '* must be completed',
  },
  COMPANY_NAME: {
    langTC: '公司名稱',
    langSC: '公司名称',
    langEN: 'COMPANY NAME',
  },
  COMPANY_NAME2: {
    langTC: '公司名稱',
    langSC: '公司名称',
    langEN: 'Company Name',
  },
  CONTACT_INFO: {
    langTC: '聯絡人資料',
    langSC: '联络人资料',
    langEN: 'CONTACT INFORMATION',
  },
  FIRST_NAME: {
    langTC: '名字',
    langSC: '名字',
    langEN: 'First Name',
  },
  LAST_NAME: {
    langTC: '姓氏',
    langSC: '姓氏',
    langEN: 'Last Name',
  },
  EMAIL: {
    langTC: '電郵',
    langSC: '电邮',
    langEN: 'Email',
  },
  TEL: {
    langTC: '電話',
    langSC: '电话',
    langEN: 'Contact Phone',
  },
  MESSAGE: {
    langTC: '訊息內容',
    langSC: '讯息内容',
    langEN: 'MESSAGE',
  },
  SUBMIT: {
    langTC: '發送',
    langSC: '发送',
    langEN: 'SUBMIT',
  },
  RECAPTCHA_FAILED: {
    langTC: `請勾選"我不是機械人(I'm not a robot)"。`,
    langSC: `请勾选"我不是机械人(I'm not a robot)"。`,
    langEN: `Please check "I'm not a robot".`,
  },
  SEND_MESSAGE_SUCCESSED: {
    langTC: '謝謝您的查詢! 訊息已成功發出。',
    langSC: '谢谢您的查询! 讯息已成功发出。',
    langEN: 'Message has been sent. Thank you for your message!',
  },
  SEND_MESSAGE_FAILED: {
    langTC: '訊息發送失敗! 請稍後再試。',
    langSC: '讯息发送失败! 请稍后再试。',
    langEN: 'Failed to send your message, please try again later.',
  },
};

export default text;