import { useState, useRef, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import { useLang } from './../../contexts/lang.context';
// import categories from '../../data/categories';
import useGetDbDoc from './../../hooks/useGetDbDoc';

export default function CategorySubMenu() {
  const { lang } = useLang();
  const menuRef = useRef();
  const [imgUrl, setImgUrl] = useState('');
  // const [show, setShow] = useState(showSubMenu);
  const [rangesList, setRangesList] = useState([]);
  const { data, isFetching } = useGetDbDoc({
    collection: 'ranges',
    docId: '001',
  });

  useEffect(() => {
    if (isFetching) return;

    let tempList = [];

    data.ranges.forEach((range) => {
      tempList.push(range);
    });

    setRangesList(tempList);
  }, [isFetching]);

  function handleLinkHover(imgUrl) {
    setImgUrl(imgUrl);
  }

  function handleLinkClick() {
    menuRef.current.classList.add('hidden');
  }

  const noOfRanges = rangesList.length;
  const spliteAt = Math.ceil(noOfRanges / 2);

  return (
    <Container ref={menuRef}>
      <Wrapper>
        <Ul>
          {rangesList.slice(0, spliteAt).map((range, index) => (
            <li key={index}>
              <HashLink
                to={`/products?range=${range.uid}`}
                onMouseOver={() => handleLinkHover(range.imageUrl)}
                onClick={handleLinkClick}
              >
                {range.name[`lang${lang}`]}
              </HashLink>
            </li>
          ))}
        </Ul>

        <Ul>
          {rangesList.slice(spliteAt, noOfRanges).map((range, index) => (
            <li key={index}>
              <HashLink
                to={`/products?range=${range.uid}`}
                onMouseOver={() => handleLinkHover(range.imageUrl)}
                onClick={handleLinkClick}
              >
                {range.name[`lang${lang}`]}
              </HashLink>
            </li>
          ))}
        </Ul>

        <ImgContainer>
          {/* <span>Test Image</span> */}
          {imgUrl && <img src={imgUrl} alt='' />}
        </ImgContainer>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  width: 1200px;
  background-color: white;
  box-shadow: var(--shadow-1);
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  padding: 15px 20px;
  display: none;

  &.hidden {
    /* visibility: hidden; */
    display: none;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 320px;
  gap: 20px;
`;

const Ul = styled.ul`
  list-style: disc;
  margin-left: 20px;

  li {
    padding: 3px 0;
    font-size: 1.6rem;

    a {
      display: block;
    }
    a:hover {
      color: var(--color-primary);
    }
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 100%;
  /* background-color: #ddd; */
  border-left: 1px solid #818284;
  padding: 10px 10px 10px 30px;
  position: relative;

  span {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    font-weight: bold;
    color: white;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
`;
