export default function outputRangeName({ ranges, uid, lang = 'EN' }) {
  const indexOfRanges = Array.isArray(ranges.ranges)
    ? ranges.ranges.findIndex((range) => range.uid === uid)
    : -1;

  // Range uid dosen't match
  if (indexOfRanges <= -1) return '';

  // Output range name
  const rangeName = ranges.ranges[indexOfRanges].name?.[`lang${lang}`] || '';
  return rangeName;
}