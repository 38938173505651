import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';

import HambugerBtn from './HambugerBtn';
import Languages from './Languages';
import Functions from './Functions';
import MainNavLg from './MainNavLg';
import ebasellLogo from '../../assets/img/ebasell-logo.svg';
import './styles.scss';

const Logo = styled.div`
  width: 180px;
  height: 54px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
  }
`;

const RightPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > * {
    margin-left: auto;
  }
`;

export default function HeaderBar() {
  return (
    <div className='headerBar'>
      <Logo className='headerBar__logo'>
        <HashLink to='/'>
          <img src={ebasellLogo} alt='' />
        </HashLink>
      </Logo>

      <MainNavLg className='headerBar__mainNavLg' />

      <div className='headerBar__mainNavSm'>
        <HambugerBtn />
      </div>

      <RightPart>
        <Languages className='headerBar__langs' />
        <Functions className='headerBar__functions' />
      </RightPart>
    </div>
  );
}
