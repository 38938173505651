
import { useState, useEffect } from 'react';
import { db } from '../data/firestore/config';

export default function useGetDbList({ collection }) {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscrib = db
      .collection(collection)
      .get()
      .then(snapshot => {
        let response = [];
        snapshot.forEach(doc => {
          response.push({ ...doc.data(), docId: doc.id });
        });
        setData(response);
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error.message);
        setError(error);
        setIsLoading(false);
      });

    return unsubscrib;
  }, [collection]);

  return { data, error, isLoading };
}
