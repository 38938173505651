import { HashLink } from 'react-router-hash-link';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useAuth } from '../../contexts/auth.context';
import { useLang } from './../../contexts/lang.context';

export default function SlideMenu({ showMenu, setShowMenu }) {
  const history = useHistory();
  const { lang, setLang } = useLang();
  const { currentUser, signOut } = useAuth();

  const text = {
    PRODUCT_RANGE: {
      langTC: '產品類別',
      langSC: '产品类别',
      langEN: 'PRODUCT RANGE',
    },
    BRAND: {
      langTC: '品牌',
      langSC: '品牌',
      langEN: 'BRAND',
    },
    PROMOTION: {
      langTC: '促銷優惠',
      langSC: '促销优惠',
      langEN: 'PROMOTION',
    },
    HOME: {
      langTC: '主頁',
      langSC: '主页',
      langEN: 'HOME',
    },
    ABOUT_US: {
      langTC: '關於我們',
      langSC: '关于我们',
      langEN: 'ABOUT US',
    },
    NEWS: {
      langTC: '最新消息',
      langSC: '最新消息',
      langEN: 'NEWS',
    },
    CONTACT_US: {
      langTC: '聯絡我們',
      langSC: '联络我们',
      langEN: 'CONTACT US',
    },
    DELIVERY_METHOD: {
      langTC: '送貨方式',
      langSC: '送货方式',
      langEN: 'DELIVERY METHOD',
    },
    SIGNIN: {
      langTC: '登入',
      langSC: '登入',
      langEN: 'SIGN IN',
    },
    SIGNOUT: {
      langTC: '登出',
      langSC: '登出',
      langEN: 'SIGN OUT',
    },
    SIGNUP: {
      langTC: '註冊',
      langSC: '注册',
      langEN: 'SIGN UP',
    },
    MY_ACCOUNT: {
      langTC: '我的帳戶',
      langSC: '我的帐户',
      langEN: 'MY ACCOUNT',
    },
    CART: {
      langTC: '購物藍',
      langSC: '购物蓝',
      langEN: 'CART',
    },
    TRANSACTIONS: {
      langTC: '交易記錄',
      langSC: '交易记录',
      langEN: 'TRANSACTIONS',
    },
    CONTACT_INFO: {
      langTC: '聯絡資料',
      langSC: '联络资料',
      langEN: 'CONTACT INFO.',
    },
    SECURITY: {
      langTC: '安全性',
      langSC: '安全性',
      langEN: 'SECURITY',
    },
  };

  function closeMenu() {
    setShowMenu(false);
  }

  function hanldeSignOut() {
    signOut();
    history.push('/');
  }

  return (
    <Container showMenu={showMenu}>
      <Menu>
        <section>
          <HashLink to='/products#' onClick={closeMenu}>
            {text.PRODUCT_RANGE[`lang${lang}`]}
          </HashLink>
          <HashLink to='/brands#' onClick={closeMenu}>
            {text.BRAND[`lang${lang}`]}
          </HashLink>
          <HashLink to='/products?promotion=true' onClick={closeMenu}>
            {text.PROMOTION[`lang${lang}`]}
          </HashLink>
          <HashLink to='/cart#' onClick={closeMenu}>
            {text.CART[`lang${lang}`]}
          </HashLink>
        </section>

        <div className='spaceBar' />

        <section>
          <HashLink to='/#' onClick={closeMenu}>
            {text.HOME[`lang${lang}`]}
          </HashLink>
          <HashLink to='/aboutus#' onClick={closeMenu}>
            {text.ABOUT_US[`lang${lang}`]}
          </HashLink>
          <HashLink to='/news#' onClick={closeMenu}>
            {text.NEWS[`lang${lang}`]}
          </HashLink>
          <HashLink to='/contact#' onClick={closeMenu}>
            {text.CONTACT_US[`lang${lang}`]}
          </HashLink>
          <HashLink to='/deliverymethod#' onClick={closeMenu}>
            {text.DELIVERY_METHOD[`lang${lang}`]}
          </HashLink>
        </section>

        <div className='spaceBar' />

        <section>
          <HashLink
            to={`${currentUser ? '/myaccount#' : '/signin#'}`}
            onClick={closeMenu}
          >
            {currentUser
              ? text.MY_ACCOUNT[`lang${lang}`]
              : `${text.SIGNIN[`lang${lang}`]} / ${text.SIGNUP[`lang${lang}`]}`}
          </HashLink>

          {currentUser && (
            <>
              <HashLink to='/myaccount/transactions#' onClick={closeMenu}>
                {text.TRANSACTIONS[`lang${lang}`]}
              </HashLink>
              <HashLink to='/myaccount/contactinfo#' onClick={closeMenu}>
                {text.CONTACT_INFO[`lang${lang}`]}
              </HashLink>
              <HashLink to='/myaccount/security#' onClick={closeMenu}>
                {text.SECURITY[`lang${lang}`]}
              </HashLink>

              <span onClick={hanldeSignOut}>{text.SIGNOUT[`lang${lang}`]}</span>
            </>
          )}
        </section>

        {/* <div className='spaceBar' />

        <section className='row langs'>
          <span
            onClick={() => setLang('TC')}
            className={lang === 'TC' ? 'selected' : null}
          >
            繁中
          </span>

          <span
            onClick={() => setLang('SC')}
            className={lang === 'SC' ? 'selected' : null}
          >
            简中
          </span>

          <span
            onClick={() => setLang('EN')}
            className={lang === 'EN' ? 'selected' : null}
          >
            EN
          </span>
        </section> */}
      </Menu>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: var(--color-primary);
  position: absolute;
  top: 0;
  left: ${({ showMenu }) => (showMenu ? '0' : '-100%')};
  transition: all 0.2s ease-in-out;
  z-index: 99;
  overflow-y: scroll;
  padding-bottom: 40px;
`;

const Menu = styled.div`
  margin-top: 60px;

  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    color: white;

    &.row {
      flex-direction: row;
    }

    a,
    span {
      color: white;
      text-decoration: none;
      font-weight: bold;
      font-size: 1.8rem;
      cursor: pointer;
    }

    &.langs {
      span {
        padding: 5px 10px;
        border-radius: 10px;
        cursor: pointer;

        &.selected {
          background-color: white;
          color: var(--color-primary);
        }
      }
    }
  }

  .spaceBar {
    width: 60%;
    height: 1px;
    background-color: #ccc;
    margin: 24px auto;
  }
`;
