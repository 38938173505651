import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import CartListHeader from './CartListHeader';
import CartListItem from './CartListItem';
import CartListFooter from './CartListFooter';
import ButtonExtend from './ButtonExtend';

export default function CartList({ data, mode = '', isShowFail = false }) {
  const [paymentTotal, setPaymentTotal] = useState(0);
  // const [isExtend, setIsExtend] = useState(mode === 'read' ? false : true);
  const [isExtend, setIsExtend] = useState(true);

  useEffect(() => {
    if (data.length === 0) return setPaymentTotal(0);

    const total = data.reduce((total, item) => {
      return total + item.unitPrice * item.qty;
    }, 0);
    setPaymentTotal(total);
  }, [data]);

  function handleBodyExtend() {
    setIsExtend((prevState) => !prevState);
  }

  return (
    <Cart>
      <CartListHeader />

      <CartListBody isExtend={isExtend}>
        {data.map((item, index) => (
          <CartListItem
            key={index}
            item={item}
            mode={mode}
            isShowFail={isShowFail}
          />
        ))}
      </CartListBody>

      {mode === 'read' && (
        <ButtonExtend onClick={handleBodyExtend} isExtend={isExtend} />
      )}

      <CartListFooter paymentTotal={paymentTotal} />
    </Cart>
  );
}

const Cart = styled.div`
  width: 100%;
`;

const CartListBody = styled.div`
  height: 100%;
  overflow-y: hidden;
  transition: all 0.3s ease-in-out;

  ${(props) => {
    return props.isExtend
      ? css`
          max-height: 100%;
          opacity: 1;
          visibility: visible;
        `
      : css`
          max-height: 0;
          opacity: 0;
          visibility: hidden;
        `;
  }}
`;
