import { db } from '../data/firestore/config';

export default function setDbData({ collection, docId, data, options }) {
  return new Promise(async (resolve, reject) => {
    await db
      .collection(collection)
      .doc(docId)
      .set(data, options)
      .then((docRef) => {
        resolve(docRef);
      })
      .catch(error => {
        // console.log('setData error:', error);
        reject(error);
      });
  });
}