import styled from 'styled-components';
import { useLang } from './../../../contexts/lang.context';

export default function CartListHeader() {
  const { lang } = useLang();

  const text = {
    header: {
      langTC: '訂單摘要',
      langSC: '订单摘要',
      langEN: 'ORDER SUMMARY',
    },
  };

  return <Container>{text.header[`lang${lang}`]}</Container>;
}

const Container = styled.div`
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
  border-bottom: 1px solid var(--color-grey);
  padding-bottom: 10px;
  margin-bottom: 5px;
`;
