const text = {
  PAYMENT_INFO: {
    langEN: 'Payment Details',
    langTC: '付款資料',
    langSC: '付款资料',
  },
  USER_INFO: {
    langEN: 'User Details',
    langTC: '帳戶資料',
    langSC: '帐户资料',
  },
  PRODUCT_INFO: {
    langEN: 'Product Details',
    langTC: '貨品內容',
    langSC: '货品内容',
  },
  DELIVERY_INFO: {
    langEN: 'Delivery Information',
    langTC: '送貨資料',
    langSC: '送货资料',
  },
  TRANSACTION_DATE: {
    langEN: 'Transaction Date',
    langTC: '交易日期',
    langSC: '交易日期',
  },
  TRANSACTION_ID: {
    langEN: 'Transaction ID',
    langTC: '交易編號',
    langSC: '交易编号',
  },
  PAYMENT_STATUS: {
    langEN: 'Payment Status',
    langTC: '付款狀態',
    langSC: '付款狀態',
  },
  PAYMENT_TYPE: {
    langEN: 'Payment Type',
    langTC: '付款種類',
    langSC: '付款种类',
  },
  PAYMENT_NAME: {
    langEN: 'Payment Name',
    langTC: '付款人',
    langSC: '付款人',
  },
  PAYMENT_AMOUNT: {
    langEN: 'Amount',
    langTC: '總額',
    langSC: '总额',
  },
  USER_ID: {
    langEN: 'User ID',
    langTC: '帳戶編號',
    langSC: '帐户编号',
  },
  EMAIL: {
    langEN: 'Email',
    langTC: '電郵',
    langSC: '电邮',
  },
  BRAND: {
    langEN: 'Brand',
    langTC: '品牌',
    langSC: '品牌',
  },
  PRODUCT_DETAILS: {
    langEN: 'Name',
    langTC: '貨品',
    langSC: '货品',
  },
  QTY: {
    langEN: 'Qty',
    langTC: '數量',
    langSC: '数量',
  },
  UNIT_PRICE: {
    langEN: 'Unit Price',
    langTC: '單價',
    langSC: '单价',
  },
  AMOUNT: {
    langEN: 'Amount',
    langTC: '總額',
    langSC: '总额',
  },
  TOTAL: {
    langEN: 'Total',
    langTC: '總計',
    langSC: '总计',
  },
  CONTACT: {
    langEN: 'Contact',
    langTC: '聯絡人',
    langSC: '联络人',
  },
  TEL: {
    langEN: 'Tel',
    langTC: '電話',
    langSC: '电话',
  },
  DELIVERY_OPTION: {
    langEN: 'Delivery Option',
    langTC: '交貨方式',
    langSC: '交货方式',
  },
  SELF_COLLECT: {
    langEN: 'Self Collect',
    langTC: '自取',
    langSC: '自取',
  },
  HK_STANDARD_DELIVERY: {
    langTC: '香港標準快遞',
    langSC: '香港标准快递',
    langEN: 'HK standard delivery',
  },
  DELIVERY_COUNTRY: {
    langTC: '收貨國家/地區',
    langSC: '收货国家/地区',
    langEN: 'Delivery Country/Location',
  },
  ADDRESS: {
    langEN: 'Delivery Address',
    langTC: '送貨地址',
    langSC: '送货地址',
  },
};

export default text;