import { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import useToastify from '../../../../hooks/useToastify';
import { setDbData } from '../../../../utils';
import Button from '../../../form/Button';
import ResultMessageBox from '../../../ResultMessageBox';

export default function EditPrivacyPolicy({ data, handleClosePopupBox }) {
  const [contentTC, setContentTC] = useState(data?.content?.langTC);
  const [contentSC, setContentSC] = useState(data?.content?.langSC);
  const [contentEN, setContentEN] = useState(data?.content?.langEN);
  const [error, setError] = useState('');
  const notify = useToastify();

  function handleFormStep1Submit(e) {
    e.preventDefault();

    const newData = {
      content: {
        langTC: contentTC || '',
        langSC: contentSC || '',
        langEN: contentEN || '',
      },
      isDisable: false,
    };

    console.log(newData);

    // Save data
    try {
      setDbData({
        collection: 'privacyPolicy',
        docId: data?.docId,
        data: newData,
      });
    } catch (error) {
      setError(error.message);
    }

    if (!error) {
      notify({
        type: 'success',
        message: 'Record successfully saved!',
      });

      handleClosePopupBox();
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <h1>{`${data?.docId ? 'Edit' : 'New'} Privacy Policy`}</h1>

      <p className='remarks'>* must be inputed</p>

      {error && <ResultMessageBox type='error' messages={error} />}

      <form onSubmit={handleFormStep1Submit}>
        <div className='paper'>
          <h4>Content</h4>
          <div className='formGroup'>
            <div>
              <label>繁體中文</label>
              <CKEditor
                editor={ClassicEditor}
                data={contentTC}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContentTC(data);
                }}
              />
            </div>
          </div>

          <div className='formGroup'>
            <div>
              <label>简体中文</label>
              <CKEditor
                editor={ClassicEditor}
                data={contentSC}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContentSC(data);
                }}
              />
            </div>
          </div>

          <div className='formGroup'>
            <div>
              <label>English</label>
              <CKEditor
                editor={ClassicEditor}
                data={contentEN}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContentEN(data);
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          <Button
            type='submit'
            text='Save'
            size='sm'
            color='secondary'
            fontWeight='bold'
          />

          <Button
            type='button'
            text='Close'
            size='sm'
            color='grey'
            fontWeight='bold'
            onClick={handleClosePopupBox}
          />
        </div>
      </form>
    </div>
  );
}
