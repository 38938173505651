import { db } from '../data/firestore/config';

export default function deleteDbData({ collection, docId }) {
  return new Promise(async (resolve, reject) => {
    await db
      .collection(collection)
      .doc(docId)
      .delete()
      .then(() => {
        resolve('Record successfully deleted!');
      })
      .catch(error => {
        // console.log('setData error:', error);
        reject(error);
      });
  });
}