import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import { db } from '../../data/firestore/config';
import { useLang } from '../../contexts/lang.context';
import moment from 'moment';
import { useAuth } from '../../contexts/auth.context';
import './styles.scss';
import { Button } from '../../components/form';

export default function PaymentStatus() {
  const history = useHistory();
  const { id } = useParams();
  const { lang } = useLang();
  const { currentUser } = useAuth();
  const [transaction, setTransaction] = useState(null);
  const [error, setError] = useState('');

  if (!currentUser.uid || !id) history.push('/pagenotfound');

  const text = {
    PAGE_TITLE: {
      langTC: '交易狀態',
      langSC: '交易状态',
      langEN: 'PAYMENT STATUS',
    },
    TRANSACTION_ID: {
      langTC: '交易編號',
      langSC: '交易编号',
      langEN: 'Transaction ID',
    },
    PAYMENT_STATUS: {
      langTC: '交易狀態',
      langSC: '交易状态',
      langEN: 'Payment Status',
    },
    BANKIN: {
      langTC: '未完成',
      langSC: '未完成',
      langEN: 'Bank In',
    },
    PENDING: {
      langTC: '未完成',
      langSC: '未完成',
      langEN: 'Pending',
    },
    FAILED: {
      langTC: '失敗',
      langSC: '失败',
      langEN: 'Failed',
    },
    SUCCESSED: {
      langTC: '已接納',
      langSC: '已接纳',
      langEN: 'Successed',
    },
    PAYMENT_TYPE: {
      langTC: '付款方式',
      langSC: '付款方式',
      langEN: 'Payment Type',
    },
    CARD: {
      langTC: '信用卡',
      langSC: '信用卡',
      langEN: 'Credit Card',
    },
    PAYMENT_DATE: {
      langTC: '訂單日期',
      langSC: '訂單日期',
      langEN: 'Order Date',
    },
    AMOUNT: {
      langTC: '總額',
      langSC: '总额',
      langEN: 'Amount',
    },
    GO_TO_MYACCOUNT: {
      langTC: '到「我的帳戶」頁',
      langSC: '到「我的帐户」页',
      langEN: 'Go to "My Account" page',
    },
  };

  const userId = currentUser.uid;

  useEffect(() => {
    db.collection('transactions')
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const result = doc.data();
          if (result.userId === userId) {
            setTransaction(result);
          } else {
            history.push('/pagenotfound');
          }
        } else {
          history.push('/pagenotfound');
        }
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      });
  }, [id]);

  function showDateTime(timestamp) {
    return moment(timestamp).format('DD-MMM-YYYY HH:mm:ss');
  }

  function showPaymentType(paymentType = '') {
    const value = paymentType === 'bankin' ? 'bank in' : paymentType;

    return value.toUpperCase();
  }

  function formatTransactionID(docId) {
    const upperCaseDocId = docId.toUpperCase();

    const stringHead = upperCaseDocId.substring(0, 8);
    const stringTail = upperCaseDocId.substring(8);
    const output = (
      <>
        <Highlight>{stringHead}</Highlight>
        {stringTail}
      </>
    );
    return output;
  }

  return (
    <div id='pagePaymentSuccess' className='container'>
      <div className='row'>
        <h1 className='pageTitle'>{text.PAGE_TITLE[`lang${lang}`]}</h1>

        {error}

        {transaction && (
          <>
            <table className='paymentDetails'>
              <tbody>
                <tr>
                  <th>{text.TRANSACTION_ID[`lang${lang}`]}</th>
                  <td>{formatTransactionID(transaction.transactionId)}</td>
                </tr>
                {/* <tr>
                  <th>{text.PAYMENT_STATUS[`lang${lang}`]}</th>
                  <td
                    className={`paymentStatus ${transaction.paymentDetails.paymentStatus.toLowerCase()}`}
                  >
                    {
                      text[
                        transaction.paymentDetails.paymentStatus.toUpperCase()
                      ][`lang${lang}`]
                    }
                    {transaction.paymentDetails.paymentMessage
                      ? ` - ${transaction.paymentDetails.paymentMessage}`
                      : null}
                  </td>
                </tr> */}
                <tr>
                  <th>{text.PAYMENT_TYPE[`lang${lang}`]}</th>
                  {/* <td>Bank In</td> */}
                  <td>
                    {showPaymentType(transaction.paymentDetails?.paymentType)}
                  </td>
                  {/* <td>{text.CARD[`lang${lang}`]}</td> */}
                </tr>
                <tr>
                  <th>{text.PAYMENT_DATE[`lang${lang}`]}</th>
                  <td>{showDateTime(transaction.createdAt)}</td>
                </tr>
                <tr>
                  <th>{text.AMOUNT[`lang${lang}`]}</th>
                  <td>
                    {transaction.currency.toUpperCase()}&nbsp;
                    <CurrencyFormat
                      value={transaction.amount}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale
                      decimalScale={2}
                      prefix='$'
                    />
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <div className='mt-2'>
              <Button
                color='secondary'
                size='md'
                onClick={() => history.push('/myaccount')}
              >
                {text.GO_TO_MYACCOUNT?.[`lang${lang}`]}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const Highlight = styled.span`
  color: var(--color-secondary-dark);
  background-color: var(--color-secondary-light);
  padding: 2px;
`;
