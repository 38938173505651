import styled from 'styled-components';

export default function TextInput(props) {
  const { disabled } = props;

  return <Input {...props} className={disabled ? 'disabled' : null} />;
}

const Input = styled.input`
  background-color: white;
  font-size: 1.6rem;
  padding: 6px 10px;
  border: 1px solid #58595b;
  border-radius: 5px;
  width: 100%;

  &.disabled {
    background-color: var(--color-grey-light-2);
  }
`;
