import { useEffect, useContext, createContext, useReducer } from "react";
import { CART_ACTIONS, reducer } from './cart.reducer';

const CartContext = createContext();

// Load from localstorage
const CART_INITIAL_STATE = (function () {
  const cart = JSON.parse(localStorage.getItem('cart'));
  if (!cart || (Array.isArray && cart.length === 0)) return [];

  return cart;
})();

export default function CartProvider({ children }) {
  const [cart, cartDispatch] = useReducer(reducer, CART_INITIAL_STATE);

  // Store into localstorage
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const value = {
    cart,
    cartDispatch,
    cartActions: CART_ACTIONS
  };

  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  );

}

export function useCart() {
  return useContext(CartContext);
}