import { useContext, createContext } from "react";
import useGetDbDoc from './../hooks/useGetDbDoc';

const BrandsContext = createContext();

export function useBrands() {
  return useContext(BrandsContext);
}

export default function BrandsProvider({ children }) {
  const { data: brands } = useGetDbDoc({ collection: 'brands', docId: '001' });

  const value = {
    brands
    // searchKeyword,
    // setSearchKeyword
  };

  return (
    <BrandsContext.Provider value={value}>
      {children}
    </BrandsContext.Provider>
  );
}