
import { useEffect, useState } from 'react';
import { db } from '../data/firestore/config';

export default function useListenDbList({ collection, docId }) {
  const [data, setData] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const unsubscrib = db.collection(collection)
      .doc(docId)
      .onSnapshot(doc => {
        setData(doc.data());
        setIsFetching(false);
      }, error => {
        setFetchError(error);
        setIsFetching(false);
      });

    return unsubscrib;
  }, [collection, docId]);

  return { data, fetchError, isFetching };
}
