export default function outputBrandName({ brands, code, lang = 'EN' }) {
  const indexOfBrands = Array.isArray(brands.values)
    ? brands.values.findIndex((brand) => brand.code === code)
    : -1;

  // Brand code dosen't match
  if (indexOfBrands <= -1) return '';

  // Output brand name
  const brandName = brands.values[indexOfBrands].name?.[`lang${lang}`] || code;
  return brandName;
}