import Button from '../form/Button';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useAuth } from '../../contexts/auth.context';
import { useLang } from './../../contexts/lang.context';
import { HashLink } from 'react-router-hash-link';

export default function LoginSubMenu(props) {
  const history = useHistory();
  const { lang } = useLang();
  const { currentUser, signOut } = useAuth();

  const text = {
    MYACCOUNT: {
      langTC: '我的帳戶',
      langSC: '我的帐户',
      langEN: 'My Account',
    },
    SIGNIN: {
      langTC: '登入',
      langSC: '登入',
      langEN: 'SIGN IN',
    },
    SIGNOUT: {
      langTC: '登出',
      langSC: '登出',
      langEN: 'SIGN OUT',
    },
    SIGNUP: {
      langTC: '註冊',
      langSC: '注册',
      langEN: 'SIGN UP',
    },
    NEW_CUSTOMER: {
      langTC: '未有帳戶?',
      langSC: '未有帐户?',
      langEN: 'New Customer?',
    },
  };

  function hanldeSignOut() {
    signOut();
    history.push('/');
  }

  return (
    <Container {...props}>
      {currentUser ? (
        <>
          {/* <div className='email'>{currentUser.email}</div> */}
          <HashLink to='/myaccount#' className='mb-1'>
            {text.MYACCOUNT[`lang${lang}`]}
          </HashLink>

          <Button
            variant='contained'
            color='grey'
            fontWeight='bold'
            text={text.SIGNOUT[`lang${lang}`]}
            onClick={hanldeSignOut}
          />
        </>
      ) : (
        <>
          <HashLink to='/signin#'>
            <Button
              variant='contained'
              color='grey'
              fontWeight='bold'
              text={text.SIGNIN[`lang${lang}`]}
              // onClick={() => history.push('/signin')}
            />
          </HashLink>

          <Splitter>{text.NEW_CUSTOMER[`lang${lang}`]}</Splitter>

          <HashLink to='/signup#'>
            <Button
              variant='outlined'
              color='grey'
              fontWeight='bold'
              text={text.SIGNUP[`lang${lang}`]}
            />
          </HashLink>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  cursor: auto;
  padding: 10px;
  position: absolute;
  top: 100%;
  right: -20px;
  z-index: 9;
  width: 140px;
  background-color: white;
  display: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: var(--shadow-1);

  .email {
    margin: 0 0 10px;
    overflow: hidden;
  }
`;

const Splitter = styled.div`
  border-top: 1px solid #777;
  margin: 15px 0 0;
  padding: 5px;
  font-size: 1.3rem;
`;
