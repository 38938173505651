import { useEffect, useState } from 'react';
import { useLang } from './../../contexts/lang.context';
import ProductList from '../../components/productList';
import PopupCard from '../../components/popupCard';
import productsData from '../../data/products';
import Button from '../../components/form/Button';
import './styles.scss';

const tempProducts = productsData;

export default function Promotion() {
  const { lang } = useLang();
  const [showPopupCard, setShowPopupCard] = useState(false);
  const [selectedProduct, setSelectProduct] = useState(null);
  const [noOfProducts, setNoOfProducts] = useState(10);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const text = {
    PROMOTION_OFFERS: {
      langTC: '促銷優惠',
      langSC: '促销优惠',
      langEN: 'PROMOTION OFFERS',
    },
    LOAD_MORE: {
      langTC: '載入其他...',
      langSC: '载入其他...',
      langEN: 'Load More...',
    },
  };

  useEffect(() => {
    setData(tempProducts.slice(0, noOfProducts));
  }, [noOfProducts]);

  function handleLoadMore() {
    setIsLoading(true);

    setTimeout(() => {
      setNoOfProducts((prevState) => prevState + 10);
      setIsLoading(false);
    }, 1000);
  }

  return (
    <div id='pagePromotion' className='container'>
      <div className='row'>
        <div className='sectionHeader'>
          <span className='sectionHeader__title'>
            {text.PROMOTION_OFFERS[`lang${lang}`]}
          </span>
        </div>

        {data && (
          <>
            <ProductList
              data={data}
              setShowPopupCard={setShowPopupCard}
              setSelectProduct={setSelectProduct}
            />

            {tempProducts.length > noOfProducts && (
              <div className='loadMoreWrapper'>
                <Button
                  color='secondary'
                  size='lg'
                  // fontWeight='400'
                  text={text.LOAD_MORE[`lang${lang}`]}
                  onClick={handleLoadMore}
                  isLoading={isLoading}
                />
              </div>
            )}
          </>
        )}
      </div>

      {selectedProduct && (
        <PopupCard
          show={showPopupCard}
          product={selectedProduct}
          setShowPopupCard={setShowPopupCard}
        />
      )}
    </div>
  );
}
