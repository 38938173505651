import { useState, useEffect } from 'react';
import BottomAdv from '../../components/BottomAdv';
import SlideBanner from '../../components/SlideBanner';
import BoxNews from '../../components/BoxNews';
import BoxSpecialOffer from '../../components/BoxSpecialOffer';
import ProductList from '../../components/productList';
import PopupCard from '../../components/popupCard';
import productsData from '../../data/products';
import './styles.scss';
import { HashLink } from 'react-router-hash-link';
import { useLang } from '../../contexts/lang.context';
import useGetDbList from './../../hooks/useGetDbList';

const text = {
  NEW_ITEMS: {
    langTC: '最新貨品',
    langSC: '最新货品',
    langEN: 'NEW ITEMS',
  },
  PROMOTION_OFFERS: {
    langTC: '促銷優惠',
    langSC: '促销优惠',
    langEN: 'PROMOTION OFFERS',
  },
  VIEW_ALL: {
    langTC: '顯示全部',
    langSC: '显示全部',
    langEN: 'VIEW ALL',
  },
};

export default function Home() {
  const { lang } = useLang();
  const [showPopupCard, setShowPopupCard] = useState(false);
  const [selectedProduct, setSelectProduct] = useState(null);
  const data1 = productsData.slice(0, 5);
  const data2 = productsData.slice(3, 8);
  const { data, error, isLoading } = useGetDbList({ collection: 'products' });
  const [newItems, setNewItems] = useState([]);
  const [promotionProducts, setPromotionProducts] = useState([]);

  useEffect(() => {
    if (isLoading) return;

    // Filter new items
    const tempNewItems = data.filter((product) => (product.isNew && !product.isDisable));
    setNewItems(tempNewItems.slice(0, 5));

    // Filter new items
    const tempPromotionProducts = data.filter((product) => (product.isPromotion && !product.isDisable));
    setPromotionProducts(tempPromotionProducts.slice(0, 5));
  }, [data, isLoading]);

  return (
    <div id='pageHome' className='container'>
      <div className='row'>
        <SlideBanner />
      </div>

      <div className='row news-container'>
        <BoxNews />
        <BoxSpecialOffer />
      </div>

      <div className='row'>
        <div className='sectionHeader'>
          <span className='sectionHeader__title'>
            {text.NEW_ITEMS[`lang${lang}`]}
          </span>
          <span className='sectionHeader__link'>
            <HashLink to='/products?newitem=true'>
              {text.VIEW_ALL[`lang${lang}`]}
            </HashLink>
          </span>
        </div>
        <ProductList
          data={newItems}
          setShowPopupCard={setShowPopupCard}
          setSelectProduct={setSelectProduct}
        />
      </div>

      <div className='row'>
        <div className='sectionHeader'>
          <span className='sectionHeader__title'>
            {text.PROMOTION_OFFERS[`lang${lang}`]}
          </span>
          <span className='sectionHeader__link'>
            <HashLink to='/products?promotion=true'>
              {text.VIEW_ALL[`lang${lang}`]}
            </HashLink>
          </span>
        </div>
        <ProductList
          data={promotionProducts}
          setShowPopupCard={setShowPopupCard}
          setSelectProduct={setSelectProduct}
        />
      </div>

      <div className='row'>
        <BottomAdv />
      </div>

      {selectedProduct && (
        <PopupCard
          show={showPopupCard}
          product={selectedProduct}
          setShowPopupCard={setShowPopupCard}
        />
      )}
    </div>
  );
}
