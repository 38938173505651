import { useEffect, useState } from 'react';
// import { useParams, useLocation } from 'react-router-dom';

import ProductList from '../../components/productList';
import PopupCard from '../../components/popupCard';
import Button from '../../components/form/Button';
import useQuery from '../../hooks/useQuery';
// import productsData from '../../data/products';
// import categories from '../../data/categories';
// import SelectBox from '../../components/form/SelectBox';
// import { db } from '../../data/firestore/config';
import useGetDbList from '../../hooks/useGetDbList';
import { useSearch } from './../../contexts/search.context';
import './styles.scss';
import { useLang } from './../../contexts/lang.context';
import { useRanges } from './../../contexts/ranges.context';
import { useBrands } from './../../contexts/brands.context';
import outputRangeName from './../../utils/outputRangeName';
import outputBrandName from './../../utils/outputBrandName';

const text = {
  NO_PRODUCT_FOUND: {
    langEN: 'No product found.',
    langTC: '找不到貨品。',
    langSC: '找不到货品。',
  },
};

export default function Products() {
  const query = useQuery();
  const { lang } = useLang();
  const { ranges } = useRanges();
  const { brands } = useBrands();
  // const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [noOfProducts, setNoOfProducts] = useState(10);
  // const [data, setData] = useState(null);
  const [showPopupCard, setShowPopupCard] = useState(false);
  const [selectedProduct, setSelectProduct] = useState(null);
  // const { searchKeyword } = useState(query.get('search'));
  // const [category, setCategory] = useState(query.get('range'));
  const { data, error, isLoading } = useGetDbList({ collection: 'products' });

  const queryRange = query.get('range');
  const queryBrand = query.get('brand');
  const querySearchKeyword = query.get('search');
  const queryIsPromotion = query.get('promotion') === 'true';
  const queryIsNewItem = query.get('newitem') === 'true';
  // console.log({ range, brand, isPromotion, isNewItem, searchKeyword });

  useEffect(() => {
    if (isLoading) return;

    function filterRange(product) {
      if (!queryRange) return true;
      return product.range === queryRange ? true : false;
    }

    function filterBrand(product) {
      if (!queryBrand) return true;
      return product.brand.toLocaleLowerCase() ===
        queryBrand.toLocaleLowerCase()
        ? true
        : false;
    }

    function filterPromotion(product) {
      if (!queryIsPromotion) return true;
      return product.isPromotion ? true : false;
    }

    function search(product) {
      if (!querySearchKeyword) return true;
      return (
        product.briefDesc
          .toLowerCase()
          .search(querySearchKeyword.toLowerCase()) !== -1
      );
    }

    function filterNewItem(product) {
      if (!queryIsNewItem) return true;
      return product.isNew ? true : false;
    }

    const temp = data.filter((product) => {
      return (
        !product.isDisable &&
        filterRange(product) &&
        filterBrand(product) &&
        filterPromotion(product) &&
        filterNewItem(product) &&
        search(product)
      );
    });

    setFilteredProducts(temp);
  }, [
    isLoading,
    data,
    queryRange,
    queryBrand,
    querySearchKeyword,
    queryIsPromotion,
    queryIsNewItem,
  ]);

  // useEffect(() => {
  //   setIsLoading(true);
  //   const unsubscrib = db
  //     .collection('products')
  //     .get()
  //     .then((snapshot) => {
  //       let response = [];
  //       snapshot.forEach((doc) => {
  //         // const { brand, range, unit, ...rest } = doc.data();
  //         // const temp = { ...doc.data(), docId: doc.id };
  //         // console.log(temp);
  //         response.push({ ...doc.data(), docId: doc.id });
  //       });
  //       setData(response);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error.message);
  //       setError(error);
  //       setIsLoading(false);
  //     });

  //   return unsubscrib;
  // }, []);

  // Get path query when first time load page
  // useEffect(() => {
  //   // console.log('load page');
  //   setCategory(query.get('category'));
  // }, [query]);

  // Filter products data
  // useEffect(() => {
  //   let filteredProducts = [];
  //   // console.log('filter data cat:', category);

  //   if (range) {
  //     filteredProducts = productsData.filter((product) => {
  //       return product.range.indexOf(range) >= 0;
  //     });
  //   } else {
  //     filteredProducts = [];
  //   }
  //   setFilteredProducts(filteredProducts);
  // }, [range]);

  // Page the data
  // useEffect(() => {
  //   filteredProducts && setData(filteredProducts.slice(0, noOfProducts));
  // }, [filteredProducts, noOfProducts]);

  function handleFilterCategory(e) {
    // setCategory(e.target.value);
  }

  return (
    <div id='pageProducts' className='container'>
      {/* <div className='row'>
        <label>
          Filter&nbsp;
          <SelectBox data={categories} onChange={handleFilterCategory} />
        </label>
      </div> */}

      {queryRange && (
        <div className='row'>
          <h4>
            Range:{' '}
            {ranges && outputRangeName({ ranges, uid: queryRange, lang })}
          </h4>
        </div>
      )}

      {queryBrand && (
        <div className='row'>
          <h4>
            Brand:{' '}
            {brands && outputBrandName({ brands, code: queryBrand, lang })}
          </h4>
        </div>
      )}

      {queryIsNewItem && (
        <div className='row'>
          <h4>NEW ITEMS</h4>
        </div>
      )}

      {queryIsPromotion && (
        <div className='row'>
          <h4>PROMOTION OFFERS</h4>
        </div>
      )}

      <div className='row'>
        {filteredProducts.length === 0 && (
          <p>{text.NO_PRODUCT_FOUND?.[`lang${lang}`]}</p>
        )}

        {filteredProducts && (
          <>
            <ProductList
              data={filteredProducts}
              setShowPopupCard={setShowPopupCard}
              setSelectProduct={setSelectProduct}
            />

            {/* {filteredProducts.length > noOfProducts && (
              <div className='loadMoreWrapper'>
                <Button
                  color='secondary'
                  size='lg'
                  fontWeight='bold'
                  text='Load More...'
                  onClick={() => setNoOfProducts((prevState) => prevState + 10)}
                />
              </div>
            )} */}
          </>
        )}
      </div>

      {selectedProduct && (
        <PopupCard
          show={showPopupCard}
          product={selectedProduct}
          setShowPopupCard={setShowPopupCard}
        />
      )}
    </div>
  );
}
