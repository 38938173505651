import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { deleteDbData, setDbData } from './../../../utils';
import useListenDbDoc from './../../../hooks/useListenDbDoc';
import Button from '../../../components/form/Button';
import PopupBox from '../../../components/PopupBox';
import Editor from '../../../components/admin/editors/EditUnit';
import LoadingSpin from '../../../components/LoadingSpin';

const tableColumns = [
  {
    name: 'Edit',
    selector: (row) => row.btnEdit,
    maxWidth: '40px',
  },
  {
    name: 'Code',
    selector: (row) => row.code,
    sortable: true,
    maxWidth: '50px',
  },
  {
    name: 'Name(ENG)',
    selector: (row) => row.name.langEN,
    sortable: true,
  },
  {
    name: 'Name(TC)',
    selector: (row) => row.name.langTC,
    sortable: true,
  },
  {
    name: 'Name(SC)',
    selector: (row) => row.name.langSC,
    sortable: true,
  },
  {
    name: 'Delete',
    selector: (row) => row.btnDelete,
    maxWidth: '40px',
  },
];

export default function Units() {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editorData, setEditorData] = useState(null);
  const [dataIndex, setDataIndex] = useState(-1);
  const { data, fetchError, isFetching } = useListenDbDoc({
    collection: 'units',
    docId: '001',
  });

  useEffect(() => {
    if (isFetching) return;

    let tempData = [];
    setIsLoading(true);

    data.values?.forEach((doc, index) => {
      tempData.push({
        ...doc,
        btnEdit: createEditButton({ doc, index }),
        btnDelete: createDeleteButton(index),
      });
    });

    setTableData(tempData);
    setIsLoading(false);
  }, [data, isFetching]);

  function createEditButton({ data: doc, index }) {
    return (
      <EditIcon
        fontSize='large'
        className='clickable'
        // color=''
        style={{ color: '#33a35e' }}
        onClick={() => handleEditItem(data, index)}
      />
    );
  }

  function createDeleteButton(index) {
    return (
      <DeleteIcon
        fontSize='large'
        className='clickable'
        color='error'
        onClick={() => handleDeleteItem(index)}
      />
    );
  }

  function handleAddNewItem(data) {
    setEditorData(data);
    setDataIndex(-1);
    setIsShowPopup(true);
  }

  function handleEditItem(data, index) {
    setEditorData(data);
    setDataIndex(index);
    setIsShowPopup(true);
  }

  function handleDeleteItem(index) {
    const confirmBox = window.confirm(`Do you want to delete this item?`);
    if (confirmBox) {
      data.values.splice(index, 1);

      try {
        setDbData({
          collection: 'units',
          docId: '001',
          data: data,
        });
      } catch (error) {
        // setError(error.message);
      } finally {
        // setIsLoading(false);
      }
    }
  }

  function handleClosePopupBox() {
    setIsShowPopup(false);
  }

  return (
    <div className='container-full'>
      <div className='row'>
        <h1>Units</h1>
        <div>
          <Button
            text='Add New Unit'
            size='sm'
            color='secondary'
            fontWeight='bold'
            onClick={() => handleAddNewItem(data)}
          />
        </div>
      </div>

      {!isLoading && !isFetching ? (
        <div className='row'>
          <div className='paper'>
            <DataTable columns={tableColumns} data={tableData} pagination />
          </div>
        </div>
      ) : (
        <LoadingSpin />
      )}

      {isShowPopup && (
        <PopupBox
          handleClosePopupBox={handleClosePopupBox}
          backdropClickable={false}
        >
          <Editor
            data={editorData}
            dataIndex={dataIndex}
            handleClosePopupBox={handleClosePopupBox}
          />
        </PopupBox>
      )}
    </div>
  );
}
