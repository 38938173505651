import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../contexts/auth.context';

const AdminRoute = ({ children, ...rest }) => {
  const { currentUser, userIsAdmin } = useAuth();

  return (
    <Route {...rest} render={() => (currentUser && userIsAdmin ? children : <Redirect to='/admin/signin' />)} />
  );
};

export default AdminRoute;