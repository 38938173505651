import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Waiting() {
  return (
    <Wrapper>
      <CircularProgress size={50} style={{ color: '#ccc' }} />
    </Wrapper>
  );
}
