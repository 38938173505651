import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import useGetDbDoc from './../../hooks/useGetDbDoc';
import { useLang } from './../../contexts/lang.context';

export default function BrandsSubMenu() {
  const { lang } = useLang();
  const [upperBrands, setUpperBrands] = useState([]);
  const [lowerBrands, setLowerBrands] = useState([]);
  const { data, isFetching } = useGetDbDoc({
    collection: 'brands',
    docId: '001',
  });

  useEffect(() => {
    if (isFetching) return;

    let tempUpper = [];
    tempUpper = data.values.filter(
      (brand) => brand.isHighlight && !brand.isDisable,
    );
    setUpperBrands(tempUpper);

    let tempLower = [];
    tempLower = data.values.filter(
      (brand) => !brand.isHighlight && !brand.isDisable,
    );
    setLowerBrands(tempLower);
  }, [isFetching]);

  return (
    <Container>
      <div className='upper'>
        {/* <HashLink to='/products?brand=AEI Cables'>AEI Cables</HashLink>
        <span> &nbsp;&nbsp;/&nbsp;&nbsp;</span>
        <HashLink to='/products?brand=KME'>KME</HashLink>
        <span> &nbsp;&nbsp;/&nbsp;&nbsp;</span>
        <HashLink to='/products?brand=EVCCO'>EVCCO</HashLink>
        <span> &nbsp;&nbsp;/&nbsp;&nbsp;</span>
        <HashLink to='/products?brand=UNEX'>UNEX</HashLink>
        <span> &nbsp;&nbsp;/&nbsp;&nbsp;</span>
        <HashLink to='/products?brand=CAVICEL'>CAVICEL</HashLink>
        <span> &nbsp;&nbsp;/&nbsp;&nbsp;</span>
        <HashLink to='/products?brand=VICHNET'>VICHNET</HashLink> */}

        {upperBrands &&
          upperBrands.map((brand, index) => (
            <Fragment key={index}>
              <span>
                <HashLink to={`/products?brand=${brand.code}`}>
                  {brand.name[`lang${lang}`]}
                </HashLink>
              </span>
              {index < upperBrands.length - 1 && <span>/</span>}
            </Fragment>
          ))}
      </div>
      <div className='lower'>
        {lowerBrands &&
          lowerBrands.map((brand, index) => (
            <Fragment key={index}>
              <span>
                <HashLink to={`/products?brand=${brand.code}`}>
                  {brand.name[`lang${lang}`]}
                </HashLink>
              </span>
              {index < lowerBrands.length - 1 && <span>/</span>}
            </Fragment>
          ))}
      </div>
    </Container>
  );
}

const Container = styled.div`
  width: 600px;
  background-color: white;
  box-shadow: var(--shadow-1);
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9;
  padding: 15px 20px;
  display: none;

  &.hidden {
    /* visibility: hidden; */
    display: none;
  }

  .upper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;

    * {
      font-size: 1.8rem;
    }
  }

  .lower {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
  }
`;
