import FooterBar from '../components/FooterBar';
import HeaderBar from '../components/HeaderBar';
import useWindowSize from '../hooks/useWindowSize';
import PagePadding from '../components/PagePadding';
import UserSideBar from '../components/myAccount/SideBar';
import styled from 'styled-components';

export default function MyAccountLayout({ children }) {
  const { mediaMode } = useWindowSize();

  return (
    <>
      {(mediaMode === 'mobile' || mediaMode === 'tablet') && <PagePadding height='50px' />}

      <HeaderBar />

      <ContentWrapper>
        {(mediaMode !== 'mobile' && mediaMode !== 'tablet') &&
          <UserSideBar />
        }

        {children}
      </ContentWrapper>

      <FooterBar />
    </>
  );
}

const ContentWrapper = styled.div`
  width:100%;
  max-width:1280px;
  flex:1;
  display:flex;
  flex-direction:row;
  margin:0 auto;
`;