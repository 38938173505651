import styled from 'styled-components';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';

import { useLang } from './../../contexts/lang.context';
import { useBrands } from './../../contexts/brands.context';
// import useWindowSize from './../../hooks/useWindowSize';
import text from './text';
import './styles.scss';
import outputBrandName from '../../utils/outputBrandName';

export default function TransactionDetails({ data, selectedLang = '' }) {
  const { lang } = useLang();
  const { brands } = useBrands();
  // const { mediaMode } = useWindowSize();

  if (selectedLang === '') selectedLang = lang;

  function formatTransactionID(docId) {
    const upperCaseDocId = docId.toUpperCase();

    const stringHead = upperCaseDocId.substring(0, 8);
    const stringTail = upperCaseDocId.substring(8);
    const output = (
      <>
        <Highlight>{stringHead}</Highlight>
        {stringTail}
      </>
    );
    return output;
  }

  function showDeliveryMethod(deliveryMethod) {
    switch (deliveryMethod) {
      case 'SelfCollect':
        return text.SELF_COLLECT?.[`lang${lang}`];
      case 'HKStandardDelivery':
        return text.HK_STANDARD_DELIVERY?.[`lang${lang}`];
      default:
        return text.HK_STANDARD_DELIVERY?.[`lang${lang}`];
    }
  }

  function showPaymentStatus(paymentStatus) {
    switch (paymentStatus) {
      case 'bankin':
        return 'bank in';
      case 'pending':
        return 'incomplete';
      default:
        return paymentStatus;
    }
  }

  return (
    <Container id='transactionDetails'>
      <div className='header'>
        <section>
          <div>
            <h4>{text.PAYMENT_INFO?.[`lang${selectedLang}`]}</h4>
          </div>
          <div>
            {text.TRANSACTION_DATE?.[`lang${selectedLang}`]}:&nbsp;
            {moment(data.createdAt).format('DD-MMM-YYYY HH:mm')}
          </div>
          <div>
            {text.TRANSACTION_ID?.[`lang${selectedLang}`]}:&nbsp;
            {formatTransactionID(data.docId)}
            {/* <span
              dangerouslySetInnerHTML={{
                __html: formatTransactionID(data.docId),
              }}
            /> */}
            {/* {data.docId.toUpperCase()} */}
          </div>
          <div>
            {text.PAYMENT_STATUS?.[`lang${selectedLang}`]}:&nbsp;
            {showPaymentStatus(
              data.paymentDetails?.paymentStatus,
            ).toUpperCase()}
          </div>
          <div>
            {text.PAYMENT_TYPE?.[`lang${selectedLang}`]}:&nbsp;
            {showPaymentStatus(data.paymentDetails?.paymentType).toUpperCase()}
          </div>
          <div>
            {text.PAYMENT_NAME?.[`lang${selectedLang}`]}:&nbsp;
            {data.billingName}
          </div>
          <div>
            {text.PAYMENT_AMOUNT?.[`lang${selectedLang}`]}:&nbsp;
            {data.currency?.toUpperCase()}&nbsp;
            <CurrencyFormat
              value={data.amount}
              displayType={'text'}
              thousandSeparator={true}
              fixedDecimalScale
              decimalScale={2}
              prefix='$'
            />
          </div>
        </section>

        <section>
          <div>
            <h4>{text.USER_INFO?.[`lang${selectedLang}`]}</h4>
          </div>
          <div>
            {text.USER_ID?.[`lang${selectedLang}`]}:&nbsp;
            {data.userId.toUpperCase()}
          </div>
          <div>
            {text.EMAIL?.[`lang${selectedLang}`]}:&nbsp;
            {data.email}
          </div>
        </section>
      </div>

      <section>
        <div>
          <h4>{text.PRODUCT_INFO?.[`lang${selectedLang}`]}</h4>
        </div>

        <div className='responsive-table pb-1'>
          <Table className='product-details'>
            <thead>
              <tr>
                <td>{text.BRAND?.[`lang${selectedLang}`]}</td>
                <td>{text.PRODUCT_DETAILS?.[`lang${selectedLang}`]}</td>
                <td>{text.QTY?.[`lang${selectedLang}`]}</td>
                <td></td>
                <td className='align-right'>
                  {text.UNIT_PRICE?.[`lang${selectedLang}`]}
                </td>
                <td className='align-right'>
                  {text.AMOUNT?.[`lang${selectedLang}`]}
                </td>
              </tr>
            </thead>
            <tbody>
              {data.orderItems.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {outputBrandName({ brands, code: item.brand, lang })}
                    </td>
                    <td>{item.briefDesc}</td>
                    <td>
                      {item.qty}&nbsp;{item.unit}
                    </td>
                    <td>{data.currency?.toUpperCase()}</td>
                    <td className='align-right'>
                      <CurrencyFormat
                        value={item.unitPrice}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale
                        decimalScale={2}
                        prefix='$'
                      />
                      {/* {item.unitPrice} */}
                    </td>
                    <td className='align-right'>
                      <CurrencyFormat
                        value={item.qty * item.unitPrice}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale
                        decimalScale={2}
                        prefix='$'
                      />
                      {/* {item.qty * item.unitPrice} */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colspan='6' className='total-amount'>
                  {text.TOTAL?.[`lang${selectedLang}`]}&nbsp;
                  {data.currency?.toUpperCase()}&nbsp;
                  <CurrencyFormat
                    value={data.amount}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale
                    decimalScale={2}
                    prefix='$'
                  />
                  {/* {data.amount} */}
                </td>
              </tr>
            </tfoot>
          </Table>
        </div>
      </section>

      <section>
        <h4>{text.DELIVERY_INFO?.[`lang${lang}`]}</h4>
        <div>
          {text.CONTACT?.[`lang${lang}`]}:&nbsp;
          {data.contactName}
        </div>
        <div>
          {text.TEL?.[`lang${lang}`]}:&nbsp;
          {data.tel}
        </div>
        <div>
          {text.DELIVERY_OPTION?.[`lang${lang}`]}:&nbsp;
          {showDeliveryMethod(data.deliveryMethod)}
        </div>
        {data.deliveryMethod !== 'SelfCollect' && (
          <>
            <div>
              {text.DELIVERY_COUNTRY?.[`lang${lang}`]}:&nbsp;
              {data.deliveryCountry?.name}
            </div>
            <div>
              {text.ADDRESS?.[`lang${lang}`]}:
              <br />
              {data.deliveryAddressLine1}
              <br />
              {data.deliveryAddressLine2}
              <br />
              {data.deliveryAddressLine3}
            </div>
          </>
        )}
      </section>
    </Container>
  );
}

const Container = styled.div`
  .header {
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 30px;
  }
`;

const Table = styled.table`
  thead tr {
    color: white;
    background-color: var(--color-primary);
  }
  tbody tr {
    border-bottom: 1px solid var(--color-grey-light-2);
  }
  /* tfoot td {
    text-align: right;
  } */
  th,
  td {
    padding: 5px 8px;
  }
  .align-right {
    text-align: right;
  }
`;

const Highlight = styled.span`
  color: var(--color-secondary-dark);
  background-color: var(--color-secondary-light);
  padding: 2px;
`;
