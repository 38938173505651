// import { initializeApp } from "firebase/app";
// import { getFirestore, collection, doc, addDoc, setDoc, updateDoc } from "firebase/firestore";

import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = firebase.firestore();
export default firebase;

// const firebaseApp = initializeApp(firebaseConfig);
// const db = getFirestore();
// const db = firebaseApp.firestore();

// export default firebaseApp;
// export { db };
// export { db, collection, doc, addDoc, setDoc, updateDoc };
