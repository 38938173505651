import { useState } from 'react';
import useToastify from '../../../../hooks/useToastify';
import { setDbData } from '../../../../utils';
import Button from '../../../form/Button';
import TextInput from '../../../form/TextInput';
import ImageUploader from '../../../ImageUploader';
import ResultMessageBox from '../../../ResultMessageBox';

export default function EditSlideBanners({ data, index, handleClosePopupBox }) {
  const notify = useToastify();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [imagePublicId, setImagePublicId] = useState('');
  const [imageLgUrl, setImageLgUrl] = useState('');

  let currentImages = [];
  if (index > -1) currentImages.push(data?.values[index].imageLgUrl);

  function handleUpdateImageId({ publicId, imageLgUrl }) {
    setImagePublicId(publicId);
    setImageLgUrl(imageLgUrl);
  }

  function handleCancel() {
    if (!imagePublicId) handleClosePopupBox();

    if (imagePublicId && data?.image?.imagePublicId !== imagePublicId) {
      const confirmBox = window.confirm(
        `You have uploaded a image to server.\nDo you still want to close it?`,
      );
      if (confirmBox) {
        handleClosePopupBox();
      }
    }
  }

  function handleFormStep1Submit(e) {
    e.preventDefault();

    // Reset states
    setError('');
    setIsLoading(true);

    if (index > -1) {
      // Change item
      data.values[index] = { imageLgUrl: imageLgUrl };
    } else {
      // Add new item
      data.values.unshift({ imageLgUrl: imageLgUrl });
    }
    const newData = data;

    const options = {
      merge: true,
    };

    // console.log('newData', newData);

    // Save data
    try {
      setDbData({
        collection: 'slideBanners',
        docId: '001',
        data: newData,
        options,
      });
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }

    if (!error) {
      notify({
        type: 'success',
        message: 'Record successfully saved!',
      });

      handleClosePopupBox();
    }
  }

  const uploaderNotes = ['Width: 1240px, height: 280px '];

  return (
    <div style={{ width: '100%' }}>
      <h1>{`${index > -1 ? 'Edit' : 'New'} Slide Banner`}</h1>

      <p className='remarks'>* must be inputed</p>

      {error && <ResultMessageBox type='error' messages={error} />}

      <form onSubmit={handleFormStep1Submit}>
        <div className='paper'>
          <h4>Upload Image *</h4>

          <ImageUploader
            currentImages={currentImages}
            handleUpdateImageId={handleUpdateImageId}
            uploadPreset={
              process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET_SLIDEBANNERS
            }
            uploaderNotes={uploaderNotes}
          />
        </div>

        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          <Button
            type='submit'
            text='Save'
            size='sm'
            color='secondary'
            fontWeight='bold'
            isLoading={isLoading}
          />

          <Button
            type='button'
            text='Close'
            size='sm'
            color='grey'
            fontWeight='bold'
            onClick={handleCancel}
          />
        </div>
      </form>
    </div>
  );
}
