import { useState, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useHistory } from 'react-router-dom';
import Recaptcha from 'react-recaptcha';

import { useLang } from './../../contexts/lang.context';
import { useAuth } from '../../contexts/auth.context';
import Button from '../../components/form/Button';
import TextInput from '../../components/form/TextInput';
import text from './text';
import './styles.scss';

export default function SignIn() {
  const history = useHistory();
  const { lang } = useLang();
  const formRef = useRef();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const { signIn } = useAuth();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function showParagraph1() {
    switch (lang) {
      case 'TC':
        return (
          <div>
            未有帳戶？<HashLink to='/signup'>按此註冊</HashLink>成為會員。
          </div>
        );
      case 'SC':
        return (
          <div>
            未有帐户？<HashLink to='/signup'>按此注册</HashLink>成为会员。
          </div>
        );
      default:
        return (
          <div>
            Don't have an account?&nbsp;
            <HashLink to='/signup'>Sign up here</HashLink>.
          </div>
        );
    }
  }

  const recaptchaLoaded = () => {
    // console.log('recaptcha loaded');
  };

  const verifyCallback = (response) => {
    if (response) {
      // console.log(response);
      setIsVerified(true);
    }
  };

  async function handleFormSubmit(e) {
    e.preventDefault();

    setError('');
    setIsLoading(true);

    if (isVerified) {
      try {
        await signIn(email, password);
        history.push('/myaccount');
      } catch (error) {
        setError(error.message);
        return formRef.current.scrollIntoView();
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <div id='pageSignIn' className='container'>
      <div className='row'>
        <h1 className='pageTitle'>{text.PAGE_TITLE[`lang${lang}`]}</h1>

        <div className='formWrapper' ref={formRef}>
          {error && <div className='messageBox'>{error}</div>}

          <div className='mb-2'>
            <form onSubmit={handleFormSubmit}>
              {/* <div className='remarks'>{text.TEXT1[`lang${lang}`]}</div> */}

              <h3>{text.TEXT2[`lang${lang}`]}</h3>

              <div className='formGroup'>
                <TextInput
                  type='email'
                  placeholder={`${text.EMAIL[`lang${lang}`]} *`}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                  autoFoucs
                />
              </div>

              <div className='formGroup'>
                <TextInput
                  type='password'
                  placeholder={`${text.PASSWORD[`lang${lang}`]} *`}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required
                />
              </div>

              <div className='formGroup'>
                <div className='recaptcha'>
                  <Recaptcha
                    sitekey='6LeeNlocAAAAACefFS5vvWLowerLqD59vupYPgl9'
                    render='explicit'
                    onloadCallback={recaptchaLoaded}
                    verifyCallback={verifyCallback}
                  />
                </div>
              </div>

              <div className='formGroup'>
                <Button
                  type='submit'
                  variant='contained'
                  color='secondary'
                  size='sm'
                  fontWeight='bold'
                  isLoading={isLoading}
                >
                  {text.SIGNIN[`lang${lang}`]}
                </Button>
              </div>

              <HashLink to='/resetpassword'>
                {text.FORGOT_PASSWORD[`lang${lang}`]}
              </HashLink>
            </form>
          </div>

          {showParagraph1()}
        </div>
      </div>
    </div>
  );
}
