import styled from 'styled-components';
import { NavHashLink } from 'react-router-hash-link';
import { useLang } from './../../../contexts/lang.context';
import { useAuth } from '../../../contexts/auth.context';

const text = {
  TITLE: {
    langTC: '我的帳戶',
    langSC: '我的帐户',
    langEN: 'My Account',
  },
  DASHBOARD: {
    langTC: '主頁摘要',
    langSC: '主页摘要',
    langEN: 'Dashboard',
  },
  TRANSACTIONS: {
    langTC: '交易記錄',
    langSC: '交易记录',
    langEN: 'Transactions',
  },
  CONTACT_INFO: {
    langTC: '聯絡資料',
    langSC: '联络资料',
    langEN: 'Contact Info.',
  },
  SECURITY: {
    langTC: '安全性',
    langSC: '安全性',
    langEN: 'Security',
  },
  SIGNOUT: {
    langTC: '登出',
    langSC: '登出',
    langEN: 'Sign Out',
  },
};

export default function SideBar() {
  const { lang } = useLang();
  const { signOut } = useAuth();

  return (
    <NavList>
      <NavHeader>{text.TITLE[`lang${lang}`]}</NavHeader>

      <NavItem>
        <NavHashLink to='/myaccount' activeClassName='selected'>
          {text.DASHBOARD[`lang${lang}`]}
        </NavHashLink>
      </NavItem>

      <NavItem>
        <NavHashLink to='/myaccount/transactions' activeClassName='selected'>
          {text.TRANSACTIONS[`lang${lang}`]}
        </NavHashLink>
      </NavItem>

      <NavItem>
        <NavHashLink to='/myaccount/contactinfo' activeClassName='selected'>
          {text.CONTACT_INFO[`lang${lang}`]}
        </NavHashLink>
      </NavItem>

      <NavItem>
        <NavHashLink to='/myaccount/security' activeClassName='selected'>
          {text.SECURITY[`lang${lang}`]}
        </NavHashLink>
      </NavItem>

      <NavItem>
        <NavHashLink to='/' onClick={signOut}>
          {text.SIGNOUT[`lang${lang}`]}
        </NavHashLink>
      </NavItem>
    </NavList>
  );
}

const NavList = styled.div`
  width: 100%;
  max-width: 160px;
  margin-top: 2.5rem;
  border-right: 1px solid var(--color-grey-light-2);
`;

const NavHeader = styled.div`
  height: 60px;
  border-bottom: 1px solid var(--color-grey-light-2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  font-size: 2rem;
  font-weight: bold;
  color: var(--color-secondary-dark);
`;

const NavItem = styled.div`
  a {
    display: block;
    padding: 1rem 2rem;
    transition: all 0.2s;
    text-decoration: none;
    color: var(--color-default-text);

    &:hover {
      /* background-color: rgba(0, 0, 0, 0.05); */
      background-color: var(--color-secondary-light);
    }

    &.selected {
      background-color: var(--color-secondary-dark);
      color: white;
    }
  }
`;
