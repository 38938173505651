import { useState, useContext, createContext } from "react";

const SearchContext = createContext();

export function useSearch() {
  return useContext(SearchContext);
}

export default function SearchProvider({ children }) {
  const [searchKeyword, setSearchKeyword] = useState('');

  const value = {
    searchKeyword,
    setSearchKeyword
  };

  return (
    <SearchContext.Provider value={value}>
      {children}
    </SearchContext.Provider>
  );
}