import { NavHashLink } from 'react-router-hash-link';
import styled from 'styled-components';

export default function NavItem({ link }) {
  return (
    <Container>
      <NavHashLink to={`/admin/${link.path}#top`} activeClassName='selected'>
        {link.text}
      </NavHashLink>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;

  a {
    color: white;
    text-decoration: none;
    display: block;
    padding: 8px 20px;
    font-size: 1.4rem;
    transition: all 0.2s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &.selected {
      background-color: rgba(255, 255, 255, 0.2);
      /* border-left: 10px solid var(--color-secondary); */
    }
  }
`;
