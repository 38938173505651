import ProductDetails from './../productDetails/index';
import './styles.scss';

export default function PopupCard({ show, product, setShowPopupCard }) {
  function handleBackdropOnClick(e) {
    if (e.target.className === 'backdrop') setShowPopupCard(false);
  }

  function handleCloseCard() {
    setShowPopupCard(false);
  }

  return (
    <div id='popupCard' className={`${show ? 'show' : ''}`}>
      <div className='backdrop' onClick={handleBackdropOnClick}>
        <div className='foreground'>
          <div className='foreground__closeBtn' onClick={handleCloseCard}>
            &#10006;
          </div>

          <ProductDetails product={product} />
        </div>
      </div>
    </div>
  );
}
