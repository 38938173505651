import { useState, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useHistory } from 'react-router-dom';
import Recaptcha from 'react-recaptcha';

import { useLang } from './../../contexts/lang.context';
import { useAuth } from '../../contexts/auth.context';
import Button from '../../components/form/Button';
import TextInput from '../../components/form/TextInput';
import text from './text';
import './styles.scss';

export default function ResetPassword() {
  const history = useHistory();
  const { lang } = useLang();
  const formRef = useRef();
  const [email, setEmail] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const { sendResetPassword } = useAuth();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  function showParagraph1() {
    switch (lang) {
      case 'TC':
        return `填寫您帳戶的電郵地址，我們會發送一封包含重設密碼連結的電郵給您。`;
      case 'SC':
        return `填写您帐户的电邮地址，我们会发送一封包含重设密码连结的电邮给您。`;
      default:
        return `Please fill in the email address of your account, we will send you an email with a link to reset your password.`;
    }
  }

  function showParagraph2() {
    switch (lang) {
      case 'TC':
        return (
          <div>
            重設密碼的電郵已發出，請檢查您的郵箱，按電郵指示重設您的密碼。
          </div>
        );
      case 'SC':
        return (
          <div>
            重设密码的电邮已发出，请检查您的邮箱，按电邮指示重设您的密码。
          </div>
        );
      default:
        return (
          <div>
            An email to reset your password has been sent. Please check your
            email and follow the instructions to reset your password.
          </div>
        );
    }
  }

  const recaptchaLoaded = () => {
    // console.log('recaptcha loaded');
  };

  const verifyCallback = (response) => {
    if (response) {
      // console.log(response);
      setIsVerified(true);
    }
  };

  async function handleFormSubmit(e) {
    e.preventDefault();

    setError('');
    setIsLoading(true);

    if (isVerified) {
      try {
        await sendResetPassword(email);
        setIsSent(true);
      } catch (error) {
        setError(error.message);
        return formRef.current.scrollIntoView();
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <div id='pageResetPassword' className='container'>
      <div className='row'>
        <h1 className='pageTitle'>{text.PAGE_TITLE[`lang${lang}`]}</h1>

        {isSent ? (
          showParagraph2()
        ) : (
          <div className='formWrapper' ref={formRef}>
            {error && <div className='messageBox'>{error}</div>}

            <form onSubmit={handleFormSubmit}>
              <p>{showParagraph1()}</p>

              <div className='remarks'>
                {text.MUST_BE_COMPLETED[`lang${lang}`]}
              </div>

              <h3>{text.YOUR_LOGIN_EMAIL[`lang${lang}`]}</h3>

              <div className='formGroup'>
                <TextInput
                  type='email'
                  placeholder={`${text.EMAIL[`lang${lang}`]} *`}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                  autoFoucs
                />
              </div>

              <div className='formGroup'>
                <div className='recaptcha'>
                  <Recaptcha
                    sitekey='6LeeNlocAAAAACefFS5vvWLowerLqD59vupYPgl9'
                    render='explicit'
                    onloadCallback={recaptchaLoaded}
                    verifyCallback={verifyCallback}
                  />
                </div>
              </div>

              <div className='formGroup'>
                <Button
                  type='submit'
                  variant='contained'
                  color='secondary'
                  size='sm'
                  fontWeight='bold'
                  text={text.SUBMIT[`lang${lang}`]}
                  isLoading={isLoading}
                />
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}
