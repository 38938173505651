import { useState } from 'react';
import axios from 'axios';
import { Image, Transformation } from 'cloudinary-react';

export default function Upload() {
  const [imageSelected, setImageSelected] = useState(null);
  const [imagePublicId, setImagePublicId] = useState('');
  const [previewSource, setPreviewSource] = useState(null);

  function previewFile(file) {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };

    setImageSelected(file);
  }

  function handleUploadImg() {
    // const files = e.target.files;
    const formData = new FormData();
    formData.append('file', imageSelected);
    // formData.append('upload_preset', 'ei6pocpo');
    formData.append('upload_preset', 'test_ynbh2bho');

    // console.log(files[0]);

    axios
      .post('https://api.cloudinary.com/v1_1/ebasell/image/upload/', formData)
      .then((response) => {
        setImagePublicId(response.data.public_id);
        console.log(response);
      })
      .catch((error) => console.log(error));
  }

  return (
    <div id='pageUpload' className='container'>
      <div className='row'>
        <h1 className='pageTitle'>UPLOAD</h1>

        <input type='file' onChange={(e) => previewFile(e.target.files[0])} />
        <br />
        <button onClick={handleUploadImg}>Upload</button>
        <br />

        {previewSource && (
          <div>
            <h2>Image preview:</h2>
            <img src={previewSource} alt='' style={{ width: '250px' }} />
          </div>
        )}

        {imagePublicId && (
          <div>
            <h2>Image uploaded - PublicId: {imagePublicId}</h2>
            <Image cloudName='ebasell' publicId={imagePublicId}>
              <Transformation width='300' height='300' crop='fill' />
            </Image>
          </div>
        )}
      </div>
    </div>
  );
}
