import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import productsData from '../../data/products';
import { useLang } from './../../contexts/lang.context';
import ProductDetails from '../../components/productDetails';
import useGetDbDoc from './../../hooks/useGetDbDoc';

export default function Product() {
  const { lang } = useLang();
  const { uid } = useParams();
  const [product, setProduct] = useState(null);
  const { data, fetchError, isFetching } = useGetDbDoc({
    collection: 'products',
    docId: uid,
  });

  const text = {
    PRODUCT_NOT_FOUND: {
      langTC: '找不到這個貨品，請返回上一頁並再試一次。',
      langSC: '找不到這個貨品，請返回上一頁並再試一次。',
      langEN: 'Product item not found, please go back and try again.',
    },
  };

  // Load product data
  // useEffect(() => {
  //   const filteredProduct = productsData.find((product) => product.uid === uid);
  //   setProduct(filteredProduct);
  // }, [uid]);

  useEffect(() => {
    if (isFetching) return;

    if (data.isDisable) return;

    setProduct(data);
  }, [isFetching]);

  return (
    <div id='pageProduct' className='container'>
      <div className='row'>
        {product ? (
          <ProductDetails product={product} />
        ) : (
          text.PRODUCT_NOT_FOUND[`lang${lang}`]
        )}
      </div>
    </div>
  );
}
