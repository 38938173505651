import useWindowSize from '../hooks/useWindowSize';
import PagePadding from '../components/PagePadding';
import AdminHeaderBar from '../components/admin/HeaderBar';
import SideBar from '../components/admin/SideBar';
import styled from 'styled-components';

export default function AdminLayout({ children }) {
  const { mediaMode } = useWindowSize();

  return (
    <Container>
      <SideBar />
      <div style={{ flex: 1 }}>
        <AdminHeaderBar />
        {(mediaMode === 'mobile' || mediaMode === 'tablet') && <PagePadding height='50px' />}
        {children}
      </div>
    </Container>
  );
}

const Container = styled.div`
  display:flex;
  flex-direction:row;
  background-color:#F6F7F9;
`;