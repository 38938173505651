import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

// import { db } from '../../../data/firestore/config';
import { deleteDbData } from './../../../utils';
import useListenDbList from './../../../hooks/useListenDbList';
import Button from '../../../components/form/Button';
import PopupBox from '../../../components/PopupBox';
import Editor from '../../../components/admin/editors/EditNews';
import LoadingSpin from '../../../components/LoadingSpin';

const customColumnSort = (rowA, rowB) => {
  if (rowA.newsDate > rowB.newsDate) {
    return -1;
  } else if (rowA.newsDate < rowB.newsDate) {
    return 1;
  }
  return 0;
};

const columns = [
  {
    name: 'Edit',
    selector: (row) => row.btnEdit,
    maxWidth: '40px',
  },
  {
    name: 'Date',
    selector: (row) => row.date,
    sortable: true,
    maxWidth: '140px',
  },
  {
    name: 'Subject',
    selector: (row) => row.subject.langEN,
    sortable: true,
  },
  {
    name: 'Delete',
    selector: (row) => row.btnDelete,
    maxWidth: '40px',
  },
];

export default function News() {
  const [isShowPopup, setIsShowPopup] = useState(false);
  // const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editorData, setEditorData] = useState(null);
  const { data, fetchError, isFetching } = useListenDbList({
    collection: 'news',
  });

  useEffect(() => {
    if (isFetching) return;

    let tempData = [];
    setIsLoading(true);

    data.forEach((doc) => {
      tempData.push({
        ...doc,
        date: moment(doc.newsDate).format('YYYY-MM-DD'),
        btnEdit: createEditButton(doc),
        btnDelete: createDeleteButton(doc.docId),
      });
    });

    tempData.sort(customColumnSort);
    setTableData(tempData);
    setIsLoading(false);
  }, [data, isFetching]);

  function createEditButton(data) {
    return (
      <EditIcon
        fontSize='large'
        className='clickable'
        // color=''
        style={{ color: '#33a35e' }}
        onClick={() => handleEditItem(data)}
      />
    );
  }

  function createDeleteButton(docId) {
    return (
      <DeleteIcon
        fontSize='large'
        className='clickable'
        color='error'
        onClick={() => handleDeleteItem(docId)}
      />
    );
  }

  function handleAddNewItem() {
    setEditorData({});
    setIsShowPopup(true);
  }

  function handleEditItem(data) {
    setEditorData(data);
    setIsShowPopup(true);
  }

  function handleDeleteItem(docId) {
    const confirmBox = window.confirm(`Do you want to delete this item?`);
    if (confirmBox) {
      deleteDbData({ collection: 'news', docId });
    }
  }

  function handleClosePopupBox() {
    setIsShowPopup(false);
  }

  return (
    <div className='container-full'>
      <div className='row'>
        <h1>News</h1>
        <div>
          <Button
            text='Add New News'
            size='sm'
            color='secondary'
            fontWeight='bold'
            onClick={handleAddNewItem}
          />
        </div>
      </div>

      {!isLoading && !isFetching ? (
        <div className='row'>
          <div className='paper'>
            <DataTable
              columns={columns}
              data={tableData}
              pagination
              defaultSortFieldId={1}
              progressPending={isLoading}
            />
          </div>
        </div>
      ) : (
        <LoadingSpin />
      )}

      {isShowPopup && (
        <PopupBox
          handleClosePopupBox={handleClosePopupBox}
          backdropClickable={false}
        >
          <Editor data={editorData} handleClosePopupBox={handleClosePopupBox} />
        </PopupBox>
      )}
    </div>
  );
}
