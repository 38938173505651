import { useLang } from '../../contexts/lang.context';
import useGetDbList from '../../hooks/useGetDbList';
import LoadingSpin from '../../components/LoadingSpin';

const text = {
  PAGE_TITLE: {
    langTC: '關於送貨',
    langSC: '关于送货',
    langEN: 'ABOUT DELIVERY',
  },
};

export default function AboutDelivery() {
  const { lang } = useLang();
  const { data, isLoading } = useGetDbList({
    collection: 'aboutDelivery',
  });

  return (
    <div id='pageAboutDelivery' className='container'>
      <div className='row'>
        <h1 className='pageTitle'>{text.PAGE_TITLE[`lang${lang}`]}</h1>

        {isLoading ? (
          <LoadingSpin />
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: data[0]?.content?.[`lang${lang}`],
            }}
          />
        )}
      </div>
    </div>
  );
}
