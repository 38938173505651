import { useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Image, Transformation } from 'cloudinary-react';
import CurrencyFormat from 'react-currency-format';

import { useLang } from './../../contexts/lang.context';
import { useCart } from './../../contexts/cart.context';
import useWindowSize from '../../hooks/useWindowSize';
import useToastify from '../../hooks/useToastify';
import NumInputGroup from '../form/NumInputGroup';
import Button from '../form/Button';
import outputBrandName from './../../utils/outputBrandName';
import { useBrands } from './../../contexts/brands.context';

export default function ProductCard({
  product,
  setShowPopupCard,
  setSelectProduct,
}) {
  const history = useHistory();
  const { lang } = useLang();
  const { mediaMode } = useWindowSize();
  const { brands } = useBrands();
  const qtyRef = useRef();
  const { cartDispatch, cartActions } = useCart();
  const notify = useToastify();

  const text = {
    SPECIAL_OFFER: {
      langTC: '特價',
      langSC: '特价',
      langEN: 'SPECIAL OFFER',
    },
    MORE_INFO: {
      langTC: '詳細資料',
      langSC: '详细资料',
      langEN: 'MORE INFO.',
    },
    ADD_TO_CART: {
      langTC: '加入購物籃',
      langSC: '加入购物篮',
      langEN: 'ADD TO CART',
    },
    OUT_OF_STOCK: {
      langTC: '缺貨',
      langSC: '缺货',
      langEN: 'OUT OF STOCK',
    },
    TOAST_ADD_SUCCESS: {
      langTC: '貨品已加到購物籃',
      langSC: '货品已加到购物篮',
      langEN: 'Added product into cart.',
    },
    TOAST_ADD_QTY_WARN: {
      langTC: '必須輸入數量',
      langSC: '必须输入数量',
      langEN: 'Qty is required.',
    },
  };

  function handleMinus() {
    if (!qtyRef.current.value || qtyRef.current.value <= 1) {
      qtyRef.current.value = 1;
      return;
    }

    qtyRef.current.value--;
  }

  function handlePlus() {
    if (!qtyRef.current.value || qtyRef.current.value <= 0) {
      qtyRef.current.value = 1;
      return;
    }

    qtyRef.current.value++;
  }

  function handleShowInfo() {
    if (mediaMode === 'mobile') {
      history.push(`/product/${product.docId}`);
    } else {
      setSelectProduct(product);
      setShowPopupCard(true);
    }
  }

  function handleQtyInput(e) {
    if (!qtyRef.current.value || qtyRef.current.value <= 0) {
      qtyRef.current.value = 1;
      return;
    }

    qtyRef.current.value = parseInt(e.target.value);
  }

  function handleAddToCart() {
    const qty = Number(qtyRef.current.value);
    const stock = product.stock;

    if (!qtyRef.current.value || qtyRef.current.value <= 0) {
      notify({
        type: 'warn',
        message: `${text.TOAST_ADD_QTY_WARN[`lang${lang}`]}`,
      });
      return;
    }

    // Not enough stock
    if (stock >= 1 && stock < qty) {
      notify({
        type: 'warn',
        message: `Quantity of this item is ${stock} only!`,
      });
      return;
    }

    const payload = {
      uid: product.docId,
      qty,
      unit: product.unit,
      unitPrice: product.isPromotion
        ? product.promotionPrice
        : product.unitPrice,
      brand: product.brand,
      brandName: outputBrandName({ brands, code: product.brand, lang }),
      briefDesc: product.briefDesc,
      modelNo: product.modelNo,
      productCode: product.productCode,
      imagePublicId: product.imagePublicId,
      stock,
    };
    cartDispatch({ type: cartActions.ADD_TO_CART, payload: payload });

    notify({
      type: 'success',
      message: `${text.TOAST_ADD_SUCCESS[`lang${lang}`]}`,
    });
  }

  return (
    <Container>
      <div className='card__img clickable' onClick={handleShowInfo}>
        <Image cloudName='ebasell' publicId={product.imagePublicId}>
          <Transformation height='400' width='400' crop='fill' />
        </Image>
        {/* <img src={product.imageUrl} alt='' /> */}
      </div>

      <div className='card__brand'>
        {outputBrandName({ brands, code: product.brand, lang })}
      </div>

      {/* <div className='card__content'>{product.productDetails.langEN}</div> */}
      <div className='card__content'>{product.briefDesc}</div>

      <div className='card__price'>
        <div>
          {product.isPromotion && (
            <div className='card__price__subject'>
              {text.SPECIAL_OFFER[`lang${lang}`]}
            </div>
          )}
          <div className='card__price__unitprice'>
            HKD{' '}
            <CurrencyFormat
              value={
                product.isPromotion ? product.promotionPrice : product.unitPrice
              }
              displayType={'text'}
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
            />
            /{product.unit}
          </div>
        </div>
      </div>

      <div className='card__qty'>
        {product.stock !== 0 && (
          <NumInputGroup>
            <button onClick={handleMinus}>-</button>
            <input
              type='number'
              step='1'
              min='1'
              max='9999'
              ref={qtyRef}
              defaultValue={1}
              onChange={handleQtyInput}
            />
            <button onClick={handlePlus}>+</button>
          </NumInputGroup>
        )}
      </div>

      <div className='card__buttons'>
        <Button
          text={text.MORE_INFO[`lang${lang}`]}
          variant='outlined'
          color='secondary'
          onClick={handleShowInfo}
        />

        <Button
          text={
            product.stock === 0
              ? text.OUT_OF_STOCK[`lang${lang}`]
              : text.ADD_TO_CART[`lang${lang}`]
          }
          variant='contained'
          color={product.stock === 0 ? 'grey' : 'secondary'}
          onClick={handleAddToCart}
          disabled={product.stock === 0}
        />
      </div>
    </Container>
  );
}

const Container = styled.div`
  background-color: white;
  box-shadow: var(--shadow-1);
  padding: 10px;
  display: flex;
  flex-direction: column;

  .card {
    &__img {
      width: 100%;
      height: 200px;
      margin-bottom: 10px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__brand {
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 5px;
    }

    &__content {
      margin-bottom: 20px;
      font-size: 1.5rem;
      /* height: 120px; */
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6; /* number of lines to show */
      -webkit-box-orient: vertical;
      flex: 1;
    }

    &__price {
      color: var(--color-primary);
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__subject {
        font-size: 1.6rem;
      }

      &__unitprice {
        font-size: 2rem;
        font-weight: bold;
      }
    }

    &__qty {
      width: auto;
      min-height: 46px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      /* background-color: #ccc; */
      /* border: 3px solid #ccc; */
      /* border-radius: 5px; */
      border: none;
      margin: 0;
      /* padding: 3px 0; */
      font-size: 1.5rem;
    }

    &__buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
    }
  }
`;

const OutOfStock = styled.div`
  color: var(--color-default-text);
`;
