import styled from 'styled-components';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';

export default function ResultMessageBox({ type, messages }) {
  function showIcon() {
    switch (type) {
      case 'warning':
        return <ErrorOutlineRoundedIcon style={{ fontSize: 24 }} />;
      case 'error':
        return <ErrorOutlineRoundedIcon style={{ fontSize: 24 }} />;
      case 'process':
        return <HourglassEmptyRoundedIcon style={{ fontSize: 24 }} />;
      case 'success':
        return <CheckCircleOutlineRoundedIcon style={{ fontSize: 24 }} />;
      default:
        break;
    }
  }

  return (
    <Container className={type}>
      <div>{showIcon()}</div>
      <div>{messages}</div>
    </Container>
  );
}

const Container = styled.div`
  border-radius: 5px;
  padding: 20px 20px;
  margin-bottom: 20px;
  font-size: 1.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;

  &.warning {
    background-color: rgba(255, 227, 102, 0.3);
    color: #8f2100;
  }

  &.error {
    background-color: rgba(255, 133, 133, 0.3);
    color: #380000;
  }

  &.process {
    background-color: rgba(173, 241, 243, 0.6);
    color: #001950;
  }

  &.success {
    background-color: rgba(102, 236, 131, 0.3);
    color: #00330f;
  }
`;
