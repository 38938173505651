import axios from 'axios';

export default async function sendOrderConfirmToAdmin({ transactionData, cart }) {
  const uid = transactionData.transactionId?.toUpperCase();
  const emailTo = process.env.REACT_APP_PAYMENT_NOTIFICATION_EMAIL_TO;
  const emailFrom = process.env.REACT_APP_PAYMENT_NOTIFICATION_EMAIL_FROM;
  const emailFromDisplay = 'ebasell Online Shop';
  const emailReplyTo = process.env.REACT_APP_PAYMENT_NOTIFICATION_EMAIL_FROM;
  const emailSubject = `Online Order Notification (Transaction ID: ${transactionData.transactionId?.toUpperCase()})`;
  let emailBody = `<span style='font-size:24px; margin:10px 0'>Order Notification</span><br />`;
  emailBody += `<p style='margin-bottom:20px'>`;
  // emailBody += `<small><i>Ref. ID: ${uid}</i></small><br /><br />`;
  emailBody += `<b>Transaction ID: </b>${transactionData.transactionId?.toUpperCase()}<br />`;
  // emailBody += `<b>Payment Method: </b>${transactionData.tel}<br />`;
  // emailBody += `<b>Payment Method: </b>${transactionData.tel}<br />`;
  emailBody += `<b>Contact Name: </b>${transactionData.contactName}<br />`;
  emailBody += `<b>Tel: </b>${transactionData.tel}<br />`;
  emailBody += `<b>Payment Type: </b>${transactionData.paymentDetails?.paymentType === 'bankin' ? 'BANK IN' :
    transactionData.paymentDetails?.paymentType?.toUpperCase()}<br />`;
  emailBody += `</p>`;

  emailBody += `<table cellpadding=5 cellspacing=0 style='border-collapse:collapse; border:0;'>`;
  emailBody += `<thead style='background:#DA3832; color:#fff'><tr><td>Brand</td><td>Desc.</td><td>Qty</td><td align='right'>Unit Price</td><td align='right'>Amount</td></tr></thead>`;
  emailBody += `<tbody>`;
  cart.forEach(item => {
    emailBody += `<tr style='border-bottom:#aaa solid 1px'>`;
    emailBody += `<td valign='top'>${item.brandName}</td>`;
    emailBody += `<td valign='top'>${item.briefDesc}<br/>Product Code: ${item.productCode}</td>`;
    emailBody += `<td valign='top'>${item.qty}/${item.unit}</td>`;
    emailBody += `<td valign='top' align='right'>${transactionData.currency?.toUpperCase()} $${item.unitPrice}</td>`;
    emailBody += `<td valign='top' align='right'>${transactionData.currency?.toUpperCase()} $${item.qty * item.unitPrice}</td>`;
    emailBody += `</tr>`;
  });
  emailBody += `</tbody>`;
  emailBody += `<tfoot>`;
  emailBody += `<tr><td colspan='99'>Amount ${transactionData.currency?.toUpperCase()} $${transactionData.amount}</td></tr>`;
  emailBody += `</tfoot>`;
  emailBody += `</table>`;

  emailBody += `<br /><span style='font-size:22px; margin:20px 0 0'>Delivery Information</span><br />`;
  emailBody += `<p style='margin:0'>`;
  emailBody += `<b>Delivery Method: </b>${transactionData.deliveryMethod === 'SelfCollect' ? 'Self collect' : 'HK standard delivery'}<br />`;

  if (transactionData.deliveryMethod === 'SelfCollect') {
    emailBody += `<b>Self Collect Address: </b>Unit 1, G/F., Kai Fuk Industrial Centre, 1 Wang Tung Street, Kowloon Bay, Kowloon, Hong Kong<br />`;
  } else {
    emailBody += `<b>Delivery Address: </b><br />`;
    emailBody += `${transactionData.deliveryCountry.name}<br />`;
    emailBody += `${transactionData.deliveryAddressLine1}<br />`;
    emailBody += `${transactionData.deliveryAddressLine2}<br />`;
    emailBody += `${transactionData.deliveryAddressLine3}<br />`;
  }
  emailBody += `</p>`;

  // Call api to send email
  await axios
    .post(process.env.REACT_APP_SEND_EMAIL_API_URL, {
      uid, emailTo, emailFrom, emailFromDisplay, emailReplyTo, emailSubject, emailBody
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw new Error(error);
      // console.log(error);
    });
}
