import { useState, useRef } from 'react';
import axios from 'axios';
import { Image, Transformation } from 'cloudinary-react';
import Button from '../form/Button';
import ResultMessageBox from '../ResultMessageBox';
import './styles.scss';

export default function ImageUploader({
  currentImages = [],
  handleUpdateImageId,
  uploadPreset,
  uploaderNotes = [],
}) {
  const [imageSelected, setImageSelected] = useState(null);
  const [previewSource, setPreviewSource] = useState(null);
  // const [imagePublicId, setImagePublicId] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [error, setError] = useState('');
  const [process, setProcess] = useState('');
  const [result, setResult] = useState('');
  const msgBox = useRef();

  function previewFile(file) {
    const reader = new FileReader();

    setError('');
    setProcess('');
    setResult('');

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };

    setImageSelected(file);
  }

  function handleUploadImg() {
    // Reset status
    setIsUploaded(false);
    setIsUploading(true);
    setError('');
    setProcess('Uploading image...');
    setResult('');

    // const files = e.target.files;
    const formData = new FormData();
    formData.append('file', imageSelected);
    // formData.append('upload_preset', 'products_wusv7a0l');
    formData.append('upload_preset', uploadPreset);

    axios
      .post('https://api.cloudinary.com/v1_1/ebasell/image/upload/', formData)
      .then((res) => {
        // setImagePublicId(res.data.public_id);
        handleUpdateImageId({
          publicId: res.data.public_id,
          imageUrl: res.data.secure_url,
        });
        // console.log(res);

        setResult('Image has been uploaded to server.');
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      })
      .finally(() => {
        setProcess('');
        setIsUploading(false);
        setIsUploaded(true);
      });
  }

  return (
    <div className='imgUploader'>
      <div className='imgUploader__selectFile'>
        <div className='imgUploader__notes'>
          Notes:
          <ul>
            <li>File size maxium 2MB</li>
            {uploaderNotes.map((note, index) => (
              <li key={index}>{note}</li>
            ))}
            {/* <li>File size...</li>
            <li>File size...</li> */}
          </ul>
        </div>

        <div className='formGroup'>
          <label>
            Select image file:
            <input
              type='file'
              onChange={(e) => previewFile(e.target.files[0])}
            />
          </label>
        </div>

        <div className='imgUploader__preview mb-2'>
          <h5>Image preview:</h5>
          {previewSource && (
            <div>
              <img src={previewSource} alt='' style={{ maxHeight: '180px' }} />
            </div>
          )}
        </div>

        <div className='mb-1'>
          <Button
            type='button'
            color='secondary'
            text='Upload Image'
            fontWeight='bold'
            onClick={handleUploadImg}
            disabled={!imageSelected}
            isLoading={isUploading}
          />
        </div>

        <div className='mb-1' ref={msgBox}>
          {error && <ResultMessageBox type='error' messages={error} />}
          {process && <ResultMessageBox type='process' messages={process} />}
          {result && <ResultMessageBox type='success' messages={result} />}
        </div>
      </div>

      {currentImages.length > 0 && (
        <>
          <div className='imgUploader__viewer'>
            <h5>Current image:</h5>
            {/* <div>No image.</div> */}

            {currentImages.map((image, index) => (
              <img key={index} src={image} alt='' />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
