import { useState, useRef, useEffect } from 'react';
import './styles.scss';

export default function Dropdown({
  options,
  id,
  label,
  prompt,
  value,
  onChange,
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(() => {
    ['click', 'touchend'].forEach((e) => {
      document.addEventListener(e, toggle);
    });

    return () =>
      ['click', 'touchend'].forEach((e) => {
        document.removeEventListener(e, toggle);
      });
  }, []);

  function toggle(e) {
    setIsOpen(e && e.target === ref.current);
    // setIsOpen((prevState) => !prevState);
  }

  function filter(options) {
    return options.filter(
      (option) => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1,
    );
  }

  function displayValue() {
    if (query.length > 0) return query;
    if (value) return value[label];
    return '';
  }

  function selectOption(option) {
    setQuery('');
    onChange(option);
    setIsOpen(false);
  }

  return (
    <div className='dropdown'>
      <div className='control'>
        <div className='selectedValue'>
          <input
            type='text'
            ref={ref}
            placeholder={value ? value[label] : prompt}
            value={displayValue()}
            onChange={(e) => {
              setQuery(e.target.value);
              onChange(null);
            }}
            onClick={toggle}
            onTouchEnd={toggle}
          />
        </div>
        <div className={`arrow ${isOpen ? 'open' : null}`} />
      </div>

      <div className={`options ${isOpen ? 'open' : null}`}>
        {filter(options).map((option) => (
          <div
            key={option[id]}
            className={`option ${value === option ? 'selected' : null}`}
            onClick={() => selectOption(option)}
            onTouchEnd={() => selectOption(option)}
          >
            {option[label]}
          </div>
        ))}
      </div>
    </div>
  );
}
