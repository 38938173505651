import { useState } from 'react';
import { nanoid } from 'nanoid';
import useToastify from '../../../../hooks/useToastify';
import { setDbData } from '../../../../utils';
import Button from '../../../form/Button';
import TextInput from '../../../form/TextInput';
import ImageUploader from '../../../ImageUploader';
import ResultMessageBox from '../../../ResultMessageBox';

const uploaderNotes = ['Recommend width: 400px, height: 320px '];

export default function EditRanges({ data, dataIndex, handleClosePopupBox }) {
  const notify = useToastify();
  const [isLoading, setIsLoading] = useState(false);
  const [nameEN, setNameEN] = useState(
    data.ranges[dataIndex]?.name?.langEN || '',
  );
  const [nameTC, setNameTC] = useState(
    data.ranges[dataIndex]?.name?.langTC || '',
  );
  const [nameSC, setNameSC] = useState(
    data.ranges[dataIndex]?.name?.langSC || '',
  );
  const [error, setError] = useState('');
  const [newImagePublicId, setNewImagePublicID] = useState('');
  const [newImageUrl, setNewImageUrl] = useState('');
  const imagePublicId = data.ranges[dataIndex]?.imagePublicId || '';
  const imageUrl = data.ranges[dataIndex]?.imageUrl || '';

  let currentImages = [];
  if (dataIndex > -1) currentImages.push(imageUrl);

  function handleUpdateImageId({ publicId, imageUrl }) {
    setNewImagePublicID(publicId);
    setNewImageUrl(imageUrl);
  }

  function handleCancel() {
    if (!newImagePublicId) {
      handleClosePopupBox();
      return;
    }

    const confirmBox = window.confirm(
      `You have uploaded a image to server.\nDo you still want to close it?`,
    );
    if (confirmBox) {
      handleClosePopupBox();
    }
  }

  function handleFormStep1Submit(e) {
    e.preventDefault();

    // Reset states
    setError('');
    setIsLoading(true);

    // New item data
    const editData = {
      name: {
        langEN: nameEN,
        langTC: nameTC,
        langSC: nameSC,
      },
      imagePublicId: newImagePublicId || imagePublicId || '',
      imageUrl: newImageUrl || imageUrl || '',
      isDisable: false,
    };

    let newDataSet = data;

    // Put new item data into dataset
    let uid = '';
    if (dataIndex > -1) {
      // Edit item
      uid = newDataSet.ranges[dataIndex].uid;
      newDataSet.ranges[dataIndex] = { ...editData, uid };
    } else {
      // Add new item
      uid = nanoid(8);
      // data.ranges.unshift({ ...editData, uid });
      newDataSet.ranges.push({ ...editData, uid });
    }

    const options = {
      merge: true,
    };

    // Save data
    try {
      setDbData({
        collection: 'ranges',
        docId: '001',
        data: newDataSet,
        options,
      });
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }

    if (!error) {
      notify({
        type: 'success',
        message: 'Record successfully saved!',
      });

      handleClosePopupBox();
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <h1>{`${data?.docId ? 'Edit' : 'New'} Range`}</h1>

      <p className='remarks'>* must be inputed</p>

      {error && <ResultMessageBox type='error' messages={error} />}

      <form onSubmit={handleFormStep1Submit}>
        <div className='paper'>
          <h4>Range Name *</h4>
          <div className='formGroup'>
            <label>
              English
              <TextInput
                type='text'
                value={nameEN}
                onChange={(e) => setNameEN(e.target.value)}
                required
              />
            </label>
          </div>

          <div className='formGroup'>
            <label>
              繁體中文
              <TextInput
                type='text'
                value={nameTC}
                onChange={(e) => setNameTC(e.target.value)}
                required
              />
            </label>
          </div>

          <div className='formGroup'>
            <label>
              简体中文
              <TextInput
                type='text'
                value={nameSC}
                onChange={(e) => setNameSC(e.target.value)}
                required
              />
            </label>
          </div>
        </div>

        <div className='paper'>
          <h4>Upload Image *</h4>

          <ImageUploader
            currentImages={currentImages}
            handleUpdateImageId={handleUpdateImageId}
            uploadPreset={process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET_RANGES}
            uploaderNotes={uploaderNotes}
          />
        </div>

        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          <Button
            type='submit'
            text='Save'
            size='sm'
            color='secondary'
            fontWeight='bold'
            isLoading={isLoading}
          />

          <Button
            type='button'
            text='Close'
            size='sm'
            color='grey'
            fontWeight='bold'
            onClick={handleCancel}
          />
        </div>
      </form>
    </div>
  );
}
