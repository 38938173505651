import styled from 'styled-components';
import { Image, Transformation } from 'cloudinary-react';
import useGetDbDoc from './../../hooks/useGetDbDoc';

export default function BottomAdv() {
  const { data, fetchError, isFetching } = useGetDbDoc({
    collection: 'advBanners',
    docId: '001',
  });

  // const adv1 =
  //   'https://res.cloudinary.com/ebasell/image/upload/v1634493489/commercials/pic1_nwjohl.jpg';

  return (
    <>
      {!isFetching && (
        <Wrapper>
          {data.bannerLg.map((banner, index) => (
            <Adv key={index}>
              <a
                href={banner.url}
                // target='_blank'
                className='adv'
                rel='noreferrer'
              >
                <img src={banner.imageUrl} alt='' />
              </a>
            </Adv>
          ))}
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  /* height: 120px; */
  /* cursor: pointer; */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Adv = styled.div`
  margin-bottom: 10px;
`;
