
import { useEffect, useState } from 'react';
import { db } from '../data/firestore/config';

export default function useListenDbList({ collection }) {
  const [data, setData] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const unsubscrib = db.collection(collection)
      .onSnapshot(snapshot => {
        let response = [];
        snapshot.forEach(doc => {
          response.push({ ...doc.data(), docId: doc.id });
        });
        setData(response);
        setIsFetching(false);
      }, error => {
        setFetchError(error);
        setIsFetching(false);
      });

    return unsubscrib;
  }, [collection]);

  return { data, fetchError, isFetching };
}
