import styled from 'styled-components';

export default function NumInputGroup({ children }) {
  return <InputGroup>{children}</InputGroup>;
}

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 5px 0;
  height: 28px;

  button {
    background-color: var(--color-secondary);
    width: 28px;
    height: 100%;
    cursor: pointer;
    border: 1px solid #aaa;
    font-size: 16px;
  }

  input {
    width: 46px;
    height: 100%;
    text-align: center;
    border: none;
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    background-color: white;
    border-radius: 0;
    padding:0;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  button:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  button:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;
