import ProductCard from '../productCard';
import './styles.scss';

export default function ProductList({
  data,
  setShowPopupCard,
  setSelectProduct,
}) {
  return (
    <div className='productList'>
      {data.map((item) => (
        <ProductCard
          key={item.docId}
          product={item}
          setShowPopupCard={setShowPopupCard}
          setSelectProduct={setSelectProduct}
        />
      ))}
    </div>
  );
}
