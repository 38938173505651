import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export default function LoadingSpin() {
  return (
    <Wrapper>
      <CircularProgress size={40} style={{ color: '#ccc' }} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
