import { useAuth } from '../../../contexts/auth.context';

export default function AdminDashboard() {
  const { currentUser } = useAuth();

  return (
    <div id='pageAdminDashboard' className='container-full'>
      <div className='row'>
        <div>
          <h1 className='pageTitle'>Dashboard</h1>
          Welcome: {currentUser.email}
        </div>
      </div>

      <div className='row'>{/* <div className='paper'>hello</div> */}</div>
    </div>
  );
}
