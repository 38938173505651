const text = {
  PAGE_TITLE: {
    langTC: '購物籃',
    langSC: '购物篮',
    langEN: 'CART',
  },
  CONTINUOUS_SHOPPING: {
    langTC: '繼續選購其他產品',
    langSC: '继续选购其他产品',
    langEN: 'CONTINUOUS SHOPPING',
  },
  CHECK_OUT: {
    langTC: '更新及確認訂單',
    langSC: '更新及确认订单',
    langEN: 'UPDATE AND CHECK OUT',
  },
};

export default text;