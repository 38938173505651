import styled from 'styled-components';
import { useLang } from './../../../contexts/lang.context';

const text = {
  SHRINK: {
    langTC: '隱藏詳細內容 &#9650;',
    langSC: '隐藏详细内容 &#9650;',
    langEN: 'Hide details &#9650;',
  },
  EXTEND: {
    langTC: '顯示詳細內容 &#9660;',
    langSC: '显示详细内容 &#9660;',
    langEN: 'Show details &#9660;',
  },
};

export default function ButtonExtend({ onClick, isExtend }) {
  const { lang } = useLang();

  function createMarkup() {
    return isExtend
      ? { __html: text.SHRINK[`lang${lang}`] }
      : { __html: text.EXTEND[`lang${lang}`] };
  }

  return <Button onClick={onClick} dangerouslySetInnerHTML={createMarkup()} />;
}

const Button = styled.div`
  font-size: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #838383;
  margin: 5px 0;
  /* background-color: #ccc; */
`;
