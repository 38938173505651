import { useRef, useState, useEffect } from 'react';
import DescriptionIcon from '@material-ui/icons/Description';
import { Image, Transformation } from 'cloudinary-react';
import CurrencyFormat from 'react-currency-format';

import { useLang } from './../../contexts/lang.context';
import { useCart } from '../../contexts/cart.context';
import Button from '../form/Button';
import useToastify from '../../hooks/useToastify';
import NumInputGroup from '../form/NumInputGroup';
import './styles.scss';
import { useBrands } from './../../contexts/brands.context';
import outputBrandName from './../../utils/outputBrandName';

export default function ProductDetails({ product }) {
  const { lang } = useLang();
  const { brands } = useBrands();
  const { cartDispatch, cartActions } = useCart();
  const [qty, setQty] = useState(1);
  // const qtyRef = useRef();
  const notify = useToastify();
  // const qtyRef = useRef();

  useEffect(() => {
    setQty(1);
  }, [product]);

  const text = {
    MODEL_NO: {
      langTC: '型號',
      langSC: '型号',
      langEN: 'Model No.',
    },
    PRODUCT_CODE: {
      langTC: '產品編號',
      langSC: '产品编号',
      langEN: 'Product Code',
    },
    PRODUCT_DETAILS: {
      langTC: '產品描述',
      langSC: '产品描述',
      langEN: 'Product Details',
    },
    SPECIFICATIONS: {
      langTC: '規格',
      langSC: '规格',
      langEN: 'Specifications',
    },
    REMARKS: {
      langTC: '備註',
      langSC: '备注',
      langEN: 'Remarks',
    },
    DOCUMENTS: {
      langTC: '參考文件',
      langSC: '参考文件',
      langEN: 'Documents',
    },
    SPECIAL_OFFER: {
      langTC: '特價',
      langSC: '特价',
      langEN: 'SPECIAL OFFER',
    },
    ADD_TO_CART: {
      langTC: '加入購物籃',
      langSC: '加入购物篮',
      langEN: 'ADD TO CART',
    },
    OUT_OF_STOCK: {
      langTC: '缺貨',
      langSC: '缺货',
      langEN: 'OUT OF STOCK',
    },
    TOAST_ADD_SUCCESS: {
      langTC: '貨品已加到購物籃',
      langSC: '货品已加到购物篮',
      langEN: 'Added product into cart.',
    },
    TOAST_ADD_QTY_WARN: {
      langTC: '必須輸入數量',
      langSC: '必须输入数量',
      langEN: 'Qty is required.',
    },
  };

  // function outputBrandName(code) {
  //   const indexOfBrands = Array.isArray(brands.values)
  //     ? brands.values.findIndex((brand) => brand.code === code)
  //     : -1;

  //   let brandName;

  //   console.log(indexOfBrands);
  //   if (indexOfBrands <= -1) return '';

  //   brandName = brands.values[indexOfBrands].name?.[`lang${lang}`];
  //   return brandName;
  // }

  function handleMinus() {
    if (!qty || isNaN(qty) || qty <= 1) {
      setQty(1);
      return;
    }

    setQty((prevState) => prevState - 1);

    // if (!qtyRef.current.value || qtyRef.current.value <= 1) {
    //   qtyRef.current.value = 1;
    //   return;
    // }

    // qtyRef.current.value--;
  }

  function handlePlus() {
    if (!qty || isNaN(qty) || qty <= 0) {
      setQty(1);
      return;
    }

    setQty((prevState) => prevState + 1);

    // if (!qtyRef.current.value || qtyRef.current.value <= 0) {
    //   qtyRef.current.value = 1;
    //   return;
    // }

    // qtyRef.current.value++;
  }

  function handleQtyInput(e) {
    var tempQty = e.target.value;

    if (!tempQty || tempQty <= 0) {
      tempQty = 1;
    }

    tempQty = parseInt(tempQty);
    setQty(tempQty);
  }

  function handleAddToCart() {
    const payload = {
      uid: product.docId,
      qty: !qty || isNaN(qty) ? 1 : qty,
      // qty: Number(qtyRef.current.value),
      unit: product.unit,
      unitPrice: product.isPromotion
        ? product.promotionPrice
        : product.unitPrice,
      brand: product.brand,
      briefDesc: product.briefDesc,
      modelNo: product.modelNo,
      productCode: product.productCode,
      imagePublicId: product.imagePublicId,
    };
    cartDispatch({ type: cartActions.ADD_TO_CART, payload: payload });

    notify({
      type: 'success',
      message: `${text.TOAST_ADD_SUCCESS[`lang${lang}`]}`,
    });
  }

  return (
    <div id='productDetails'>
      {product && (
        <div className='card'>
          <div className='card__mainboard'>
            <div className='card__partition img-wrapper'>
              <Image cloudName='ebasell' publicId={product.imagePublicId}>
                <Transformation height='400' width='400' crop='fill' />
              </Image>
              {/* <img src={product.image} alt='' /> */}
            </div>

            <div className='card__partition heading'>
              {outputBrandName({ brands, code: product.brand, lang })}
              <br />
              {product.briefDesc}
            </div>

            <div className='card__partition desc'>
              {text.MODEL_NO[`lang${lang}`]}: {product.modelNo}
              <br />
              {text.PRODUCT_CODE[`lang${lang}`]}: {product.productCode}
            </div>

            <div className='card__partition actions'>
              <div className='card__sideboard__price'>
                <div>
                  {product.isPromotion && (
                    <div className='card__sideboard__price__subject'>
                      {text.SPECIAL_OFFER[`lang${lang}`]}
                    </div>
                  )}
                  <div className='card__sideboard__price__unitprice'>
                    {product.currency}
                    &nbsp;
                    <CurrencyFormat
                      value={product.unitPrice}
                      displayType={'text'}
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                    />
                    /{product.unit}
                  </div>
                </div>

                <div>
                  {product.stock !== 0 && (
                    <NumInputGroup>
                      <button onClick={handleMinus}>-</button>
                      <input
                        type='number'
                        step='1'
                        min='1'
                        max='9999'
                        defaultValue={1}
                        value={qty}
                        onChange={handleQtyInput}
                        // onChange={(e) => setQty(e.target.value)}
                      />
                      <button onClick={handlePlus}>+</button>
                    </NumInputGroup>
                  )}
                </div>
              </div>

              <div className='card__sideboard__buttons'>
                <Button
                  text={
                    product.stock === 0
                      ? text.OUT_OF_STOCK[`lang${lang}`]
                      : text.ADD_TO_CART[`lang${lang}`]
                  }
                  variant='contained'
                  color={product.stock === 0 ? 'grey' : 'secondary'}
                  onClick={handleAddToCart}
                  disabled={product.stock === 0}
                />
              </div>
            </div>

            {product.productDetails?.[`lang${lang}`] && (
              <div className='card__partition desc'>
                <h4>{text.PRODUCT_DETAILS[`lang${lang}`]}</h4>
                {product.productDetails?.[`lang${lang}`]}
              </div>
            )}

            {product.specifications[`lang${lang}`] && (
              <div className='card__partition spec'>
                <h4>{text.SPECIFICATIONS[`lang${lang}`]}</h4>

                <div
                  className='card__specifications'
                  dangerouslySetInnerHTML={{
                    __html: product.specifications[`lang${lang}`],
                  }}
                />
                {/* <table className='card__specTable'>
                <tbody>
                  <tr>
                    <td>Voltage Rating</td>
                    <td>450/750 volts</td>
                  </tr>
                  <tr>
                    <td>Construction</td>
                    <td>
                      Solid or stranded plain copper conductor, single core, PVC
                      insulation
                    </td>
                  </tr>
                  <tr>
                    <td>Operating Temperature</td>
                    <td>-15degC to +70degc</td>
                  </tr>
                  <tr>
                    <td>Minimum Bending Radius</td>
                    <td>3x 1.5sq to 25sq, 4x 35sq to 240sq, 6x 300 to 630sq</td>
                  </tr>
                  <tr>
                    <td>Core Identiﬁcation</td>
                    <td>Bn, Be, Bk, Gy, Gn/Yw, Yw#, RD, Oe, PK, Te, Vt, We</td>
                  </tr>
                  <tr>
                    <td>Lengths</td>
                    <td>
                      100/500m 1.5-4.0, 50/100/500m,6.0-25
                      <br />
                      special lengths available on request
                    </td>
                  </tr>
                  <tr>
                    <td>Maunfacturing Standard</td>
                    <td>BSEN50525-2-31 table B1</td>
                  </tr>
                  <tr>
                    <td>Operating Temperature</td>
                    <td>-15degC to +70degc</td>
                  </tr>
                  <tr>
                    <td>Minimum Bending Radius</td>
                    <td>3x 1.5sq to 25sq, 4x 35sq to 240sq, 6x 300 to 630sq</td>
                  </tr>
                  <tr>
                    <td>Core Identiﬁcation</td>
                    <td>Bn, Be, Bk, Gy, Gn/Yw, Yw#, RD, Oe, PK, Te, Vt, We</td>
                  </tr>
                  <tr>
                    <td>Lengths</td>
                    <td>
                      100/500m 1.5-4.0, 50/100/500m,6.0-25
                      <br />
                      special lengths available on request
                    </td>
                  </tr>
                  <tr>
                    <td>Maunfacturing Standard</td>
                    <td>BSEN50525-2-31 table B1</td>
                  </tr>
                </tbody>
              </table> */}
              </div>
            )}

            {product.remarks?.[`lang${lang}`] && (
              <div className='card__partition'>
                <h4>{text.REMARKS[`lang${lang}`]}</h4>
                <p>{product.remarks?.[`lang${lang}`]}</p>
              </div>
            )}

            {/* <div className='card__partition downloads'>
              <h4>{text.DOCUMENTS[`lang${lang}`]} (Coming soon...)</h4>
              <div>
                <DescriptionIcon fontSize='large' />
                <a href='https://google.com'>
                  Standard Cable Technical Catalogue
                </a>
              </div>
              <div>
                <DescriptionIcon fontSize='large' />
                <a href='https://google.com'>Data Sheets</a>
              </div>
            </div> */}
          </div>

          <div className='card__sideboard'>
            <div className='img-wrapper'>
              <Image cloudName='ebasell' publicId={product.imagePublicId}>
                <Transformation height='400' width='400' crop='fill' />
              </Image>
              {/* <img src={product.image} alt='' /> */}
            </div>

            <div className='card__sideboard__brand'>
              {outputBrandName({ brands, code: product.brand, lang })}
            </div>

            <div className='card__sideboard__content'>
              {product.productDetails?.[`lang${lang}`]}
            </div>

            <div className='card__sideboard__price'>
              <div>
                {product.isPromotion && (
                  <div className='card__sideboard__price__subject'>
                    {text.SPECIAL_OFFER[`lang${lang}`]}
                  </div>
                )}
                <div className='card__sideboard__price__unitprice'>
                  {product.currency}&nbsp;
                  <CurrencyFormat
                    value={
                      product.isPromotion
                        ? product.promotionPrice
                        : product.unitPrice
                    }
                    displayType={'text'}
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                  />
                  /{product.unit}
                </div>
              </div>
              <div>
                {product.stock !== 0 && (
                  <NumInputGroup>
                    <button onClick={handleMinus}>-</button>
                    <input
                      type='number'
                      step='1'
                      min='1'
                      max='9999'
                      value={qty}
                      onChange={handleQtyInput}
                      // ref={qtyRef}
                      // defaultValue={1}
                      // onChange={(e) => setQty(e.target.value)}
                    />
                    <button onClick={handlePlus}>+</button>
                  </NumInputGroup>
                )}
              </div>
            </div>

            <div className='card__sideboard__buttons'>
              <Button
                text={
                  product.stock === 0
                    ? text.OUT_OF_STOCK[`lang${lang}`]
                    : text.ADD_TO_CART[`lang${lang}`]
                }
                variant='contained'
                color={product.stock === 0 ? 'grey' : 'secondary'}
                onClick={handleAddToCart}
                disabled={product.stock === 0}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
