import FooterBar from '../components/FooterBar';
import HeaderBar from '../components/HeaderBar';
import useWindowSize from '../hooks/useWindowSize';
import PagePadding from '../components/PagePadding';

export default function ContentLayout({ children }) {
  const { mediaMode } = useWindowSize();

  return (
    <>
      {(mediaMode === 'mobile' || mediaMode === 'tablet') && <PagePadding height='50px' />}

      <HeaderBar />
      {children}
      <FooterBar />
    </>
  );
}
