import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

export default function Header() {
  const history = useHistory();

  return (
    <Container onClick={() => history.push('/admin')}>
      ebasell Dashboard
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
`;
