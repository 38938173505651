import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../contexts/auth.context';

const PrivateRoute = ({ children, ...rest }) => {
  const { currentUser } = useAuth();

  return (
    <Route {...rest} render={() => (currentUser ? children : <Redirect to='/signin' />)} />
  );
};

export default PrivateRoute;