import { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import useToastify from '../../../../hooks/useToastify';

import { setDbData } from '../../../../utils';
import ImageUploader from '../../../ImageUploader';
import ResultMessageBox from '../../../ResultMessageBox';
import {
  Button,
  TextInput,
  TextArea,
  CheckBox,
  Dropdown,
  SelectBox,
} from '../../../form';
import useGetDbDoc from './../../../../hooks/useGetDbDoc';

// const brands = [
//   { name: 'AEI Cables', code: 'AEI' },
//   { name: 'KME', code: 'KME' },
// ];

// const ranges = [
//   { name: 'AEI Cables', code: 'AEI' },
//   { name: 'KME', code: 'KME' },
// ];

// const units = [
//   { name: 'pc', code: 'pc' },
//   { name: 'roll', code: 'roll' },
// ];

export default function EditProducts({ data, handleClosePopupBox }) {
  const notify = useToastify();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { data: brands, isLoading: isBrandsLoading } = useGetDbDoc({
    collection: 'brands',
    docId: '001',
  });
  const { data: ranges, isLoading: isRangesLoading } = useGetDbDoc({
    collection: 'ranges',
    docId: '001',
  });
  const { data: units, isLoading: isUnitsLoading } = useGetDbDoc({
    collection: 'units',
    docId: '001',
  });

  const [modelNo, setModelNo] = useState(data.modelNo || '');
  const [productCode, setProductCode] = useState(data.productCode || '');
  const [brand, setBrand] = useState(data.brand || '');
  const [range, setRange] = useState(data.range || '');
  const [briefDesc, setBriefDesc] = useState(data.briefDesc || '');
  const [productDetailsEN, setProductDetailsEN] = useState(
    data.productDetails?.langEN || '',
  );
  const [productDetailsTC, setProductDetailsTC] = useState(
    data.productDetails?.langTC || '',
  );
  const [productDetailsSC, setProductDetailsSC] = useState(
    data.productDetails?.langSC || '',
  );
  const [specificationsEN, setSpecificationsEN] = useState(
    data.specifications?.langEN || '',
  );
  const [specificationsTC, setSpecificationsTC] = useState(
    data.specifications?.langTC || '',
  );
  const [specificationsSC, setSpecificationsSC] = useState(
    data.specifications?.langSC || '',
  );
  const [remarksEN, setRemarksEN] = useState(data.remarks?.langEN || '');
  const [remarksTC, setRemarksTC] = useState(data.remarks?.langTC || '');
  const [remarksSC, setRemarksSC] = useState(data.remarks?.langSC || '');
  const [documents, setDocuments] = useState(data.documents || []);

  const [unit, setUnit] = useState(data.unit || '');
  const [currency, setCurrency] = useState(data.currency || '');
  const [unitPrice, setUnitPrice] = useState(data.unitPrice || 0);
  const [promotionPrice, setPromotionPrice] = useState(
    data.promotionPrice || 0,
  );
  const [stock, setStock] = useState(
    (data.stock === -1 ? '' : data.stock) || '',
  );
  const [isNew, setIsNew] = useState(data.isNew || false);
  const [isPromotion, setIsPromotion] = useState(data.isPromotion || false);
  const [isDisable, setIsDisable] = useState(data.isDisable || false);
  const [newImagePublicId, setNewImagePublicID] = useState('');
  const [newImageUrl, setNewImageUrl] = useState('');
  const { imagePublicId, imageUrl } = data;

  const [brandsDropdownList, setBrandsDropdownList] = useState([]);
  const [rangesDropdownList, setRangesDropdownList] = useState([]);
  const [unitsDropdownList, setUnitsDropdownList] = useState([]);

  // Brands dropdown list
  useEffect(() => {
    if (isBrandsLoading || !brands) return;

    let tempList = [];
    brands.values.forEach((brand) => {
      tempList.push({ value: brand.code, label: brand.name.langEN });
    });
    tempList.sort((a, b) =>
      a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
    );

    setBrandsDropdownList(tempList);
  }, [brands, isBrandsLoading]);

  // Ranges dropdown list
  useEffect(() => {
    if (isRangesLoading || !ranges) return;

    let tempList = [];
    ranges.ranges.forEach((range) => {
      tempList.push({ value: range.uid, label: range.name.langEN });
    });

    setRangesDropdownList(tempList);
  }, [ranges, isRangesLoading]);

  // Units dropdown list
  useEffect(() => {
    if (isUnitsLoading || !units) return;

    let tempList = [];
    units.values.forEach((unit) => {
      tempList.push({ value: unit.code, label: unit.name.langEN });
    });
    tempList.sort((a, b) =>
      a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
    );
    setUnitsDropdownList(tempList);
  }, [units, isUnitsLoading]);

  // Current data images
  let currentImages = [];
  if (data.imageUrl) currentImages.push(data.imageUrl);

  function handleUpdateImageId({ publicId, imageUrl }) {
    setNewImagePublicID(publicId);
    setNewImageUrl(imageUrl);
  }

  function handleCancel() {
    if (!newImagePublicId) {
      handleClosePopupBox();
      return;
    }

    const confirmBox = window.confirm(
      `You have uploaded a image to server.\nDo you still want to close it?`,
    );
    if (confirmBox) {
      handleClosePopupBox();
    }
  }

  function handleFormStep1Submit(e) {
    e.preventDefault();

    // Reset states
    setError('');
    setIsLoading(true);

    const newData = {
      briefDesc,
      brand,
      modelNo,
      productCode,
      range,
      unit,
      currency: 'HKD',
      unitPrice: Number(unitPrice || 0),
      promotionPrice: Number(promotionPrice || 0),
      stock: stock === '' || stock < 0 ? -1 : Number(stock),
      isPromotion,
      isNew,
      isDisable,
      productDetails: {
        langEN: productDetailsEN,
        langTC: productDetailsTC,
        langSC: productDetailsSC,
      },
      specifications: {
        langEN: specificationsEN,
        langTC: specificationsTC,
        langSC: specificationsSC,
      },
      remarks: {
        langEN: remarksEN,
        langTC: remarksTC,
        langSC: remarksSC,
      },
      imagePublicId: newImagePublicId || imagePublicId || '',
      imageUrl: newImageUrl || imageUrl || '',
      // uid: data.docId,
    };

    const options = {
      merge: true,
    };

    // Save data
    try {
      setDbData({
        collection: 'products',
        docId: data?.docId,
        data: newData,
        options,
      });
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }

    if (!error) {
      notify({
        type: 'success',
        message: 'Record successfully saved!',
      });

      handleClosePopupBox();
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <h1>{`${data?.docId ? 'Edit' : 'New'} Product`}</h1>

      {data?.docId && (
        <div style={{ float: 'right' }}>
          <p style={{ fontSize: '13px', color: '#aaa' }}>ID: {data.docId}</p>
        </div>
      )}
      <p className='remarks'>* must be inputed</p>

      {error && <ResultMessageBox type='error' messages={error} />}

      <form onSubmit={handleFormStep1Submit}>
        <div className='paper'>
          <h4>General Info.</h4>

          <div className='formGroup'>
            <label>
              Brief Description *
              <TextInput
                type='text'
                value={briefDesc}
                onChange={(e) => setBriefDesc(e.target.value)}
                required
              />
            </label>
          </div>

          <div className='formGroup'>
            <label>
              Brand *
              <SelectBox
                options={brandsDropdownList}
                prompt='Select a brand...'
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
              />
              {/* <Dropdown
                options={brandsDropdownList}
                id='name'
                label='name'
                prompt={'Select brand...'}
                value={{ brand }}
                onChange={(val) => setBrand(val)}
              /> */}
            </label>
          </div>

          <div className='formGroup'>
            <label>
              Model No. *
              <TextInput
                type='text'
                value={modelNo}
                onChange={(e) => setModelNo(e.target.value)}
                required
              />
            </label>
          </div>

          <div className='formGroup'>
            <label>
              Product Code *
              <TextInput
                type='text'
                value={productCode}
                onChange={(e) => setProductCode(e.target.value)}
                required
              />
            </label>
          </div>

          <div className='formGroup'>
            <label>
              Range *
              <SelectBox
                options={rangesDropdownList}
                prompt='Select a range...'
                value={range}
                onChange={(e) => setRange(e.target.value)}
              />
              {/* <Dropdown
                options={rangesDropdownList}
                id='code'
                label='name'
                prompt={'Select range...'}
                value={{ code: range, name: 'test' }}
                onChange={(val) => setRange(val)}
              /> */}
            </label>
          </div>

          <div className='formGroup'>
            <label>
              Unit *
              <SelectBox
                options={unitsDropdownList}
                prompt='Select a unit...'
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
              />
              {/* <Dropdown
                options={units}
                id='name'
                label='name'
                prompt={'Select unit...'}
                value={{ name: unit }}
                onChange={(val) => setUnit(val)}
              /> */}
            </label>
          </div>

          <div className='formGroup'>
            <label>
              Basic Unit Price *
              <TextInput
                type='number'
                min='1'
                step='any'
                value={unitPrice}
                onChange={(e) => setUnitPrice(e.target.value)}
                required
              />
            </label>
          </div>

          <div className='formGroup'>
            <CheckBox
              label='Promotion (Enable to show promotion price)'
              checked={isPromotion}
              onChange={() => setIsPromotion(!isPromotion)}
            />
          </div>

          <div className='formGroup'>
            <label>
              Promotion Price
              <TextInput
                type='number'
                min='1'
                step='any'
                value={promotionPrice}
                onChange={(e) => setPromotionPrice(e.target.value)}
                disabled={!isPromotion}
              />
            </label>
          </div>

          <div className='formGroup'>
            <label>
              Stock Qty. (Leave blank for unlimited)
              <TextInput
                type='number'
                min='0'
                step='1'
                value={stock}
                onChange={(e) => setStock(e.target.value)}
              />
            </label>
          </div>

          <div className='formGroup'>
            <CheckBox
              label='New Item'
              checked={isNew}
              onChange={() => setIsNew(!isNew)}
            />
          </div>

          <div className='formGroup'>
            <CheckBox
              label='Disabled (Hidden this product)'
              checked={isDisable}
              onChange={() => setIsDisable(!isDisable)}
            />
          </div>
        </div>

        <div className='paper'>
          <h4>Content - English</h4>

          <div className='formGroup'>
            <label>
              Product Details
              <TextArea onChange={(e) => setProductDetailsEN(e.target.value)}>
                {productDetailsEN}
              </TextArea>
            </label>
          </div>

          <div className='formGroup'>
            <div>
              Specifications
              <CKEditor
                editor={ClassicEditor}
                data={specificationsEN}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setSpecificationsEN(data);
                }}
              />
            </div>
          </div>

          <div className='formGroup'>
            <label>
              Remarks
              <TextArea onChange={(e) => setRemarksEN(e.target.value)}>
                {remarksEN}
              </TextArea>
            </label>
          </div>
        </div>

        <div className='paper'>
          <h4>Content - 繁體中文</h4>

          <div className='formGroup'>
            <label>
              Product Details
              <TextArea onChange={(e) => setProductDetailsTC(e.target.value)}>
                {productDetailsTC}
              </TextArea>
            </label>
          </div>

          <div className='formGroup'>
            <div>
              Specifications
              <CKEditor
                editor={ClassicEditor}
                data={specificationsTC}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setSpecificationsTC(data);
                }}
              />
            </div>
          </div>

          <div className='formGroup'>
            <label>
              Remarks
              <TextArea onChange={(e) => setRemarksTC(e.target.value)}>
                {remarksTC}
              </TextArea>
            </label>
          </div>
        </div>

        <div className='paper'>
          <h4>Content - 简体中文</h4>

          <div className='formGroup'>
            <label>
              Product Details
              <TextArea onChange={(e) => setProductDetailsSC(e.target.value)}>
                {productDetailsSC}
              </TextArea>
            </label>
          </div>

          <div className='formGroup'>
            <div>
              Specifications
              <CKEditor
                editor={ClassicEditor}
                data={specificationsSC}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setSpecificationsSC(data);
                }}
              />
            </div>
          </div>

          <div className='formGroup'>
            <label>
              Remarks
              <TextArea onChange={(e) => setRemarksSC(e.target.value)}>
                {remarksSC}
              </TextArea>
            </label>
          </div>
        </div>

        <div className='paper'>
          <h4>Upload Documents</h4>

          <p>Coming soon...</p>

          <div className='formGroup'>
            {/* <label>
              Product Details
              <TextInput
                type='text'
                value={productDetailsEN}
                onChange={(e) => setProductDetailsEN(e.target.value)}
              />
            </label> */}
          </div>
        </div>

        <div className='paper'>
          <h4>Upload Image</h4>

          <ImageUploader
            currentImages={currentImages}
            handleUpdateImageId={handleUpdateImageId}
            uploadPreset={
              process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET_PRODUCTS
            }
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
          }}
        >
          <Button
            type='submit'
            text='Save'
            size='sm'
            color='secondary'
            fontWeight='bold'
            isLoading={isLoading}
          />

          <Button
            type='button'
            text='Close'
            size='sm'
            color='grey'
            fontWeight='bold'
            onClick={handleCancel}
          />
        </div>
      </form>
    </div>
  );
}
