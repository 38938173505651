import { useState, useEffect, useRef } from 'react';
import useGetDbDoc from './../../../hooks/useGetDbDoc';
import { useAuth } from '../../../contexts/auth.context';
import { useLang } from './../../../contexts/lang.context';
import TextInput from '../../../components/form/TextInput';
import Button from '../../../components/form/Button';
import ResultMessageBox from '../../../components/ResultMessageBox';
// import CountrySelector from '../../../components/form/CountrySelector';
import setDbData from './../../../utils/setDbData';
import text from './text';
import countries from '../../../data/countries.json';
import Dropdown from '../../../components/form/Dropdown';

export default function ContactInfo() {
  const msgBox = useRef();
  const { lang } = useLang();
  const { currentUser } = useAuth();
  const { data } = useGetDbDoc({ collection: 'users', docId: currentUser.uid });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [locationTelCode, setLocationTelCode] = useState('');
  const [tel, setTel] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyTel, setCompanyTel] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressLine3, setAddressLine3] = useState('');
  const [region, setRegion] = useState('');
  const [country, setCountry] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [result, setResult] = useState('');

  useEffect(() => {
    setFirstName(data?.firstName);
    setLastName(data?.lastName);
    setEmail(data?.email);
    setLocationTelCode(data?.locationTelCode);
    setTel(data?.tel);

    setCompanyName(data?.companyName);
    setCompanyTel(data?.companyTel);

    setAddressLine1(data?.addressLine1);
    setAddressLine2(data?.addressLine2);
    setAddressLine3(data?.addressLine3);
    setRegion(data?.region);
    setCountry(data?.country);
  }, [data]);

  async function handleFormSubmit(e) {
    e.preventDefault();

    // Reset states
    setError('');
    setIsLoading(true);

    const newData = {
      firstName,
      lastName,
      email,
      locationTelCode,
      tel,
      companyName,
      companyTel,
      addressLine1,
      addressLine2,
      addressLine3,
      region,
      country,
    };

    const options = {
      merge: true,
    };

    try {
      setDbData({
        collection: 'users',
        docId: currentUser.uid,
        data: newData,
        options,
      });

      setResult(text.UPDATE_SUCCESS[`lang${lang}`]);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
      return msgBox.current.scrollIntoView();
    }
  }

  return (
    <div id='pageMyAccountContactInfo' className='container'>
      <div className='row'>
        <h1 className='pageTitle'>{text.PAGE_TITLE[`lang${lang}`]}</h1>

        <div ref={msgBox}>
          {error && <ResultMessageBox type='error' messages={error} />}
          {result && <ResultMessageBox type='success' messages={result} />}
        </div>

        <p>{text.MUST_BE_COMPLETED[`lang${lang}`]}</p>

        <form onSubmit={handleFormSubmit}>
          <h3>{text.CONTACT_INFORMATION[`lang${lang}`]}</h3>

          <div className='formGroup'>
            <label>
              {text.FIRST_NAME[`lang${lang}`]} *
              <TextInput
                type='text'
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName || ''}
                required
                autoFoucs
              />
            </label>

            <label>
              {text.LAST_NAME[`lang${lang}`]} *
              <TextInput
                type='text'
                onChange={(e) => setLastName(e.target.value)}
                value={lastName || ''}
                required
              />
            </label>
          </div>

          <div className='formGroup'>
            <label>
              {text.EMAIL[`lang${lang}`]} *
              <TextInput
                type='email'
                onChange={(e) => setEmail(e.target.value)}
                value={email || ''}
                required
              />
            </label>
          </div>

          <div className='formGroup'>
            {/* <label style={{ width: '120px' }}>
              {text.LOCATION_CODE[`lang${lang}`]}
              <TextInput
                type='text'
                onChange={(e) => setLocationTelCode(e.target.value)}
                value={locationTelCode || ''}
              />
            </label> */}

            <label>
              {text.TEL[`lang${lang}`]}
              <TextInput
                type='tel'
                onChange={(e) => setTel(e.target.value)}
                value={tel || ''}
              />
            </label>
          </div>

          <br />
          <h3>{text.COMPANY_INFORMATION[`lang${lang}`]}</h3>

          <div className='formGroup'>
            <label>
              {text.COMPANY_NAME[`lang${lang}`]}
              <TextInput
                type='text'
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName || ''}
              />
            </label>
          </div>

          <div className='formGroup'>
            <label>
              {text.COMPANY_TELEPHONE[`lang${lang}`]}
              <TextInput
                type='tel'
                onChange={(e) => setCompanyTel(e.target.value)}
                value={companyTel || ''}
              />
            </label>
          </div>

          <br />
          <h3>{text.ADDRESS[`lang${lang}`]}</h3>

          <div className='formGroup'>
            <label>
              {text.COUNTRY[`lang${lang}`]}
              <Dropdown
                options={countries}
                id='code'
                label='name'
                prompt={text.SELECT_COUNTRY[`lang${lang}`]}
                value={country}
                onChange={(val) => setCountry(val)}
              />
            </label>
          </div>

          <div className='formGroup'>
            <label>
              {text.ADDRESS_LINE_1[`lang${lang}`]}
              <TextInput
                type='text'
                onChange={(e) => setAddressLine1(e.target.value)}
                value={addressLine1 || ''}
              />
            </label>
          </div>

          <div className='formGroup'>
            <label>
              {text.ADDRESS_LINE_2[`lang${lang}`]}
              <TextInput
                type='text'
                onChange={(e) => setAddressLine2(e.target.value)}
                value={addressLine2 || ''}
              />
            </label>
          </div>

          <div className='formGroup'>
            <label>
              {text.ADDRESS_LINE_3[`lang${lang}`]}
              <TextInput
                type='text'
                onChange={(e) => setAddressLine3(e.target.value)}
                value={addressLine3 || ''}
              />
            </label>
          </div>

          {/* <div className='formGroup'>
            <label>
              {text.REGION[`lang${lang}`]}
              <TextInput
                type='text'
                onChange={(e) => setRegion(e.target.value)}
                value={region || ''}
              />
            </label>
          </div> */}

          <div className='formGroup'>
            <Button
              type='submit'
              text={text.SAVE[`lang${lang}`]}
              color='secondary'
              size='sm'
              fontWeight='bold'
              isLoading={isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
