import { useState } from 'react';
import useToastify from '../../../../hooks/useToastify';
import { setDbData } from '../../../../utils';
import Button from '../../../form/Button';
import TextInput from '../../../form/TextInput';
import ImageUploader from '../../../ImageUploader';
import ResultMessageBox from '../../../ResultMessageBox';

const uploaderNotes = ['Recommended width: 1200px, height: 120px '];

export default function EditRanges({ data, dataIndex, handleClosePopupBox }) {
  const notify = useToastify();
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState(data?.bannerLg[dataIndex]?.url);
  const [error, setError] = useState('');
  const [imagePublicId, setImagePublicId] = useState('');
  const [imageUrl, setImageUrl] = useState(
    data?.bannerLg[dataIndex]?.imageUrl || '',
  );

  let currentImages = [];
  if (dataIndex > -1) currentImages.push(data?.bannerLg[dataIndex].imageUrl);

  function handleUpdateImageId({ publicId, imageUrl }) {
    setImagePublicId(publicId);
    setImageUrl(imageUrl);
  }

  function handleCancel() {
    if (!imagePublicId) handleClosePopupBox();

    if (imagePublicId && data?.image?.imagePublicId !== imagePublicId) {
      const confirmBox = window.confirm(
        `You have uploaded a image to server.\nDo you still want to close it?`,
      );
      if (confirmBox) {
        handleClosePopupBox();
      }
    }
  }

  function handleFormStep1Submit(e) {
    e.preventDefault();

    // Reset states
    setError('');
    setIsLoading(true);

    if (dataIndex > -1) {
      // Edit item
      data.bannerLg[dataIndex] = { url: url, imageUrl: imageUrl };
    } else {
      // Add new item
      data.bannerLg.unshift({ url: url, imageUrl: imageUrl });
    }
    const newData = data;

    const options = {
      merge: true,
    };

    // Save data
    try {
      setDbData({
        collection: 'advBanners',
        docId: '001',
        data: newData,
        options,
      });
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }

    if (!error) {
      notify({
        type: 'success',
        message: 'Record successfully saved!',
      });

      handleClosePopupBox();
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <h1>{`${data?.docId ? 'Edit' : 'New'} Adv. Banner`}</h1>

      <p className='remarks'>* must be inputed</p>

      {error && <ResultMessageBox type='error' messages={error} />}

      <form onSubmit={handleFormStep1Submit}>
        <div className='paper'>
          <label>
            <h4>URL</h4>
            <div className='formGroup'>
              <TextInput
                type='text'
                value={url || ''}
                onChange={(e) => setUrl(e.target.value)}
                required
              />
            </div>
          </label>
        </div>

        <div className='paper'>
          <h4>Upload Image *</h4>

          <ImageUploader
            currentImages={currentImages}
            handleUpdateImageId={handleUpdateImageId}
            uploadPreset={
              process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET_ADVBANNERS
            }
            uploaderNotes={uploaderNotes}
          />
        </div>

        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          <Button
            type='submit'
            text='Save'
            size='sm'
            color='secondary'
            fontWeight='bold'
            isLoading={isLoading}
          />

          <Button
            type='button'
            text='Close'
            size='sm'
            color='grey'
            fontWeight='bold'
            onClick={handleCancel}
          />
        </div>
      </form>
    </div>
  );
}
