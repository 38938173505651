// import styled from 'styled-components';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  Scrollbar,
  A11y,
} from 'swiper';
// swiper bundle styles
import 'swiper/swiper-bundle.min.css';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';

import useWindowSize from './../../hooks/useWindowSize';
import useGetDbDoc from './../../hooks/useGetDbDoc';
import './styles.scss';

// Import Swiper styles
// import 'swiper/css';
import 'swiper/swiper.min.css';
// import imgPrev from '@/assets/images/demo/prev.png';
// import imgNext from '@/assets/images/demo/next.png';
SwiperCore.use([Navigation, Pagination, Autoplay, Scrollbar, A11y]);

// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/modules/navigation/navigation.scss';
// import 'swiper/modules/pagination/pagination.scss';

// const Wrapper = styled.div`
//   width: 100%;
//   height: 270px;
//   position: relative;
//   cursor: pointer;

//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }

//   .btnArrow {
//     position: absolute;
//     background-color: rgba(0, 0, 0, 0.15);
//     color: rgba(255, 255, 255, 0.8);
//     font-size: 30px;
//     padding: 20px 5px;
//   }

//   .leftArrow {
//     left: 0;
//     top: 50%;
//     transform: translateY(-50%);
//   }

//   .rightArrow {
//     right: 0;
//     top: 50%;
//     transform: translateY(-50%);
//   }
// `;

// const bannerLg = [
//   {
//     imageUrl:
//       'https://res.cloudinary.com/ebasell/image/upload/v1638390625/slidebanners/banner-aei-cables-lg_hjgksh.jpg',
//   },
//   {
//     imageUrl:
//       'https://res.cloudinary.com/ebasell/image/upload/v1638390624/slidebanners/banner-kme-lg_eqd2xs.jpg',
//   },
//   {
//     imageUrl:
//       'https://res.cloudinary.com/ebasell/image/upload/v1638390624/slidebanners/banner-evcco-lg_j9nwff.jpg',
//   },
//   {
//     imageUrl:
//       'https://res.cloudinary.com/ebasell/image/upload/v1638390624/slidebanners/banner-unex-lg_eyr6kj.jpg',
//   },
//   {
//     imageUrl:
//       'https://res.cloudinary.com/ebasell/image/upload/v1638390624/slidebanners/banner-cavicel-lg_x2voha.jpg',
//   },
//   {
//     imageUrl:
//       'https://res.cloudinary.com/ebasell/image/upload/v1638390624/slidebanners/banner-vichnet-lg_nppnu4.jpg',
//   },
// ];

export default function SlideBanner() {
  const { mediaMode } = useWindowSize();
  const { data, fetchError, isFetching } = useGetDbDoc({
    collection: 'slideBanners',
    docId: '001',
  });

  const slideConfig = {
    tag: 'section',
    // wrapperTag: 'ul',
    spaceBetween: 50, // 两个slide的间距
    slidesPerView: 1,
    loop: true,
    speed: 800,
    effect: 'coverflow',
    autoplay: { delay: 5000 },
    pagination: { clickable: true },
    navigation: { prevEl: '.prev', nextEl: '.next' },
    // navigation: true,
    // scrollbar: { draggable: true },
    // onSwiper: (swiper) => console.log(swiper),
    // onSlideChange: () => console.log('slide change'),
  };

  // <Wrapper>
  //   <img src={banner01} alt='' />

  //   <span className='btnArrow leftArrow'>&lt;</span>
  //   <span className='btnArrow rightArrow'>&gt;</span>
  // </Wrapper>

  return (
    <>
      {/* <Swiper
        className='slideBanner'
        spaceBetween={20}
        slidesPerView={3}
        navigation
        pagination={{ clickable: true }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>Slide 1</SwiperSlide>
        <SwiperSlide>Slide 2</SwiperSlide>
        <SwiperSlide>Slide 3</SwiperSlide>
        <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>
      </Swiper> */}

      {data && (
        // <Swiper {...slideConfig} className='slideBanner'>
        //   {data.bannerLg.map((banner, index) => (
        //     <SwiperSlide key={index}>
        //       <img src={banner.imageUrl} alt='' />
        //     </SwiperSlide>
        //   ))}
        // </Swiper>

        <div className='slideContainer'>
          <div className='prev'>
            <ChevronLeftIcon style={{ fontSize: 40 }} />
          </div>
          <div className='next'>
            <ChevronRightIcon style={{ fontSize: 40 }} />
          </div>

          <Swiper
            // install Swiper modules
            // modules={[Navigation, Pagination, A11y]}
            {...slideConfig}
            className='slideBanner'
          >
            {data.values.map((banner, index) => (
              <SwiperSlide key={index}>
                <img
                  src={
                    mediaMode === 'tablet' || mediaMode === 'mobile'
                      ? banner.imageMdUrl || banner.imageLgUrl
                      : banner.imageLgUrl
                  }
                  alt=''
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
}
