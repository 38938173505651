import { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import useToastify from '../../../../hooks/useToastify';

import { setDbData } from '../../../../utils';
import Button from '../../../form/Button';
import TextArea from '../../../form/TextArea';
import TextInput from '../../../form/TextInput';
import ImageUploader from '../../../ImageUploader';
import ResultMessageBox from '../../../ResultMessageBox';

export default function EditNews({ data, handleClosePopupBox }) {
  const [isLoading, setIsLoading] = useState(false);
  const [subjectEN, setSubjectEN] = useState(data.subject?.langEN || '');
  const [subjectTC, setSubjectTC] = useState(data.subject?.langTC || '');
  const [subjectSC, setSubjectSC] = useState(data.subject?.langSC || '');
  const [contentEN, setContentEN] = useState(data.content?.langEN || '');
  const [contentTC, setContentTC] = useState(data.content?.langTC || '');
  const [contentSC, setContentSC] = useState(data.content?.langSC || '');
  const [newsDate, setNewsDate] = useState(data.newsDate || '');
  const [error, setError] = useState('');
  const notify = useToastify();
  const [newImagePublicId, setNewImagePublicId] = useState('');
  const [newImageUrl, setNewImageUrl] = useState('');
  const { imagePublicId, imageUrl } = data;

  // Current data images
  let currentImages = [];
  if (data.imageUrl) currentImages.push(data.imageUrl);

  function handleUpdateImageId({ publicId, imageUrl }) {
    setNewImagePublicId(publicId);
    setNewImageUrl(imageUrl);
  }

  function handleCancel() {
    if (!newImagePublicId) {
      handleClosePopupBox();
      return;
    }

    const confirmBox = window.confirm(
      `You have uploaded a image to server.\nDo you still want to close it?`,
    );
    if (confirmBox) {
      handleClosePopupBox();
    }
  }

  function handleFormStep1Submit(e) {
    e.preventDefault();

    // Reset states
    setError('');
    setIsLoading(true);

    const newData = {
      subject: {
        langEN: subjectEN,
        langTC: subjectTC,
        langSC: subjectSC,
      },
      content: {
        langEN: contentEN,
        langTC: contentTC,
        langSC: contentSC,
      },
      newsDate: newsDate,
      imagePublidId: newImagePublicId || imageUrl || '',
      imageUrl: newImageUrl || imageUrl || '',
      isDisable: false,
    };

    const options = {
      merge: true,
    };

    // Save data
    try {
      setDbData({
        collection: 'news',
        docId: data?.docId,
        data: newData,
        options,
      });
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }

    if (!error) {
      notify({
        type: 'success',
        message: 'Record successfully saved!',
      });

      handleClosePopupBox();
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <h1>{`${data?.docId ? 'Edit' : 'New'} News`}</h1>

      <p className='remarks'>* must be inputed</p>

      {error && <ResultMessageBox type='error' messages={error} />}

      <form onSubmit={handleFormStep1Submit}>
        <div className='paper'>
          <h4>News Date *</h4>
          <input
            type='date'
            value={newsDate}
            onChange={(e) => setNewsDate(e.target.value)}
          />
        </div>

        <div className='paper'>
          <h4>Content - English</h4>
          <div className='formGroup'>
            <label>
              News Subject *
              <TextInput
                type='text'
                value={subjectEN}
                onChange={(e) => setSubjectEN(e.target.value)}
                required
              />
            </label>
          </div>

          <div className='formGroup'>
            <div>
              <label>Content</label>
              <CKEditor
                editor={ClassicEditor}
                data={contentEN}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContentEN(data);
                }}
              />
            </div>
          </div>
        </div>

        <div className='paper'>
          <h4>Content - 繁體中文</h4>
          <div className='formGroup'>
            <label>
              News Subject
              <TextInput
                type='text'
                value={subjectTC}
                onChange={(e) => setSubjectTC(e.target.value)}
              />
            </label>
          </div>

          <div className='formGroup'>
            <div>
              <label>Content</label>
              <CKEditor
                editor={ClassicEditor}
                data={contentTC}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContentTC(data);
                }}
              />
            </div>
          </div>
        </div>

        <div className='paper'>
          <h4>Content - 简体中文</h4>
          <div className='formGroup'>
            <label>
              News Subject
              <TextInput
                type='text'
                value={subjectSC}
                onChange={(e) => setSubjectSC(e.target.value)}
              />
            </label>
          </div>

          <div className='formGroup'>
            <div>
              <CKEditor
                editor={ClassicEditor}
                data={contentSC}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContentSC(data);
                }}
              />
            </div>
          </div>
        </div>

        <div className='paper'>
          <h4>Upload Image</h4>

          <ImageUploader
            currentImages={currentImages}
            handleUpdateImageId={handleUpdateImageId}
            uploadPreset={process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET_NEWS}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
          }}
        >
          <Button
            type='submit'
            text='Save'
            size='sm'
            color='secondary'
            fontWeight='bold'
            isLoading={isLoading}
          />

          <Button
            type='button'
            text='Close'
            size='sm'
            color='grey'
            fontWeight='bold'
            onClick={handleCancel}
          />
        </div>
      </form>
    </div>
  );
}
