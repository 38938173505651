const text = {
  PAGE_TITLE: {
    langTC: '重設密碼',
    langSC: '重设密码',
    langEN: 'RESET PASSWORD',
  },
  MUST_BE_COMPLETED: {
    langTC: '* 必須填寫',
    langSC: '* 必须填写',
    langEN: '* must be completed',
  },
  YOUR_LOGIN_EMAIL: {
    langTC: '您的登入電郵地址',
    langSC: '您的登入电邮地址',
    langEN: 'YOUR LOGIN EMAIL',
  },
  EMAIL: {
    langTC: '電郵地址',
    langSC: '电邮地址',
    langEN: 'Email',
  },
  SUBMIT: {
    langTC: '送出',
    langSC: '送出',
    langEN: 'SUBMIT',
  },
};

export default text;