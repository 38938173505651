import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import CurrencyFormat from 'react-currency-format';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DisableIcon from '@material-ui/icons/Block';

import { useBrands } from './../../../contexts/brands.context';
import { deleteDbData } from './../../../utils';
import { TextInput } from '../../../components/form';
import useListenDbList from './../../../hooks/useListenDbList';
import Button from '../../../components/form/Button';
import PopupBox from '../../../components/PopupBox';
import Editor from '../../../components/admin/editors/EditProducts';
import LoadingSpin from '../../../components/LoadingSpin';
import outputBrandName from '../../../utils/outputBrandName';

// const customColumnSort = (rowA, rowB) => {
//   if (rowA.seq > rowB.seq) {
//     return 1;
//   } else if (rowB.seq > rowA.seq) {
//     return -1;
//   }
//   return 0;
// };

const columns = [
  {
    name: 'Edit',
    selector: (row) => row.btnEdit,
    maxWidth: '40px',
  },
  {
    name: 'Image',
    selector: (row) => (
      <img src={`${row.imageUrl}`} style={{ height: '50px' }} />
    ),
    sortable: false,
    maxWidth: '60px',
  },
  {
    name: 'Model No.',
    selector: (row) => row.modelNo,
    sortable: true,
  },
  {
    name: 'Product Code',
    selector: (row) => row.productCode,
    sortable: true,
  },
  {
    name: 'Brand',
    selector: (row) => row.brandName,
    sortable: true,
  },
  {
    name: '',
    selector: (row) => row.currency,
    sortable: true,
    maxWidth: '40px',
  },
  {
    name: 'Price',
    selector: (row) => (
      <CurrencyFormat
        value={row.unitPrice}
        displayType={'text'}
        thousandSeparator={true}
        fixedDecimalScale
        decimalScale={2}
        prefix='$'
      />
    ),
    sortable: true,
  },
  {
    name: 'Promote',
    selector: (row) => (row.isPromotion ? 'Yes' : 'No'),
    sortable: true,
    maxWidth: '40px',
  },
  {
    name: 'Promotion Price',
    selector: (row) => (
      <CurrencyFormat
        value={row.promotionPrice}
        displayType={'text'}
        thousandSeparator={true}
        fixedDecimalScale
        decimalScale={2}
        prefix='$'
      />
    ),
    sortable: true,
  },
  {
    name: 'New Item',
    selector: (row) => (row.isNew ? 'Yes' : 'No'),
    sortable: true,
    maxWidth: '30px',
  },
  {
    name: 'Stock Qty.*',
    // selector: (row) => row.stock,
    selector: (row) => (row.stock < 0 ? null : row.stock),
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => (row.isDisable ? row.statusDisable : ''),
    sortable: true,
  },
  {
    name: 'Delete',
    selector: (row) => row.btnDelete,
    maxWidth: '40px',
  },
];

export default function Products() {
  const { brands } = useBrands();
  const [isShowPopup, setIsShowPopup] = useState(false);
  // const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [editorData, setEditorData] = useState(null);
  // const searchRef = useRef();
  const [searchModelNo, setSearchModelNo] = useState('');
  const { data, fetchError, isFetching } = useListenDbList({
    collection: 'products',
  });

  useEffect(() => {
    if (isFetching) return;

    let tempData = [];
    setIsLoading(true);

    data.forEach((doc) => {
      tempData.push({
        ...doc,
        brandName: outputBrandName({ brands, code: doc.brand }),
        btnEdit: createEditButton(doc),
        btnDelete: createDeleteButton(doc.docId),
        statusDisable: createStatusDisable(doc),
      });
    });

    setAllTableData(tempData);
    setFilteredTableData(tempData);
    setIsLoading(false);
  }, [data, isFetching]);

  function createEditButton(data) {
    return (
      <EditIcon
        fontSize='large'
        className='clickable'
        // color=''
        style={{ color: '#33a35e' }}
        onClick={() => handleEditItem(data)}
      />
    );
  }

  function createDeleteButton(docId) {
    return (
      <DeleteIcon
        fontSize='large'
        className='clickable'
        color='error'
        onClick={() => handleDeleteItem(docId)}
      />
    );
  }

  function createStatusDisable(data) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
        <DisableIcon
          fontSize='large'
          // className='clickable'
          color='error'
        />
        Disabled
      </div>
    );
  }

  // function handleFilterData() {
  //   // let searchKey = searchRef.current.value.toLowerCase();
  //   let filterdData = allTableData.filter((doc) => {
  //     return (
  //       doc.modelNo.toLowerCase().includes(searchModelNo) ||
  //       doc.productCode.toLowerCase().includes(searchModelNo)
  //     );
  //   });

  //   setFilteredTableData(filterdData);
  // }

  useEffect(() => {
    let filterdData = allTableData.filter((doc) => {
      return (
        doc.modelNo.toLowerCase().includes(searchModelNo?.toLowerCase()) ||
        doc.productCode.toLowerCase().includes(searchModelNo?.toLowerCase())
      );
    });

    setFilteredTableData(filterdData);
  }, [searchModelNo, allTableData]);

  // function handleFilterData(e) {
  //   // let searchKey = searchRef.current.value.toLowerCase();

  //   let searchKey = e.target.value;
  //   setSearchModelNo(searchKey);

  //   let filterdData = allTableData.filter((doc) => {
  //     return (
  //       doc.modelNo.toLowerCase().includes(searchKey) ||
  //       doc.productCode.toLowerCase().includes(searchKey)
  //     );
  //   });

  //   setFilteredTableData(filterdData);
  // }

  function handleAddNewItem() {
    setEditorData({});
    setIsShowPopup(true);
  }

  function handleEditItem(data) {
    setEditorData(data);
    setIsShowPopup(true);
  }

  function handleDeleteItem(docId) {
    const confirmBox = window.confirm(`Do you want to delete this item?`);
    if (confirmBox) {
      deleteDbData({ collection: 'products', docId });
    }
  }

  function handleClosePopupBox() {
    setIsShowPopup(false);
  }

  function handleResetSearch() {
    setSearchModelNo('');
  }

  return (
    <div className='container-full'>
      <div className='row'>
        <h1>Products</h1>

        <div>
          <Button
            text='Add New Product'
            size='sm'
            color='secondary'
            fontWeight='bold'
            onClick={handleAddNewItem}
          />
        </div>
      </div>

      <div className='row'>
        <div className='paper mb-1'>
          <h3>Search</h3>
          <SearchArea>
            <div>
              <label>
                Model No / Product Code
                <TextInput
                  value={searchModelNo}
                  onChange={(e) => setSearchModelNo(e.target.value)}
                />
              </label>
            </div>
            <div>
              <Button color='secondary' size='sm' onClick={handleResetSearch}>
                Reset
              </Button>
            </div>
          </SearchArea>
        </div>
      </div>

      {!isLoading && !isFetching ? (
        <div className='row'>
          <div className='paper'>
            {/* <div style={{ margin: '10px 0' }}>
              Quick Search [Model No / Product Code]
              <input type='text' ref={searchRef} onChange={handleFilterData} />
            </div> */}

            <DataTable
              columns={columns}
              data={filteredTableData}
              pagination
              defaultSortFieldId={1}
              progressPending={isLoading}
            />
          </div>
        </div>
      ) : (
        <LoadingSpin />
      )}

      <div className='row pt-0 mb-2'>
        Remarks:
        <br />
        Stock Qty. - Blank = Unlimited qty.
      </div>

      {isShowPopup && (
        <PopupBox
          handleClosePopupBox={handleClosePopupBox}
          backdropClickable={false}
        >
          <Editor data={editorData} handleClosePopupBox={handleClosePopupBox} />
        </PopupBox>
      )}
    </div>
  );
}

const SearchArea = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  align-items: flex-end;
`;
