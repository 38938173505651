import { useEffect, useState } from 'react';
import ProductList from '../../components/productList';
import PopupCard from '../../components/popupCard';
import productsData from '../../data/products';
import './styles.scss';
import Button from '../../components/form/Button';
import { useLang } from './../../contexts/lang.context';

const tempProducts = productsData.slice(0, 6);

export default function NewItems() {
  const { lang } = useLang();
  const [showPopupCard, setShowPopupCard] = useState(false);
  const [selectedProduct, setSelectProduct] = useState(null);
  const [noOfProducts, setNoOfProducts] = useState(10);
  const [data, setData] = useState(null);

  const text = {
    NEW_ITEMS: {
      langTC: '最新貨品',
      langSC: '最新货品',
      langEN: 'NEW ITEMS',
    },
  };

  useEffect(() => {
    setData(tempProducts.slice(0, noOfProducts));
  }, [noOfProducts]);

  return (
    <div id='pageNewItems' className='container'>
      <div className='row'>
        <div className='sectionHeader'>
          <span className='sectionHeader__title'>
            {text.NEW_ITEMS[`lang${lang}`]}
          </span>
        </div>

        {data && (
          <>
            <ProductList
              data={data}
              setShowPopupCard={setShowPopupCard}
              setSelectProduct={setSelectProduct}
            />

            {tempProducts.length > noOfProducts && (
              <div className='loadMoreWrapper'>
                <Button
                  color='secondary'
                  size='lg'
                  fontWeight='bold'
                  text='Load More...'
                  onClick={() => setNoOfProducts((prevState) => prevState + 10)}
                />
              </div>
            )}
          </>
        )}
      </div>

      {selectedProduct && (
        <PopupCard
          show={showPopupCard}
          data={selectedProduct}
          setShowPopupCard={setShowPopupCard}
        />
      )}
    </div>
  );
}
