import styled from 'styled-components';

const Input = styled.textarea`
  background-color: white;
  font-style: 1.6rem;
  padding: 6px 10px;
  border: 1px solid #58595b;
  border-radius: 5px;
  width: 100%;
  height: 200px;
`;

export default function TextArea({ children, ...rest }) {
  return <Input {...rest} defaultValue={children} />;
}
