import styled from 'styled-components';

const Select = styled.select`
  background-color: white;
  font-style: 1.6rem;
  padding: 6px 10px;
  border: 1px solid #58595b;
  border-radius: 5px;
  width: 100%;
`;

export default function SelectBox({ options, prompt, onChange, ...rest }) {
  return (
    <Select {...rest} onChange={onChange}>
      <option value=''>{prompt}</option>
      {options &&
        options.map((option) => (
          <option key={option} value={option.value}>
            {option.label}
          </option>
        ))}
    </Select>
  );
}
