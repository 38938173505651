import { useState } from 'react';
import styled from 'styled-components';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import SlideMenu from './SlideMenu';

const Haumbuger = styled.div`
  background-color: var(--color-secondary);
  width: 34px;
  height: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 999;
`;

export default function HambugerBtn(props) {
  const [showMenu, setShowMenu] = useState(false);

  function toggleSlideMenu() {
    setShowMenu(!showMenu);
  }

  return (
    <>
      <Haumbuger {...props} onClick={toggleSlideMenu}>
        <MenuRoundedIcon style={{ color: '#183C48', fontSize: 24 }} />
      </Haumbuger>
      <SlideMenu showMenu={showMenu} setShowMenu={setShowMenu} />
    </>
  );
}
