import { useState, useRef } from 'react';
import { useAuth } from '../../../contexts/auth.context';
import { useLang } from './../../../contexts/lang.context';
import TextInput from '../../../components/form/TextInput';
import Button from '../../../components/form/Button';
import ResultMessageBox from '../../../components/ResultMessageBox';

const text = {
  PAGE_TITLE: {
    langTC: '安全性',
    langSC: '安全性',
    langEN: 'SECURITY',
  },
  CHANGE_PASSWORD: {
    langTC: '變更密碼',
    langSC: '变更密码',
    langEN: 'Change Password',
  },
  EMAIL: {
    langTC: '帳戶電郵',
    langSC: '帐户电邮',
    langEN: 'User Email',
  },
  NEW_PASSWORD: {
    langTC: '新密碼',
    langSC: '新密码',
    langEN: 'New Password',
  },
  CONFIRM_NEW_PASSWORD: {
    langTC: '再次輸入新密碼',
    langSC: '再次输入新密码',
    langEN: 'Confirm New Password',
  },
  SAVE: {
    langTC: '儲存',
    langSC: '储存',
    langEN: 'SAVE',
  },
  ERROR_PASSWORD_NOT_SAME: {
    langTC: '新密碼與再次輸入新密碼必須相同！',
    langSC: '新密码与再次输入新密码必须相同！',
    langEN: 'New Password and Confirm New Password must be same!',
  },
  UPDATE_SUCCESS: {
    langTC: '密碼已成功更新！',
    langSC: '密码已成功更新！',
    langEN: 'Password successfully updated!',
  },
};

export default function Security() {
  const msgBox = useRef();
  const { currentUser, updatePassword } = useAuth();
  const { lang } = useLang();
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [result, setResult] = useState('');

  async function handleFormSubmit(e) {
    e.preventDefault();

    // Reset states
    setError('');
    setIsLoading(true);

    // Check password and confirm password is same
    if (newPassword !== confirmNewPassword) {
      setError(text.ERROR_PASSWORD_NOT_SAME[`lang${lang}`]);
      setIsLoading(false);
      return;
    }

    try {
      await updatePassword(newPassword);

      setResult(text.UPDATE_SUCCESS[`lang${lang}`]);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
      return msgBox.current.scrollIntoView();
    }
  }

  return (
    <div id='pageMyAccountSecurity' className='container'>
      <div className='row'>
        <h1 className='pageTitle'>{text.PAGE_TITLE[`lang${lang}`]}</h1>

        <h3>{text.CHANGE_PASSWORD[`lang${lang}`]}</h3>

        <p>{`${text.EMAIL[`lang${lang}`]}: ${currentUser.email}`}</p>

        <div ref={msgBox}>
          {error && <ResultMessageBox type='error' messages={error} />}
          {result && <ResultMessageBox type='success' messages={result} />}
        </div>

        <form onSubmit={handleFormSubmit}>
          <div className='formGroup'>
            <label>
              {text.NEW_PASSWORD[`lang${lang}`]}
              <TextInput
                type='password'
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </label>
          </div>

          <div className='formGroup'>
            <label>
              {text.CONFIRM_NEW_PASSWORD[`lang${lang}`]}
              <TextInput
                type='password'
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
              />
            </label>
          </div>

          <div className='formGroup'>
            <Button
              type='submit'
              text={text.SAVE[`lang${lang}`]}
              color='secondary'
              size='sm'
              fontWeight='bold'
              isLoading={isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
