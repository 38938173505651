import styled from 'styled-components';
import { useLang } from '../../contexts/lang.context';

export default function Languages(props) {
  const { lang, setLang } = useLang();

  return (
    <Wrapper {...props}>
      {/* <span
        onClick={() => setLang('TC')}
        className={lang === 'TC' ? 'selected' : null}
      >
        繁體
      </span>
      <span
        onClick={() => setLang('SC')}
        className={lang === 'SC' ? 'selected' : null}
      >
        简体
      </span>
      <span
        onClick={() => setLang('EN')}
        className={lang === 'EN' ? 'selected' : null}
      >
        ENG
      </span> */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-size: 1.5rem;
    cursor: pointer;

    &.selected {
      color: var(--color-primary);
    }
  }
`;
