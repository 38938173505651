import styled from 'styled-components';
import CurrencyFormat from 'react-currency-format';
import { useLang } from './../../../contexts/lang.context';

export default function CartListFooter({ paymentTotal }) {
  const { lang } = useLang();

  const text = {
    paymentTotal: {
      langTC: '總額',
      langSC: '总额',
      langEN: 'Payment Total',
    },
  };

  return (
    <Container>
      {text.paymentTotal[`lang${lang}`]}&nbsp;HKD&nbsp;
      <CurrencyFormat
        value={paymentTotal}
        displayType='text'
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  text-align: right;
  color: var(--color-primary);
  font-weight: bold;
  font-size: 1.6rem;
  margin-top: 10px;
`;
