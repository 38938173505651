import styled from 'styled-components';

export default function CheckBox({ label, checked = false, onChange }) {
  return (
    <Wrapper>
      <Label>
        <Check type='checkbox' defaultChecked={checked} onChange={onChange} />
        {label}
      </Label>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Check = styled.input`
  width: auto;
  margin-right: 10px;
`;
