import { useEffect, useState, useContext, createContext } from "react";

const LangContext = createContext();

// Load from localstorage
// const LANG_INITIAL_STATE = JSON.parse(localStorage.getItem('lang')) || 'EN';

// default EN only
const LANG_INITIAL_STATE = 'EN';

export function useLang() {
  return useContext(LangContext);
}

export default function LangProvider({ children }) {
  const [lang, setLang] = useState(LANG_INITIAL_STATE);

  // Store into localstorage
  useEffect(() => {
    localStorage.setItem('lang', JSON.stringify(lang));
  }, [lang]);

  const value = {
    lang: lang.toUpperCase(),
    setLang
  };

  return (
    <LangContext.Provider value={value}>
      {children}
    </LangContext.Provider>
  );
}

