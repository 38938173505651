import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLang } from './../../contexts/lang.context';
import useGetDbList from './../../hooks/useGetDbList';
import './styles.scss';

const text = {
  TITLE: {
    langTC: '最新消息',
    langSC: '最新消息',
    langEN: 'NEWS',
  },
  MORE: {
    langTC: '更多',
    langSC: '更多',
    langEN: 'MORE',
  },
};

export default function BoxNews() {
  const history = useHistory();
  const { lang } = useLang();
  const [filteredData, setFilteredData] = useState([]);
  const { data, isLoading } = useGetDbList({
    collection: 'news',
  });

  useEffect(() => {
    if (isLoading) return;

    function compare(a, b) {
      if (a.newsDate > b.newsDate) {
        return -1;
      }
      if (a.newsDate < b.newsDate) {
        return 1;
      }
      return 0;
    }

    data.sort(compare);

    setFilteredData(data);
  }, [isLoading, data]);

  // const newsCover =
  //   'https://res.cloudinary.com/ebasell/image/upload/v1631391872/news/news-cover.jpg';

  return (
    <div className='homeNewsBox' onClick={() => history.push('/news')}>
      <div className='homeNewsBox__textWrapper'>
        <div className='title'>{text.TITLE[`lang${lang}`]}</div>

        {!isLoading && (
          <>
            <div className='subject'>
              {filteredData[0]?.subject[`lang${lang}`]}
            </div>

            <div
              className='content'
              dangerouslySetInnerHTML={{
                __html: filteredData[0]?.content[`lang${lang}`],
              }}
            />
          </>
        )}

        <div className='readMore'>
          <span className='clickable'>
            {text.MORE[`lang${lang}`]}&nbsp;&gt;
          </span>
        </div>
      </div>

      {!isLoading && (
        <div className='homeNewsBox__imgWrapper'>
          <img src={filteredData[0]?.imageUrl} alt='' />
        </div>
      )}
    </div>
  );
}
