import { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';

import { useLang } from './../../../contexts/lang.context';
import { db } from '../../../data/firestore/config';
import { useAuth } from '../../../contexts/auth.context';
import Button from './../../../components/form/Button';
import PopupBox from '../../../components/PopupBox';
import TransactionDetails from '../../../components/TransactionDetails';
// import useGetDbList from '../../../hooks/useGetDbList';

import './styles.scss';

const text = {
  PAGE_TITLE: {
    langTC: '交易記錄',
    langSC: '交易记录',
    langEN: 'TRANSACTIONS',
  },
  DATE: {
    langTC: '日期',
    langSC: '日期',
    langEN: 'Date',
  },
  TRANSACTION_ID: {
    langTC: '交易編號',
    langSC: '交易编号',
    langEN: 'Transaction ID',
  },
  PAYMENT_STATUS: {
    langTC: '付款狀態',
    langSC: '付款状态',
    langEN: 'Payment Status',
  },
  PAYMENT_AMOUNT: {
    langTC: '總金額',
    langSC: '总金额',
    langEN: 'Amount',
  },
  VIEW: {
    langTC: '詳細',
    langSC: '详细',
    langEN: 'View',
  },
  NO_RECORD: {
    langTC: '沒有交易記錄',
    langSC: '没有交易记录',
    langEN: 'No transaction.',
  },
};

export default function Transactions() {
  const { currentUser } = useAuth();
  const { lang } = useLang();
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [transaction, setTransaction] = useState({});

  useEffect(() => {
    const unsubscrib = db
      .collection('transactions')
      .where('userId', '==', currentUser.uid)
      .orderBy('createdAt', 'desc')
      .get()
      .then((snapshot) => {
        let response = [];
        snapshot.forEach((doc) => {
          const paymentStatus = doc.data().paymentDetails?.paymentStatus;
          if (paymentStatus === 'successed' || paymentStatus === 'bankin') {
            response.push({ ...doc.data(), docId: doc.id });
          }
        });
        setData(response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setError(error);
        setIsLoading(false);
      });

    return unsubscrib;
  }, [currentUser.uid]);

  function handleViewDetails(data) {
    setTransaction(data);
    setIsShowPopup(true);
  }

  function handleClosePopupBox() {
    setIsShowPopup(false);
  }

  function formatTransactionID(docId) {
    const upperCaseDocId = docId.toUpperCase();

    const stringHead = upperCaseDocId.substring(0, 8);
    const stringTail = upperCaseDocId.substring(8);
    const output = (
      <>
        <Highlight>{stringHead}</Highlight>
        {stringTail}
      </>
    );
    return output;
  }

  function showPaymentStatus(paymentStatus) {
    switch (paymentStatus) {
      case 'bankin':
        return 'bank in';
      case 'pending':
        return 'incomplete';
      default:
        return paymentStatus;
    }
  }

  return (
    <div id='pageMyAccountTransactions' className='container'>
      <div className='row'>
        <h1 className='pageTitle'>{text.PAGE_TITLE[`lang${lang}`]}</h1>

        <div className='responsive-table pb-1'>
          <table className='table-transactions'>
            <thead>
              <tr>
                <th>{text.DATE[`lang${lang}`]}</th>
                <th>{text.TRANSACTION_ID[`lang${lang}`]}</th>
                <th>{text.PAYMENT_STATUS[`lang${lang}`]}</th>
                <th className='currency'></th>
                <th className='amount'>{text.PAYMENT_AMOUNT[`lang${lang}`]}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <div>{text.NO_RECORD[`lang${lang}`]}</div>
              ) : (
                data.map((transaction) => (
                  <tr key={transaction.docId}>
                    <td>
                      {moment(transaction.createdAt).format(
                        'DD-MMM-YYYY HH:mm',
                      )}
                    </td>
                    <td>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleViewDetails(transaction)}
                      >
                        {formatTransactionID(
                          `${transaction.docId.slice(0, 8)}...`,
                        )}
                      </div>
                    </td>
                    {/* <td>{`${transaction.docId.slice(0, 8).toUpperCase()}...`}</td> */}
                    <td>
                      {showPaymentStatus(
                        transaction.paymentDetails.paymentStatus,
                      ).toUpperCase()}
                    </td>
                    <td className='currency'>
                      {transaction.currency.toUpperCase()}
                    </td>
                    <td className='amount'>
                      <CurrencyFormat
                        value={transaction.amount}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale
                        decimalScale={2}
                        prefix='$'
                      />
                      {/* {transaction.amount} */}
                    </td>
                    <td className='actions'>
                      <Button
                        color='secondary'
                        size='sm'
                        onClick={() => handleViewDetails(transaction)}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {isShowPopup && (
        <PopupBox
          handleClosePopupBox={handleClosePopupBox}
          backdropClickable={true}
        >
          <TransactionDetails
            data={transaction}
            handleClosePopupBox={handleClosePopupBox}
          />
        </PopupBox>
      )}
    </div>
  );
}

const Highlight = styled.span`
  color: var(--color-secondary-dark);
  background-color: var(--color-secondary-light);
  padding: 2px;
`;
