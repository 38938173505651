const productsData = [
  {
    uid: "1",
    brand: "AEI Cable",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      '1'
    ],
    description: {
      langEN: "PVC Conduit Wiring Primarily for use within conduit or trunking but may also be usied where suitable rotection against mechanixal damage exists eg within light ﬁttings, seitchgear and control gear."
    },
    unitPrice: 959.00,
    unit: "roll",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214759/products/temp-product6_ztt32n.jpg",
    imagePublicId: 'products/temp-product6_ztt32n.jpg',
    spec: {
      langEN: ""
    },
    remarks: {
      langEN: "This is remarks text..."
    },
    downloadFiles: [
      {
        "title": {
          langEN: "Standard Cable Technical Catalogue"
        },
        "url": "http://download.com"
      },
      {
        "title": {
          langEN: "Data Sheets"
        },
        "url": "http://download.com"
      }
    ]
  },
  {
    uid: "2",
    brand: "AEI Cable",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      '2'
    ],
    description: {
      langEN: "PVC Conduit Wiring Primarily for use within conduit or trunking but may also be usied where suitable rotection against mechanixal damage exists eg within light ﬁttings, seitchgear and control gear."
    },
    unitPrice: 0.5,
    unit: "pc",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214759/products/temp-product2_ybju09.jpg",
    imagePublicId: 'products/temp-product2_ybju09.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  },
  {
    uid: "3",
    brand: "SCHNEIDER Curve",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      '3'
    ],
    description: {
      langEN: "PVC Conduit Wiring Primarily for use within conduit or trunking but may also be usied where suitable rotection against mechanixal damage exists eg within light ﬁttings, seitchgear and control gear."
    },
    unitPrice: 19,
    unit: "pc",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214759/products/temp-product3_xvj0hm.jpg",
    imagePublicId: 'products/temp-product3_xvj0hm.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  },
  {
    uid: "4",
    brand: "MK",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      '4'
    ],
    description: {
      langEN: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    unitPrice: 19,
    unit: "pc",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214759/products/temp-product4_aynl8g.jpg", imagePublicId: 'products/temp-product4_aynl8g.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  },
  {
    uid: "5",
    brand: "ABB",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      '5'
    ],
    description: {
      langEN: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    unitPrice: 214,
    unit: "pc",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214758/products/temp-product5_mcswun.jpg", imagePublicId: 'products/temp-product5_mcswun.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  },
  {
    uid: "6",
    brand: "PROSUN Cable Tie",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      '6'
    ],
    description: {
      langEN: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    unitPrice: 4,
    unit: "pack",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214759/products/temp-product8_ck1ejx.jpg", imagePublicId: 'products/temp-product8_ck1ejx.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  },
  {
    uid: "7",
    brand: "MK",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      '7'
    ],
    description: {
      langEN: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    unitPrice: 8.5,
    unit: "pc",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214759/products/temp-product7_tirybn.jpg", imagePublicId: 'products/temp-product7_tirybn.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  },
  {
    uid: "8",
    brand: "AEI Cable",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      '1'
    ],
    description: {
      langEN: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    unitPrice: 230,
    unit: "roll",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214649/products/temp-product1_sbqykt.jpg", imagePublicId: 'products/temp-product1_sbqykt.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  },
  {
    uid: "9",
    brand: "EVCCO",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      '2'
    ],
    description: {
      langEN: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    unitPrice: 8.5,
    unit: "pc",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214759/products/temp-product7_tirybn.jpg", imagePublicId: 'products/temp-product7_tirybn.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  },
  {
    uid: "10",
    brand: "AEI Cable",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      '3'
    ],
    description: {
      langEN: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    unitPrice: 230,
    unit: "roll",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214649/products/temp-product1_sbqykt.jpg", imagePublicId: 'products/temp-product1_sbqykt.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  }, {
    uid: "11",
    brand: "KME",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      '8'
    ],
    description: {
      langEN: "PVC Conduit Wiring Primarily for use within conduit or trunking but may also be usied where suitable rotection against mechanixal damage exists eg within light ﬁttings, seitchgear and control gear."
    },
    unitPrice: 959.00,
    unit: "roll",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214759/products/temp-product6_ztt32n.jpg", imagePublicId: 'products/temp-product6_ztt32n.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: [
      {
        "title": {
          langEN: "Standard Cable Technical Catalogue"
        },
        "url": "http://download.com"
      },
      {
        "title": {
          langEN: "Data Sheets"
        },
        "url": "http://download.com"
      }
    ]
  },
  {
    uid: "12",
    brand: "CAVICEL",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      '9'
    ],
    description: {
      langEN: "PVC Conduit Wiring Primarily for use within conduit or trunking but may also be usied where suitable rotection against mechanixal damage exists eg within light ﬁttings, seitchgear and control gear."
    },
    unitPrice: 0.5,
    unit: "pc",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214759/products/temp-product2_ybju09.jpg", imagePublicId: 'products/temp-product2_ybju09.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  },
  {
    uid: "13",
    brand: "VICHNET",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      '10'
    ],
    description: {
      langEN: "PVC Conduit Wiring Primarily for use within conduit or trunking but may also be usied where suitable rotection against mechanixal damage exists eg within light ﬁttings, seitchgear and control gear."
    },
    unitPrice: 19,
    unit: "pc",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214759/products/temp-product3_xvj0hm.jpg",
    imagePublicId: 'products/temp-product3_xvj0hm.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  },
  {
    uid: "14",
    brand: "MK Push Switch (WP)",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      'Communication'
    ],
    description: {
      langEN: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    unitPrice: 19,
    unit: "pc",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214759/products/temp-product4_aynl8g.jpg", imagePublicId: 'products/temp-product4_aynl8g.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  },
  {
    uid: "15",
    brand: "ABB",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      'Communication'
    ],
    description: {
      langEN: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    unitPrice: 214,
    unit: "pc",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214758/products/temp-product5_mcswun.jpg", imagePublicId: 'products/temp-product5_mcswun.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  },
  {
    uid: "16",
    brand: "PROSUN Cable Tie",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      'Communication'
    ],
    description: {
      langEN: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    unitPrice: 4,
    unit: "pack",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214759/products/temp-product8_ck1ejx.jpg", imagePublicId: 'products/temp-product8_ck1ejx.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  },
  {
    uid: "17",
    brand: "MK 2 Way Switch",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      'Communication'
    ],
    description: {
      langEN: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    unitPrice: 8.5,
    unit: "pc",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214759/products/temp-product7_tirybn.jpg", imagePublicId: 'products/temp-product7_tirybn.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  },
  {
    uid: "18",
    brand: "AEI Cable",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      'Communication'
    ],
    description: {
      langEN: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    unitPrice: 230,
    unit: "roll",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214649/products/temp-product1_sbqykt.jpg", imagePublicId: 'products/temp-product1_sbqykt.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  },
  {
    uid: "19",
    brand: "MK 2 Way Switch",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      'Communication'
    ],
    description: {
      langEN: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    unitPrice: 8.5,
    unit: "pc",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214759/products/temp-product7_tirybn.jpg", imagePublicId: 'products/temp-product7_tirybn.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  },
  {
    uid: "20",
    brand: "UNEX",
    modelNo: "BSEN50525-2-31",
    productCode: "01AEI160041.5BE500",
    productName: {
      langEN: "1.5mm 1/C PVC Cable (500m/RL)-Blue BS6004"
    },
    categories: [
      'Communication'
    ],
    description: {
      langEN: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    unitPrice: 230,
    unit: "roll",
    imageUrl: "https://res.cloudinary.com/ebasell/image/upload/v1631214649/products/temp-product1_sbqykt.jpg", imagePublicId: 'products/temp-product1_sbqykt.jpg',
    spec: {
      langEN: ""
    },
    downloadFiles: []
  }
];

export default productsData;