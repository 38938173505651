import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DisableIcon from '@material-ui/icons/Block';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

// import { db } from '../../../data/firestore/config';
import { deleteDbData, setDbData } from './../../../utils';
import useListenDbDoc from './../../../hooks/useListenDbDoc';
import Button from '../../../components/form/Button';
import PopupBox from '../../../components/PopupBox';
import Editor from '../../../components/admin/editors/EditBrand';
import LoadingSpin from '../../../components/LoadingSpin';

const tableColumns = [
  {
    name: 'Edit',
    selector: (row) => row.btnEdit,
    maxWidth: '40px',
  },
  {
    name: 'Seq.',
    selector: (row) => row.seq,
    sortable: true,
    maxWidth: '30px',
  },
  {
    name: 'Move',
    selector: (row) => row.btnMoveUpDown,
    maxWidth: '50px',
  },
  {
    name: 'Code',
    selector: (row) => row.code,
    sortable: true,
    maxWidth: '50px',
  },
  {
    name: 'Name(ENG)',
    selector: (row) => row.name.langEN,
    sortable: true,
  },
  {
    name: 'Name(TC)',
    selector: (row) => row.name.langTC,
    sortable: true,
  },
  {
    name: 'Name(SC)',
    selector: (row) => row.name.langSC,
    sortable: true,
  },
  {
    name: 'Highlight',
    selector: (row) => (row.isHighlight ? 'Yes' : ''),
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => (row.isDisable ? row.statusDisable : ''),
    sortable: true,
  },
  {
    name: 'Delete',
    selector: (row) => row.btnDelete,
    maxWidth: '40px',
  },
];

export default function Brands() {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editorData, setEditorData] = useState(null);
  const [dataIndex, setDataIndex] = useState(-1);
  const { data, fetchError, isFetching } = useListenDbDoc({
    collection: 'brands',
    docId: '001',
  });

  // useEffect(() => {
  //   const unsubscribe = db.collection('brands').onSnapshot(
  //     (querySnapshot) => {
  //       var data = [];

  //       querySnapshot.forEach((doc) => {
  //         const dataWithId = { ...doc.data(), docId: doc.id };

  //         data.push({
  //           ...dataWithId,
  //           btnEdit: createEditButton(dataWithId),
  //           btnDelete: createDeleteButton(doc.id),
  //         });
  //       });

  //       setTableData(data);
  //     },
  //     (error) => {
  //       console.log(error.message);
  //     },
  //   );

  //   return unsubscribe;
  // }, []);

  useEffect(() => {
    if (isFetching) return;

    let tempData = [];
    setIsLoading(true);

    const noOfItems = data.values.length;

    data.values?.forEach((doc, index) => {
      tempData.push({
        ...doc,
        seq: index + 1,
        btnEdit: createEditButton({ doc, index }),
        btnMoveUpDown: createMoveUpDownButton({ index, noOfItems }),
        btnDelete: createDeleteButton(index),
        statusDisable: createStatusDisable(doc),
      });
    });

    setTableData(tempData);
    setIsLoading(false);
  }, [data, isFetching]);

  function createEditButton({ data: doc, index }) {
    return (
      <EditIcon
        fontSize='large'
        className='clickable'
        // color=''
        style={{ color: '#33a35e' }}
        onClick={() => handleEditItem(data, index)}
      />
    );
  }

  function createMoveUpDownButton({ index, noOfItems }) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: '22px' }}>
          {index > 0 && (
            <KeyboardArrowUpRoundedIcon
              fontSize='large'
              className='clickable'
              style={{ color: '#33a35e' }}
              onClick={() => handleMoveUp(index)}
            />
          )}
        </div>

        <div style={{ width: '22px' }}>
          {noOfItems > 0 && index < noOfItems - 1 && (
            <KeyboardArrowDownRoundedIcon
              fontSize='large'
              className='clickable'
              style={{ color: '#33a35e' }}
              onClick={() => handleMoveDown(index)}
            />
          )}
        </div>
      </div>
    );
  }

  function createStatusDisable(data) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
        <DisableIcon
          fontSize='large'
          // className='clickable'
          color='error'
        />
        Disabled
      </div>
    );
  }

  function createDeleteButton(index) {
    return (
      <DeleteIcon
        fontSize='large'
        className='clickable'
        color='error'
        onClick={() => handleDeleteItem(index)}
      />
    );
  }

  function handleAddNewItem(data) {
    setEditorData(data);
    setDataIndex(-1);
    setIsShowPopup(true);
  }

  function handleEditItem(data, index) {
    setEditorData(data);
    setDataIndex(index);
    setIsShowPopup(true);
  }

  function handleMoveUp(index) {
    if (!index || index === 0) return;

    const temp = data.values[index - 1];
    data.values[index - 1] = data.values[index];
    data.values[index] = temp;

    try {
      setDbData({
        collection: 'brands',
        docId: '001',
        data: data,
      });
    } catch (error) {
      // setError(error.message);
    } finally {
      // setIsLoading(false);
    }
  }

  function handleMoveDown(index) {
    const temp = data.values[index + 1];
    data.values[index + 1] = data.values[index];
    data.values[index] = temp;

    try {
      setDbData({
        collection: 'brands',
        docId: '001',
        data: data,
      });
    } catch (error) {
      // setError(error.message);
    } finally {
      // setIsLoading(false);
    }
  }

  function handleDeleteItem(index) {
    const confirmBox = window.confirm(`Do you want to delete this item?`);
    if (confirmBox) {
      // deleteDbData({ collection: 'brands', docId });

      data.values.splice(index, 1);

      try {
        setDbData({
          collection: 'brands',
          docId: '001',
          data: data,
        });
      } catch (error) {
        // setError(error.message);
      } finally {
        // setIsLoading(false);
      }
    }
  }

  function handleClosePopupBox() {
    setIsShowPopup(false);
  }

  return (
    <div className='container-full'>
      <div className='row'>
        <h1>Brands</h1>
        <div>
          <Button
            text='Add New Brand'
            size='sm'
            color='secondary'
            fontWeight='bold'
            onClick={() => handleAddNewItem(data)}
          />
        </div>
      </div>

      {!isLoading && !isFetching ? (
        <div className='row'>
          <div className='paper'>
            <DataTable columns={tableColumns} data={tableData} pagination />
          </div>
        </div>
      ) : (
        <LoadingSpin />
      )}

      {isShowPopup && (
        <PopupBox
          handleClosePopupBox={handleClosePopupBox}
          backdropClickable={false}
        >
          <Editor
            data={editorData}
            dataIndex={dataIndex}
            handleClosePopupBox={handleClosePopupBox}
          />
        </PopupBox>
      )}
    </div>
  );
}
