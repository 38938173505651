const text = {
  DELIVERY_OPTION: {
    langTC: '交貨方式',
    langSC: '交货方式',
    langEN: 'DELIVERY OPTION',
  },
  SELF_COLLECT: {
    langTC: '自取',
    langSC: '自取',
    langEN: 'Self collect',
  },
  HK_STANDARD_DELIVERY: {
    langTC: '香港標準快遞 (免運費)',
    langSC: '香港标准快递 (免运费)',
    langEN: 'HK standard delivery (Free of charge)',
  },
  SELF_COLLECT_ADDRESS: {
    langTC: '自取地址',
    langSC: '自取地址',
    langEN: 'SELF COLLECT ADDRESS',
  },
  DELIVERY_ADDRESS: {
    langTC: '收貨地址',
    langSC: '收货地址',
    langEN: 'DELIVERY ADDRESS',
  },
  DELIVERY_COUNTRY: {
    langTC: '收貨國家/地區',
    langSC: '收货国家/地区',
    langEN: 'Delivery Country/Location',
  },
  ADDRESS_LINE: {
    langTC: '地址行',
    langSC: '地址行',
    langEN: 'Address Line',
  },
  CONTACT: {
    langTC: '收貨人',
    langSC: '收货人',
    langEN: 'CONTACT',
  },
  CONTACT_NAME: {
    langTC: '姓名',
    langSC: '姓名',
    langEN: 'Contact Name',
  },
  TEL: {
    langTC: '電話',
    langSC: '电话',
    langEN: 'Tel',
  },
  PAYMENT_METHOD: {
    langTC: '付款方式',
    langSC: '付款方式',
    langEN: 'PAYMENT METHOD',
  },
  CARD_NAME: {
    langTC: '信用卡上的姓名',
    langSC: '信用卡上的姓名',
    langEN: 'Name On Your Card',
  },
  PAY_NOW: {
    langTC: '立即付款',
    langSC: '立即付款',
    langEN: 'PAY NOW',
  },
  CONFIRM_ORDER: {
    langTC: '立即付款',
    langSC: '立即付款',
    langEN: 'CONFIRM AND SUBMIT ORDER',
  },
  PAYMENT_FAILED: {
    langTC: '付款失敗 -',
    langSC: '付款失败 -',
    langEN: 'Payment failed -',
  },
};

export default text;