const text = {
  PAGE_TITLE: {
    langTC: '會員登入',
    langSC: '会员登入',
    langEN: 'SIGN IN',
  },
  TEXT1: {
    langTC: '* 必須填寫',
    langSC: '* 必须填写',
    langEN: '* must be completed',
  },
  TEXT2: {
    langTC: '登入帳戶',
    langSC: '登入帐户',
    langEN: 'LOGIN YOUR ACCOUNT',
  },
  EMAIL: {
    langTC: '電郵地址',
    langSC: '电邮地址',
    langEN: 'Email',
  },
  PASSWORD: {
    langTC: '密碼',
    langSC: '密码',
    langEN: 'Password',
  },
  SIGNIN: {
    langTC: '登入',
    langSC: '登入',
    langEN: 'SIGN IN',
  },
  FORGOT_PASSWORD: {
    langTC: '忘記密碼？',
    langSC: '忘记密码？',
    langEN: 'Forgot password?',
  },
};

export default text;