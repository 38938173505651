import { useRef } from 'react';
import Tippy from '@tippyjs/react';
import { Image, Transformation } from 'cloudinary-react';
import CurrencyFormat from 'react-currency-format';

import DeleteIcon from '@material-ui/icons/Delete';
import { useLang } from './../../../contexts/lang.context';
import { useCart } from '../../../contexts/cart.context';
import { useBrands } from '../../../contexts/brands.context';
import NumInputGroup from '../../form/NumInputGroup';
import outputBrandName from './../../../utils/outputBrandName';

import 'tippy.js/dist/tippy.css';
import './styles.scss';

export default function CartListItem({ item, mode, isShowFail }) {
  const { lang } = useLang();
  const { cartDispatch, cartActions } = useCart();
  const { brands } = useBrands();
  const qtyRef = useRef();

  const text = {
    qty: {
      langTC: '數量：',
      langSC: '数量：',
      langEN: 'Qty.:',
    },
    delete: {
      langTC: '刪除',
      langSC: '删除',
      langEN: 'Delete',
    },
    deleteQuestion: {
      langTC: '確定要移除這個項目嗎？',
      langSC: '确定要移除这个项目吗？',
      langEN: 'Do you want to remove this item?',
    },
  };

  // const product = productsData.find(({ uid }) => uid === item.uid);
  const product = item;

  function handleMinus() {
    if (!qtyRef.current.value || qtyRef.current.value <= 1) {
      qtyRef.current.value = 1;
      return;
    }

    qtyRef.current.value--;

    updateItemQty();
  }

  function handlePlus() {
    if (!qtyRef.current.value || qtyRef.current.value <= 0) {
      qtyRef.current.value = 1;
      return;
    }

    qtyRef.current.value++;

    updateItemQty();
  }

  function updateItemQty() {
    if (!qtyRef.current.value || qtyRef.current.value <= 0) {
      qtyRef.current.value = 1;
    }
    qtyRef.current.value = parseInt(qtyRef.current.value);

    cartDispatch({
      type: cartActions.UPDATE_CART_ITEM,
      payload: {
        uid: item.uid,
        qty: Number(qtyRef.current.value),
      },
    });
  }

  function handleRemoveItem() {
    const confirmBox = window.confirm(`${text.deleteQuestion[`lang${lang}`]}`);
    if (confirmBox) {
      cartDispatch({
        type: cartActions.REMOVE_FROM_CART,
        payload: { uid: item.uid },
      });
    }
  }

  return (
    product &&
    brands && (
      <div className='cartListItem'>
        <div className='cartListItem__img'>
          <Image cloudName='ebasell' publicId={product.imagePublicId}>
            <Transformation height='400' width='400' crop='fill' />
          </Image>
          {/* <img src={product.image} alt='' /> */}
        </div>

        <div className='cartListItem__desc'>
          <div className='brand'>
            {outputBrandName({ brands, code: product.brand, lang })}
          </div>
          <div>{product.briefDesc}</div>
          <div>Code:&nbsp;{product.productCode}</div>
          {/* <div>
          {product.modelNo}
          &nbsp;/&nbsp;
          {product.productCode}
        </div> */}
        </div>

        <div className='cartListItem__qty'>
          {text.qty[`lang${lang}`]}
          {mode === 'read' ? (
            item.qty
          ) : (
            <NumInputGroup>
              <button onClick={handleMinus}>-</button>
              {/* <input type='number' defaultValue={1} /> */}
              <input
                type='number'
                step='1'
                min='1'
                max='9999'
                ref={qtyRef}
                defaultValue={item.qty}
                onChange={updateItemQty}
              />
              <button onClick={handlePlus}>+</button>
            </NumInputGroup>
          )}
        </div>

        <div className='cartListItem__price'>
          HKD&nbsp;
          <CurrencyFormat
            value={item.unitPrice}
            displayType={'text'}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
          />
          /{product.unit}
        </div>

        <div className='cartListItem__totalPrice'>
          {/* HKD&nbsp;{item.qty * item.unitPrice} */}
          HKD&nbsp;
          <CurrencyFormat
            value={item.qty * item.unitPrice}
            displayType={'text'}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
          />
        </div>

        <div className='cartListItem__delete'>
          {mode !== 'read' && (
            <Tippy content={text.delete[`lang${lang}`]}>
              <span onClick={handleRemoveItem}>
                <DeleteIcon
                  color='action'
                  fontSize='large'
                  className='clickable'
                />
              </span>
            </Tippy>
          )}
        </div>

        {isShowFail && item.stock > 0 && item.stock < item.qty && (
          <div className='cartListItem__msg'>
            Quantity of this item is {item.stock} only!
          </div>
        )}
      </div>
    )
  );
}
