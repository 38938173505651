import { useState, useRef } from 'react';
import { useAuth } from '../../../contexts/auth.context';
import Button from '../../../components/form/Button';
import TextInput from '../../../components/form/TextInput';
import ResultMessageBox from '../../../components/ResultMessageBox';

export default function Settings() {
  const msgBox = useRef();
  const { updatePassword } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [result, setResult] = useState('');

  async function handleFormSubmit(e) {
    e.preventDefault();

    // Reset states
    setError('');
    setIsLoading(true);

    // Check password and confirm password is same
    if (newPassword !== confirmNewPassword) {
      setError('New Password and Confirm New Password must be same!');
      setIsLoading(false);
      return;
    }

    try {
      await updatePassword(newPassword);

      setResult('Password successfully updated!');
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
      return msgBox.current.scrollIntoView();
    }
  }

  return (
    <div className='container-full'>
      <div className='row'>
        <h1>Security</h1>

        <div className='paper' style={{ fontSize: '1.4rem' }}>
          <div className='mb-1'>
            <h2>Change Password</h2>
          </div>

          <div ref={msgBox}>
            {error && <ResultMessageBox type='error' messages={error} />}
            {result && <ResultMessageBox type='success' messages={result} />}
          </div>

          <form onSubmit={handleFormSubmit}>
            <div className='formGroup'>
              <label>
                New Password (Min. 8 and max. 20 characters)
                <TextInput
                  type='password'
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  minLength='8'
                  maxLength='20'
                />
              </label>
            </div>

            <div className='formGroup'>
              <label>
                Confirm New Password
                <TextInput
                  type='password'
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  required
                  minLength='8'
                  maxLength='20'
                />
              </label>
            </div>

            <div className='formGroup'>
              <Button
                type='submit'
                text='Save'
                size='xs'
                color='secondary'
                fontWeight='bold'
                isLoading={isLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
