import axios from 'axios';

export default function TestPayment() {
  function testPost() {
    axios
      .post(
        'http://localhost:5001/webapp-practice-58b0c/us-central1/createStripePayment',
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log(error));
  }
  return (
    <div>
      <button onClick={testPost}>Pay Now!</button>
    </div>
  );
}
