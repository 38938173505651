import { NavHashLink, HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import { useLang } from './../../contexts/lang.context';
import CategorySubMenu from './CategorySubMenu';
import BrandsSubMenu from './BrandsSubMenu';
import useQuery from './../../hooks/useQuery';

const text = {
  RANGE: {
    langEN: 'PRODUCT RANGE',
    langTC: '貨品範圍',
    langSC: '货品范围',
  },
  BRAND: {
    langEN: 'BRAND',
    langTC: '品牌',
    langSC: '品牌',
  },
  PROMOTION: {
    langEN: 'PROMOTION',
    langTC: '促銷優惠',
    langSC: '促销优惠',
  },
};

export default function MainNavLg(props) {
  const { lang } = useLang();
  const query = useQuery();

  const queryRange = query.get('range');
  const queryBrand = query.get('brand');
  const queryIsPromotion = query.get('promotion') === 'true';

  return (
    <Menu {...props}>
      <Span className='categories'>
        {/* <NavHashLink to='/products' className='topLink' activeClassName='selected'> */}
        <HashLink
          to='/products'
          className={`topLink ${queryRange && 'active'}`}
        >
          {text.RANGE?.[`lang${lang}`]}
        </HashLink>
        <CategorySubMenu className='subMenu' />
      </Span>
      &nbsp;/&nbsp;
      {/* <NavHashLink to='/products' className='topLink' activeClassName='selected'> */}
      <Span className='categories'>
        <HashLink
          to='/products'
          className={`topLink ${queryBrand && 'active'}`}
        >
          {text.BRAND?.[`lang${lang}`]}
        </HashLink>
        <BrandsSubMenu className='subMenu' />
      </Span>
      &nbsp;/&nbsp;
      <HashLink
        to='/products?promotion=true'
        className={`topLink ${queryIsPromotion && 'active'}`}
      >
        {text.PROMOTION?.[`lang${lang}`]}
      </HashLink>
    </Menu>
  );
}

const Menu = styled.div`
  a {
    text-decoration: none;
    color: var(--color-default-text);
    font-size: 1.6rem;
    /* padding: 5px; */
  }

  .topLink {
    font-size: 1.9rem;
    color: #000000bc;

    &.active {
      color: #000;
      font-weight: bold;
    }
  }

  .selected {
    color: var(--color-primary);
  }

  .categories {
    position: relative;

    &:hover .subMenu {
      display: block;
    }
  }
`;

const Span = styled.span`
  &:hover > div {
    display: block;
  }
`;
