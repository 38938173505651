import styled from 'styled-components';
import { useAuth } from '../../../contexts/auth.context';
import Button from './../../form/Button';

export default function HeaderBar() {
  const { currentUser, signOut } = useAuth();

  return (
    <Container>
      <Title>CMS Console</Title>
      <div>
        <Wrapper>
          <UserInfo>{currentUser.email}</UserInfo>

          <Button text='Sign Out' size='xs' color='grey' onClick={signOut} />
        </Wrapper>
      </div>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 8px 20px;
  /* background-color: white; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* gap: 20px; */
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const Title = styled.div`
  font-family: 'Noto Sans SC';
  font-weight: 500;
  color: #818181;
`;

const UserInfo = styled.div``;
