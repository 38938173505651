export const CART_ACTIONS = {
  ADD_TO_CART: 'ADD_TO_CART',
  UPDATE_CART_ITEM: 'UPDATE_CART_ITEM',
  INCREMENT_CART_ITEM: 'INCREMENT_CART_ITEM',
  DECREMENT_CART_ITEM: 'DECREMENT_CART_ITEM',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',
  CLEAR_CART: 'CLEAR_CART',
};

export function reducer(state, action) {
  // console.log(action.type, action.payload);

  switch (action.type) {
    case CART_ACTIONS.ADD_TO_CART:
      return addCartItem(state, action.payload);
    case CART_ACTIONS.UPDATE_CART_ITEM:
      return updateCartItem(state, action.payload);
    case CART_ACTIONS.INCREMENT_CART_ITEM:
      return incrementCartItem(state, action.payload);
    case CART_ACTIONS.DECREMENT_CART_ITEM:
      return decrementCartItem(state, action.payload);
    case CART_ACTIONS.REMOVE_FROM_CART:
      return removeCartItem(state, action.payload);
    case CART_ACTIONS.CLEAR_CART:
      return clearCart();
    default:
      return state;
  }
}

// Add item into cart
function addCartItem(state, payload) {
  const newState = [...state];
  const { uid, qty } = payload;

  // console.log('state', newState);
  // console.log('payload', payload);

  const cartItemIndex = newState.findIndex(item => item.uid === uid);
  if (cartItemIndex < 0) {
    newState.push(payload);
  } else {
    newState[cartItemIndex].qty += qty;
  }

  return newState;
}

function updateCartItem(state, payload) {
  const newState = [...state];
  const { uid } = payload;

  const cartItemIndex = newState.findIndex(item => item.uid === uid);
  // console.log('cartItemIndex', cartItemIndex);
  if (cartItemIndex > -1) {
    const oldState = newState[cartItemIndex];
    newState[cartItemIndex] = { ...oldState, ...payload };
  }

  return newState;
}

// Increment cart item quantity by 1
function incrementCartItem(state, payload) {
  const newState = [...state];
  const { uid } = payload;

  const cartItemIndex = newState.findIndex(item => item.uid === uid);
  if (cartItemIndex > -1) {
    newState[cartItemIndex].qty++;
  }

  return newState;
}

// Decrement cart item quantity by 1
function decrementCartItem(state, payload) {
  const newState = [...state];
  const { uid } = payload;

  const cartItemIndex = newState.findIndex(item => item.uid === uid);
  if (cartItemIndex > -1) {
    if (newState[cartItemIndex].qty > 1) {
      newState[cartItemIndex].qty--;
    }
  }

  return newState;
}

// Remove whole item from cart
function removeCartItem(state, payload) {
  const { uid } = payload;

  const newState = state.filter(item => item.uid !== uid);
  return newState;
}

// Clear shoppping cart
function clearCart() {
  return [];
}
