export default function PageNotFound() {
  return (
    <div id='pageNotFound' className='container'>
      <div className='row'>
        <h1 className='pageTitle'>Page Not Found</h1>

        <h3>We're Sorry!</h3>
        <p>
          The page you are looking for doesn't exist or an other error occurred.
        </p>
      </div>
    </div>
  );
}
