import styled from 'styled-components';

export default function NavTitle({ title, toggleSubMenu }) {
  return <Container onClick={toggleSubMenu}>{title}</Container>;
}

const Container = styled.div`
  font-size: 1.4rem;
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px 10px;
  cursor: pointer;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`;
