import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import LangProvider from './contexts/lang.context';
import AuthProvider from './contexts/auth.context';
import RangesProvider from './contexts/ranges.context';
import BrandsProvider from './contexts/brands.context';
import CartProvider from './contexts/cart.context';
import SearchProvider from './contexts/search.context';

import PrivateRoute from './routes/PrivateRoute';
import AdminRoute from './routes/AdminRoute';

import ContentLayout from './layouts/ContentLayout';
import AdminLayout from './layouts/AdminLayout';
import MyAccountLayout from './layouts/MyAccountLayout';

import Home from './pages/Home';
import News from './pages/News';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import DeliveryMethod from './pages/DeliveryMethod';
import Promotion from './pages/Promotion';
import NewItems from './pages/NewItems';
import Product from './pages/Product';
import Products from './pages/Products';
import Brands from './pages/Brands';
import Cart from './pages/Cart';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ReturnPolicy from './pages/ReturnPolicy';
import TermsConditions from './pages/TermsConditions';
import Disclaimer from './pages/Disclaimer';
import AboutDelivery from './pages/AboutDelivery';
import CopyrightInfo from './pages/CopyrightInfo';
import PageNotFound from './pages/PageNotFound';

import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import CheckOut from './pages/CheckOut';
import PaymentStatus from './pages/PaymentStatus';

import MyAccountDashboard from './pages/myAccount/Dashboard';
import MyAccountTransactions from './pages/myAccount/Transactions';
import MyAccountContactInfo from './pages/myAccount/ContactInfo';
import MyAccountSecurity from './pages/myAccount/Security';

import AdminSignIn from './pages/admin/SignIn';
import AdminDashboard from './pages/admin/Dashboard';
import AdminBrands from './pages/admin/Brands';
import AdminRanges from './pages/admin/Ranges';
import AdminUnits from './pages/admin/Units';
import AdminProducts from './pages/admin/Products';
import AdminSlideBanners from './pages/admin/SlideBanners';
import AdminAdvBanners from './pages/admin/AdvBanners';
import AdminNews from './pages/admin/News';
import AdminAboutUs from './pages/admin/AboutUs';
import AdminDeliveryMethod from './pages/admin/DeliveryMethod';
import AdminPrivacyPolicy from './pages/admin/PrivacyPolicy';
import AdminReturnPolicy from './pages/admin/ReturnPolicy';
import AdminTermsConditions from './pages/admin/TermsConditions';
import AdminDisclaimer from './pages/admin/Disclaimer';
import AdminAboutDelivery from './pages/admin/AboutDelivery';
import AdminCopyrightInfo from './pages/admin/CopyrightInfo';
import AdminTransactions from './pages/admin/Transactions';
import AdminSettings from './pages/admin/Settings';

import Upload from './pages/Upload';
import TestPayment from './pages/TestPayment';

import './styles/main.scss';

function App() {
  return (
    <Router>
      <LangProvider>
        <SearchProvider>
          <AuthProvider>
            <RangesProvider>
              <BrandsProvider>
                <CartProvider>
                  <Switch>
                    {/* test */}
                    <Route exact path='/testpayment'>
                      <ContentLayout>
                        <TestPayment />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/upload'>
                      <ContentLayout>
                        <Upload />
                      </ContentLayout>
                    </Route>

                    {/* General routes */}
                    <Route exact path='/'>
                      <ContentLayout>
                        <Home />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/news'>
                      <ContentLayout>
                        <News />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/aboutus'>
                      <ContentLayout>
                        <AboutUs />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/contact'>
                      <ContentLayout>
                        <Contact />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/deliverymethod'>
                      <ContentLayout>
                        <DeliveryMethod />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/signin'>
                      <ContentLayout>
                        <SignIn />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/signup'>
                      <ContentLayout>
                        <SignUp />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/resetpassword'>
                      <ContentLayout>
                        <ResetPassword />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/newitems'>
                      <ContentLayout>
                        <NewItems />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/promotion'>
                      <ContentLayout>
                        <Promotion />
                      </ContentLayout>
                    </Route>

                    <Route path='/products'>
                      <ContentLayout>
                        <Products />
                      </ContentLayout>
                    </Route>

                    <Route path='/product/:uid'>
                      <ContentLayout>
                        <Product />
                      </ContentLayout>
                    </Route>

                    <Route path='/brands/:brand'>
                      <ContentLayout>
                        <Brands />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/brands'>
                      <ContentLayout>
                        <Brands />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/cart'>
                      <ContentLayout>
                        <Cart />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/privacypolicy'>
                      <ContentLayout>
                        <PrivacyPolicy />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/returnpolicy'>
                      <ContentLayout>
                        <ReturnPolicy />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/termsconditions'>
                      <ContentLayout>
                        <TermsConditions />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/disclaimer'>
                      <ContentLayout>
                        <Disclaimer />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/aboutdelivery'>
                      <ContentLayout>
                        <AboutDelivery />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/copyrightinfo'>
                      <ContentLayout>
                        <CopyrightInfo />
                      </ContentLayout>
                    </Route>

                    <Route exact path='/admin/signin'>
                      <AdminSignIn />
                    </Route>

                    {/* USER private routes */}
                    <PrivateRoute exact path='/checkout'>
                      <ContentLayout>
                        <CheckOut />
                      </ContentLayout>
                    </PrivateRoute>

                    <PrivateRoute exact path='/paymentstatus/:id'>
                      <ContentLayout>
                        <PaymentStatus />
                      </ContentLayout>
                    </PrivateRoute>

                    <PrivateRoute exact path='/myaccount'>
                      <MyAccountLayout>
                        <MyAccountDashboard />
                      </MyAccountLayout>
                    </PrivateRoute>

                    <PrivateRoute exact path='/myaccount/transactions'>
                      <MyAccountLayout>
                        <MyAccountTransactions />
                      </MyAccountLayout>
                    </PrivateRoute>

                    <PrivateRoute exact path='/myaccount/contactinfo'>
                      <MyAccountLayout>
                        <MyAccountContactInfo />
                      </MyAccountLayout>
                    </PrivateRoute>

                    <PrivateRoute exact path='/myaccount/security'>
                      <MyAccountLayout>
                        <MyAccountSecurity />
                      </MyAccountLayout>
                    </PrivateRoute>

                    {/* ADMIN private routes */}
                    <AdminRoute exact path='/admin'>
                      <AdminLayout>
                        <AdminDashboard />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/dashboard'>
                      <AdminLayout>
                        <AdminDashboard />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/brands'>
                      <AdminLayout>
                        <AdminBrands />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/ranges'>
                      <AdminLayout>
                        <AdminRanges />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/units'>
                      <AdminLayout>
                        <AdminUnits />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/products'>
                      <AdminLayout>
                        <AdminProducts />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/slidebanners'>
                      <AdminLayout>
                        <AdminSlideBanners />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/advbanners'>
                      <AdminLayout>
                        <AdminAdvBanners />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/news'>
                      <AdminLayout>
                        <AdminNews />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/aboutus'>
                      <AdminLayout>
                        <AdminAboutUs />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/deliverymethod'>
                      <AdminLayout>
                        <AdminDeliveryMethod />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/privacyPolicy'>
                      <AdminLayout>
                        <AdminPrivacyPolicy />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/returnPolicy'>
                      <AdminLayout>
                        <AdminReturnPolicy />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/termsconditions'>
                      <AdminLayout>
                        <AdminTermsConditions />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/disclaimer'>
                      <AdminLayout>
                        <AdminDisclaimer />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/aboutdelivery'>
                      <AdminLayout>
                        <AdminAboutDelivery />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/copyrightinfo'>
                      <AdminLayout>
                        <AdminCopyrightInfo />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/transactions'>
                      <AdminLayout>
                        <AdminTransactions />
                      </AdminLayout>
                    </AdminRoute>

                    <AdminRoute exact path='/admin/settings'>
                      <AdminLayout>
                        <AdminSettings />
                      </AdminLayout>
                    </AdminRoute>

                    {/* Page not found */}
                    <Route>
                      <ContentLayout>
                        <PageNotFound />
                      </ContentLayout>
                    </Route>
                  </Switch>
                </CartProvider>
              </BrandsProvider>
            </RangesProvider>
          </AuthProvider>
        </SearchProvider>
      </LangProvider>
    </Router>
  );
}

export default App;
