import axios from 'axios';

export default async function sendEnquiryEmail(formData) {
  const { uid, firstName, lastName, email, tel, companyName, message } = formData;

  const emailTo = process.env.REACT_APP_ENQUIRY_EMAIL_TO;
  const emailFrom = process.env.REACT_APP_ENQUIRY_EMAIL_FROM;
  const emailFromDisplay = 'ebasell Online Shop';
  const emailReplyTo = emailFrom;
  const emailSubject = `ebasell - Online Contact Message from <${firstName} ${lastName}>`;
  let emailBody = `<h2 style='font-size:22x; margin:10px 0'>Contact Message</h2>`;
  emailBody += `<p style='font-size:16px; margin:0'>`;
  emailBody += `<small><i>Ref. ID: ${uid}</i></small><br /><br />`;
  emailBody += `<b>Contact Name: </b>${firstName} ${lastName}<br />`;
  emailBody += `<b>Email: </b>${email}<br />`;
  emailBody += `<b>Contact Phone: </b>${tel}<br />`;
  emailBody += `<b>Company Name: </b>${companyName}<br />`;
  emailBody += `<b>Message: </b><br />${message}<br />`;
  emailBody += `</p>`;

  // Call api to send email
  await axios
    .post(process.env.REACT_APP_SEND_EMAIL_API_URL, {
      uid, emailTo, emailFrom, emailFromDisplay, emailReplyTo, emailSubject, emailBody
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw new Error(error);
      // console.log(error);
    });
}
