import { useEffect, useState } from 'react';
import { useLang } from './../../contexts/lang.context';
import useGetDbList from '../../hooks/useGetDbList';
import LoadingSpin from '../../components/LoadingSpin';

import './styles.scss';

const text = {
  PAGE_TITLE: {
    langTC: '最新消息',
    langSC: '最新消息',
    langEN: 'NEWS',
  },
  DATE: {
    langTC: '日期',
    langSC: '日期',
    langEN: 'Date',
  },
};

export default function News() {
  const { lang } = useLang();
  const [filteredData, setFilteredData] = useState([]);
  const { data, isLoading } = useGetDbList({
    collection: 'news',
  });

  useEffect(() => {
    if (isLoading) return;

    function compare(a, b) {
      if (a.newsDate > b.newsDate) {
        return -1;
      }
      if (a.newsDate < b.newsDate) {
        return 1;
      }
      return 0;
    }

    data.sort(compare);

    setFilteredData(data);
  }, [isLoading, data]);

  return (
    <div id='pageNews' className='container'>
      <div className='row'>
        <h1 className='pageTitle'>{text.PAGE_TITLE[`lang${lang}`]}</h1>

        {isLoading ? (
          <LoadingSpin />
        ) : (
          // <div
          //   dangerouslySetInnerHTML={{
          //     __html: data[0]?.content?.[`lang${lang}`],
          //   }}
          // />

          filteredData.map((item, index) => {
            const { subject, newsDate, content, imageUrl, imagePublicId } =
              item;

            return (
              <div className='news' key={index}>
                <div className='news__subject'>{subject[`lang${lang}`]}</div>
                <div className='news__date'>{`${
                  text.DATE[`lang${lang}`]
                }: ${newsDate}`}</div>

                {imageUrl && (
                  <div className='news__imgbox'>
                    <img src={imageUrl} alt='' />
                  </div>
                )}

                <div
                  className='news__content'
                  dangerouslySetInnerHTML={{
                    __html: content[`lang${lang}`],
                  }}
                />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}
