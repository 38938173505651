import { useState } from 'react';
import useToastify from '../../../../hooks/useToastify';
import { setDbData } from '../../../../utils';
import Button from '../../../form/Button';
import TextInput from '../../../form/TextInput';
import ResultMessageBox from '../../../ResultMessageBox';
import CheckBox from './../../../form/CheckBox';

export default function EditBrand({ data, dataIndex, handleClosePopupBox }) {
  const notify = useToastify();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [code, setCode] = useState(
    dataIndex === -1 ? '' : data.values[dataIndex]?.code,
  );
  const [nameTC, setNameTC] = useState(
    dataIndex === -1 ? '' : data.values[dataIndex]?.name?.langTC,
  );
  const [nameSC, setNameSC] = useState(
    dataIndex === -1 ? '' : data.values[dataIndex]?.name?.langSC,
  );
  const [nameEN, setNameEN] = useState(
    dataIndex === -1 ? '' : data.values[dataIndex]?.name?.langEN,
  );
  const [isDisable, setIsDisable] = useState(
    dataIndex === -1 ? '' : data.values[dataIndex]?.isDisable,
  );
  const [isHighlight, setIsHighlight] = useState(
    dataIndex === -1 ? '' : data.values[dataIndex]?.isHighlight,
  );

  function handleFormSubmit(e) {
    e.preventDefault();

    // Reset states
    setError('');
    setIsLoading(true);

    const editData = {
      code,
      name: {
        langTC: nameTC || '',
        langSC: nameSC || '',
        langEN: nameEN || '',
      },
      isHighlight,
      isDisable,
    };

    let newDataSet = data;

    // Put new item data into dataset
    if (dataIndex > -1) {
      // Edit item
      newDataSet.values[dataIndex] = { ...editData };
    } else {
      // Add new item
      newDataSet.values.push(editData);
    }

    // console.log({ newDataSet });

    const options = {
      merge: true,
    };

    // Save data
    try {
      setDbData({
        collection: 'brands',
        docId: '001',
        data: newDataSet,
        options,
      });
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }

    if (!error) {
      notify({
        type: 'success',
        message: 'Record successfully saved!',
      });

      handleClosePopupBox();
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <h1>{`${data?.docId ? 'Edit' : 'New'} Brand`}</h1>

      <p className='remarks'>* must be inputed</p>

      {error && <ResultMessageBox type='error' messages={error} />}

      <form onSubmit={handleFormSubmit}>
        <div className='paper'>
          <div className='formGroup'>
            <label>
              <h4>Brand Short Code *</h4>
              <TextInput
                type='text'
                required
                value={code || ''}
                onChange={(e) => setCode(e.target.value)}
              />
            </label>
          </div>

          <div className='formGroup'>
            <CheckBox
              label='Highlight this brand (If YES, this brand will show on top of brand list)'
              checked={isHighlight}
              onChange={() => setIsHighlight(!isHighlight)}
            />
          </div>

          <div className='formGroup'>
            <CheckBox
              label='Disabled (Hidden this brand)'
              checked={isDisable}
              onChange={() => setIsDisable(!isDisable)}
            />
          </div>
        </div>

        <div className='paper'>
          <h4>Content - English</h4>

          <div className='formGroup'>
            <label>
              Name *
              <TextInput
                type='text'
                required
                value={nameEN || ''}
                onChange={(e) => setNameEN(e.target.value)}
              />
            </label>
          </div>
        </div>

        <div className='paper'>
          <h4>Content - 繁體中文</h4>

          <div className='formGroup'>
            <label>
              Name
              <TextInput
                type='text'
                value={nameTC || ''}
                onChange={(e) => setNameTC(e.target.value)}
              />
            </label>
          </div>
        </div>

        <div className='paper'>
          <h4>Content - 简体中文</h4>

          <div className='formGroup'>
            <label>
              Name
              <TextInput
                type='text'
                value={nameSC || ''}
                onChange={(e) => setNameSC(e.target.value)}
              />
            </label>
          </div>
        </div>

        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          <Button
            type='submit'
            text='Save'
            size='sm'
            color='secondary'
            fontWeight='bold'
          />

          <Button
            type='button'
            text='Close'
            size='sm'
            color='grey'
            fontWeight='bold'
            onClick={handleClosePopupBox}
          />
        </div>
      </form>
    </div>
  );
}
