import { useContext, createContext } from "react";
import useGetDbDoc from './../hooks/useGetDbDoc';

const RangesContext = createContext();

export function useRanges() {
  return useContext(RangesContext);
}

export default function RangesProvider({ children }) {
  const { data: ranges } = useGetDbDoc({ collection: 'ranges', docId: '001' });

  const value = {
    ranges
    // searchKeyword,
    // setSearchKeyword
  };

  return (
    <RangesContext.Provider value={value}>
      {children}
    </RangesContext.Provider>
  );
}