import { useAuth } from '../../../contexts/auth.context';
import { useLang } from '../../../contexts/lang.context';

const text = {
  PAGE_TITLE: {
    langTC: '帳戶主頁',
    langSC: '帐户主页',
    langEN: 'DASHBOARD',
  },
  USER_NAME: {
    langTC: '帳戶',
    langSC: '帐户',
    langEN: 'User',
  },
  EMAIL: {
    langTC: '帳戶電郵',
    langSC: '帐户电邮',
    langEN: 'User Email',
  },
};

export default function UserDashboard() {
  const { currentUser } = useAuth();
  const { lang } = useLang();

  return (
    <div id='pageMyAccountDashboard' className='container'>
      <div className='row'>
        <h1 className='pageTitle'>{text.PAGE_TITLE[`lang${lang}`]}</h1>

        {/* <div>{`${text.userName[`lang${lang}`]}: ${currentUser.email}`}</div> */}
        <div>{`${text.EMAIL[`lang${lang}`]}: ${currentUser.email}`}</div>
      </div>
    </div>
  );
}
