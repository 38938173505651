import styled from 'styled-components';
import NavList from './NavList';
import Header from './Header';

const navPayments = {
  title: 'PAYMENTS',
  links: [{ text: 'Payment Transactions', path: 'transactions' }],
};

const navProducts = {
  title: 'PRODUCT INFO.',
  links: [
    { text: 'Brands', path: 'brands' },
    { text: 'Ranges', path: 'ranges' },
    { text: 'Units', path: 'units' },
    { text: 'Products', path: 'products' },
  ],
};

const navBanners = {
  title: 'BANNERS',
  links: [
    { text: 'Slide Banners', path: 'slideBanners' },
    { text: 'Adv. Banners', path: 'advBanners' },
  ],
};

const navPages = {
  title: 'PAGE CONTENTS',
  links: [
    { text: 'News', path: 'news' },
    // { text: 'Advertisement', path: 'advertisements' },
    // { text: 'Banners', path: 'banners' },
    { text: 'About Us', path: 'aboutus' },
    { text: 'Delivery Method', path: 'deliverymethod' },
    { text: 'Privacy Policy', path: 'privacypolicy' },
    { text: 'Return Policy', path: 'returnpolicy' },
    { text: 'Terms of Service', path: 'termsconditions' },
    // { text: 'Notice of Disclaimer', path: 'disclaimer' },
    // { text: 'About Delivery', path: 'aboutdelivery' },
    // { text: 'Copyright Information', path: 'copyrightinfo' },
  ],
};

const navSystem = {
  title: 'SYSTEM',
  links: [
    { text: 'Member Accounts(coming)', path: 'users' },
    { text: 'Enquiry Messages(coming)', path: 'enquiries' },
    { text: 'Contact Info.(coming)', path: 'contactinfo' },
    { text: 'Security', path: 'settings' },
  ],
};

export default function SideBar() {
  return (
    <Container>
      <Header />
      <NavList nav={navPayments} />
      <NavList nav={navProducts} />
      <NavList nav={navBanners} />
      <NavList nav={navPages} />
      <NavList nav={navSystem} />
    </Container>
  );
}

const Container = styled.div`
  width: 200px;
  height: 100vh;
  position: sticky;
  top: 0;
  left: -200px;
  background-color: #aa211d;
  overflow-y: auto;
  transform: all 0.3s;

  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0);

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-corner {
  }
`;
