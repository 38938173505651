import { useState } from 'react';
import useListenDbList from '../../../hooks/useListenDbList';
import Button from '../../../components/form/Button';
import LoadingSpin from '../../../components/LoadingSpin';
import PopupBox from '../../../components/PopupBox';
import Editor from '../../../components/admin/editors/EditTermsConditions';

export default function TermsConditions() {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [editorData, setEditorData] = useState(null);
  const { data, error, isLoading } = useListenDbList({
    collection: 'termsConditions',
  });

  function handleEditItem() {
    setEditorData(data[0]);
    setIsShowPopup(true);
  }

  function handleClosePopupBox() {
    setIsShowPopup(false);
  }

  return (
    <div className='container-full'>
      <div className='row'>
        <h1>Terms Of Service</h1>
        <div>
          <Button
            text='Edit'
            size='xs'
            color='secondary'
            fontWeight='bold'
            onClick={handleEditItem}
          />
        </div>
      </div>

      {isLoading ? (
        <LoadingSpin />
      ) : (
        <>
          <div className='row'>
            <div className='paper' style={{ fontSize: '1.4rem' }}>
              <div className='mb-1'>繁體中文</div>
              <div className='splitter mb-1' />
              <div
                dangerouslySetInnerHTML={{ __html: data[0]?.content?.langTC }}
              />
            </div>

            <div className='paper' style={{ fontSize: '1.4rem' }}>
              <div className='mb-1'>简体中文</div>
              <div className='splitter mb-1' />
              <div
                dangerouslySetInnerHTML={{ __html: data[0]?.content?.langSC }}
              />
            </div>

            <div className='paper' style={{ fontSize: '1.4rem' }}>
              <div className='mb-1'>English</div>
              <div className='splitter mb-1' />
              <div
                dangerouslySetInnerHTML={{ __html: data[0]?.content?.langEN }}
              />
            </div>
          </div>

          {isShowPopup && (
            <PopupBox
              handleClosePopupBox={handleClosePopupBox}
              backdropClickable={false}
            >
              <Editor
                data={editorData}
                handleClosePopupBox={handleClosePopupBox}
              />
            </PopupBox>
          )}
        </>
      )}
    </div>
  );
}
