import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ProductList from '../../components/productList';
import PopupCard from '../../components/popupCard';
import productsData from '../../data/products';
import Button from '../../components/form/Button';
import './styles.scss';
import { NavHashLink } from 'react-router-hash-link';

export default function Brands() {
  let { brand } = useParams();
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [noOfProducts, setNoOfProducts] = useState(10);
  const [data, setData] = useState(null);
  const [showPopupCard, setShowPopupCard] = useState(false);
  const [selectedProduct, setSelectProduct] = useState(null);

  useEffect(() => {
    let filteredProducts = null;
    if (brand) {
      filteredProducts = productsData.filter((product) => {
        return product.brand === brand;
      });
      setFilteredProducts(filteredProducts);
    } else {
      // filteredProducts = productsData;
    }
  }, [brand]);

  useEffect(() => {
    // console.log('filteredProducts', filteredProducts);

    filteredProducts && setData(filteredProducts.slice(0, noOfProducts));
  }, [filteredProducts, noOfProducts]);

  return (
    <div id='pageBrands' className='container'>
      <div className='row'>
        <div className='brandList'>
          <NavHashLink to='/brands/AEI Cable' activeClassName='selected'>
            AEI Cable
          </NavHashLink>
          <span> &nbsp;&nbsp;/&nbsp;&nbsp;</span>
          <NavHashLink to='/brands/KME' activeClassName='selected'>
            KME
          </NavHashLink>
          <span> &nbsp;&nbsp;/&nbsp;&nbsp;</span>
          <NavHashLink to='/brands/EVCCO' activeClassName='selected'>
            EVCCO
          </NavHashLink>
          <span> &nbsp;&nbsp;/&nbsp;&nbsp;</span>
          <NavHashLink to='/brands/UNEX' activeClassName='selected'>
            UNEX
          </NavHashLink>
          <span> &nbsp;&nbsp;/&nbsp;&nbsp;</span>
          <NavHashLink to='/brands/CAVICEL' activeClassName='selected'>
            CAVICEL
          </NavHashLink>
          <span> &nbsp;&nbsp;/&nbsp;&nbsp;</span>
          <NavHashLink to='/brands/VICHNET' activeClassName='selected'>
            VICHNET
          </NavHashLink>
        </div>
      </div>

      <div className='row'>
        {/* <div className='sectionHeader'>
          <span className='sectionHeader__title'>Brand: {brand}</span>
        </div> */}

        {data && (
          <>
            <ProductList
              data={data}
              setShowPopupCard={setShowPopupCard}
              setSelectProduct={setSelectProduct}
            />

            {filteredProducts.length > noOfProducts && (
              <div className='loadMoreWrapper'>
                <Button
                  color='secondary'
                  size='lg'
                  fontWeight='bold'
                  text='Load More...'
                  onClick={() => setNoOfProducts((prevState) => prevState + 10)}
                />
              </div>
            )}
          </>
        )}
      </div>

      {selectedProduct && (
        <PopupCard
          show={showPopupCard}
          product={selectedProduct}
          setShowPopupCard={setShowPopupCard}
        />
      )}
    </div>
  );
}
