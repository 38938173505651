import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCart } from '../../contexts/cart.context';
import { useLang } from './../../contexts/lang.context';
import CartList from '../../components/CartList';
import Button from '../../components/form/Button';
import text from './text';
import './styles.scss';

export default function Cart() {
  const history = useHistory();
  const { cart } = useCart();
  const { lang } = useLang();
  const [isShowFail, setIsShowFail] = useState(false);
  // const [tempCart, setTempCart] = useState(cart);

  function checkProductStock() {
    // setTempCart(cart);
    // console.log('tempCart', tempCart);
    let failedItems = 0;

    cart.forEach((item) => {
      if (item.stock > 0 && item.stock < item.qty) {
        failedItems++;
      }
    });

    // console.log('failedItems', failedItems);
    if (failedItems > 0) {
      setIsShowFail(true);
      return;
    }

    history.push('/checkout');
  }

  return (
    <div id='pageCart' className='container'>
      <div className='row'>
        <h1 className='pageTitle'>{text.PAGE_TITLE[`lang${lang}`]}</h1>

        <CartList data={cart} isShowFail />
      </div>

      {cart && cart.length > 0 && (
        // Cart footer
        <div className='row'>
          <div className='btnContainer'>
            <Button
              size='md'
              color='secondary'
              fontWeight='bold'
              onClick={() => history.push('/products?promotion=true')}
            >
              {text.CONTINUOUS_SHOPPING[`lang${lang}`]}
            </Button>

            <Button
              size='md'
              color='secondary'
              fontWeight='bold'
              onClick={checkProductStock}
              // onClick={() => history.push('/checkout')}
            >
              {text.CHECK_OUT[`lang${lang}`]}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
