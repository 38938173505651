import { useState } from 'react';
import styled from 'styled-components';

import NavTitle from './NavTitle';
import NavItem from './NavItem';

export default function LinksList({ nav }) {
  const [isActive, setIsActive] = useState(false);

  function toggleSubMenu() {
    setIsActive((prevState) => !prevState);
  }

  return (
    <Container>
      <NavTitle title={nav.title} toggleSubMenu={toggleSubMenu} />

      {isActive && (
        <>
          {nav.links.map((link, index) => {
            return <NavItem key={index} link={link} />;
          })}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
