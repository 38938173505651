import { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../data/firestore/config';
import Waiting from '../pages/Waiting';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  // Monitoring user login status
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      setCurrentUser(user);

      const isAdmin = await checkUserIsAdmin(user?.uid);
      setUserIsAdmin(isAdmin);

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  // Check current user is admin
  async function checkUserIsAdmin(userId) {
    let isAdmin = false;

    const checkIsAdmin = async () => {
      if (userId) {
        await db.collection('users')
          .doc(userId)
          .get()
          .then(doc => {
            if (doc.exists) {
              isAdmin = doc.data().roles.includes('admin');
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    };

    await checkIsAdmin();

    return isAdmin;
  }

  // Sign up new user account
  function signUp(email, password) {
    return new Promise(async (resolve, reject) => {
      await auth.createUserWithEmailAndPassword(email, password)
        .then(userCredential => {
          resolve({ uid: userCredential.user.uid });
        })
        .catch((error) => {
          console.log('Sign up error:', error.message);
          reject(new Error(error));
        });
    });
  }

  // Sign in user account
  function signIn(email, password) {
    return new Promise(async (resolve, reject) => {
      await auth.signInWithEmailAndPassword(email, password)
        .then(userCredential => {
          resolve(userCredential);
        })
        .catch((error) => {
          console.log('Sign in error:', error.message);
          reject(new Error(error));
        });
    });
  };

  // Sign out user account
  function signOut() {
    return auth.signOut();
  };

  // Change user login password
  function updatePassword(newPassword) {
    return new Promise(async (resolve, reject) => {
      await currentUser.updatePassword(newPassword)
        .then(() => {
          resolve('Update successful.');
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  function sendResetPassword(email) {
    return new Promise(async (resolve, reject) => {
      await auth.sendPasswordResetEmail(email)
        .then(() => {
          resolve('Password reset email sent!');
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  const value = {
    currentUser,
    userIsAdmin,
    signUp,
    signIn,
    signOut,
    updatePassword,
    sendResetPassword,
    checkUserIsAdmin
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? <Waiting /> : children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;