import { useState, useRef } from 'react';
import Recaptcha from 'react-recaptcha';
import { v4 as uuidv4 } from 'uuid';

import { useLang } from './../../contexts/lang.context';
import Button from '../../components/form/Button';
import TextInput from '../../components/form/TextInput';
import TextArea from '../../components/form/TextArea';
import sendEnquiryEmail from '../../utils/sendEnquiryEmail';
import setDbData from './../../utils/setDbData';
import text from './text';
import './styles.scss';
import ResultMessageBox from './../../components/ResultMessageBox/index';

export default function Contact() {
  const { lang } = useLang();
  const divRef = useRef();
  const formRef = useRef();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  // const [locationTelCode, setLocationTelCode] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [tel, setTel] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState('');
  const [response, setResponse] = useState({ type: '', messages: '' });

  const recaptchaLoaded = () => {
    // console.log('recaptcha loaded');
  };

  const verifyCallback = (response) => {
    if (response) {
      // console.log(response);
      setIsVerified(true);
    }
  };

  function resetResponse() {
    setResponse(null);
  }

  async function handleFormSubmit(e) {
    e.preventDefault();

    // setError('');
    resetResponse();
    setIsLoading(true);

    if (!isVerified) {
      setResponse({
        type: 'warning',
        messages: text.RECAPTCHA_FAILED?.[`lang${lang}`],
      });
      setIsLoading(false);
      return divRef.current.scrollIntoView();
    }

    const uid = uuidv4();

    const formData = {
      uid,
      firstName,
      lastName,
      email,
      tel,
      companyName,
      message,
      createAt: new Date(),
    };

    const dbData = {
      collection: 'enquiryMessage',
      docId: uid,
      data: formData,
    };

    try {
      // Save form data to database
      await setDbData(dbData);

      // Send out email
      await sendEnquiryEmail(formData);

      console.log('Send contact message successed!');
      setResponse({
        type: 'success',
        messages: text.SEND_MESSAGE_SUCCESSED?.[`lang${lang}`],
      });

      // Reset form
      formRef.current.reset();
    } catch (error) {
      console.log(error.message);
      setResponse({
        type: 'error',
        messages: text.SEND_MESSAGE_FAILED?.[`lang${lang}`],
      });
      return divRef.current.scrollIntoView();
    } finally {
      setIsLoading(false);
      return divRef.current.scrollIntoView();
    }
  }

  return (
    <div id='pageContact' className='container'>
      <div className='row'>
        <h1 className='pageTitle'>{text.PAGE_TITLE[`lang${lang}`]}</h1>

        <div className='formWrapper' ref={divRef}>
          {response?.messages && (
            <ResultMessageBox
              type={response.type}
              messages={response.messages}
            />
          )}

          <form onSubmit={handleFormSubmit} ref={formRef}>
            <div className='remarks'>
              {text.MUST_BE_COMPLETED[`lang${lang}`]}
            </div>

            <h3>{text.CONTACT_INFO[`lang${lang}`]}</h3>

            <div className='formGroup'>
              <TextInput
                type='text'
                placeholder={`${text.FIRST_NAME[`lang${lang}`]} *`}
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                required
              />
              <TextInput
                type='text'
                placeholder={`${text.LAST_NAME[`lang${lang}`]} *`}
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                required
              />
            </div>

            <div className='formGroup'>
              <TextInput
                type='email'
                placeholder={`${text.EMAIL[`lang${lang}`]} *`}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
            </div>

            <div className='formGroup'>
              {/* <div style={{ width: '120px' }}>
                <TextInput
                  type='text'
                  placeholder='+852'
                  onChange={(e) => setLocationTelCode(e.target.value)}
                  value={locationTelCode}
                />
              </div> */}
              <TextInput
                type='tel'
                placeholder={text.TEL[`lang${lang}`]}
                onChange={(e) => setTel(e.target.value)}
                value={tel}
              />
            </div>

            <br />

            <h3>{text.COMPANY_NAME[`lang${lang}`]}</h3>

            <div className='formGroup'>
              <TextInput
                type='text'
                placeholder={`${text.COMPANY_NAME2[`lang${lang}`]}`}
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName}
              />
            </div>

            <br />

            <h3>{text.MESSAGE[`lang${lang}`]}</h3>

            <div className='formGroup'>
              {/* <textarea rows='10' onChange={(e) => setMessage(e.target.value)}>
                {message}
              </textarea> */}
              <TextArea onChange={(e) => setMessage(e.target.value)}>
                {message}
              </TextArea>

              {/* <TextInput
                type='tel'
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              /> */}
            </div>

            <br />

            <div className='formGroup'>
              <div className='recaptcha'>
                <Recaptcha
                  sitekey='6LeeNlocAAAAACefFS5vvWLowerLqD59vupYPgl9'
                  render='explicit'
                  onloadCallback={recaptchaLoaded}
                  verifyCallback={verifyCallback}
                />
              </div>
            </div>

            <div className='formGroup'>
              <Button
                type='submit'
                variant='contained'
                color='secondary'
                size='sm'
                fontWeight='bold'
                text={text.SUBMIT[`lang${lang}`]}
                isLoading={isLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
