import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useWindowSize from '../../hooks/useWindowSize';
import { useLang } from './../../contexts/lang.context';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 250px;
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-color: #ddd;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  cursor: pointer;

  .btnMore {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: white;
    cursor: pointer;
  }
`;

export default function BoxSpecialOffer() {
  const history = useHistory();
  const { lang } = useLang();
  const { mediaMode } = useWindowSize();

  const text = {
    MORE: {
      langTC: '更多',
      langSC: '更多',
      langEN: 'MORE',
    },
  };

  function specialOfferImg() {
    switch (mediaMode) {
      case 'desktop':
      case 'desktop-large':
        return 'https://res.cloudinary.com/ebasell/image/upload/v1653472089/misc/specialoffer.jpg';
      case 'tablet':
        return 'https://res.cloudinary.com/ebasell/image/upload/v1653472274/misc/specialoffer-md_nne3ws.jpg';
      case 'mobile':
        return 'https://res.cloudinary.com/ebasell/image/upload/v1653472089/misc/specialoffer.jpg';
      default:
        return 'https://res.cloudinary.com/ebasell/image/upload/v1631446629/misc/specialoffer.jpg';
    }
  }

  return (
    <Wrapper
      backgroundImage={specialOfferImg()}
      onClick={() => history.push('/products?promotion=true')}
    >
      <span className='btnMore'>{text.MORE[`lang${lang}`]}&nbsp;&gt;</span>
    </Wrapper>
  );
}
