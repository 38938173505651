import { useLang } from './../../contexts/lang.context';
import { useCart } from '../../contexts/cart.context';
import CartList from '../../components/CartList';
import StripeContainer from '../../components/stripeContainer';
import './styles.scss';

const text = {
  PAGE_TITLE: {
    langTC: '付款',
    langSC: '付款',
    langEN: 'CHECK OUT',
  },
};

export default function CheckOut() {
  const { lang } = useLang();
  const { cart } = useCart();

  return (
    <div id='pageCheckOut' className='container'>
      <div className='row'>
        <h1 className='pageTitle'>{text.PAGE_TITLE[`lang${lang}`]}</h1>

        <div className='cartWrapper'>
          <CartList data={cart} mode='read' />
        </div>
      </div>

      <div className='splitter mt-2 mb-2' />

      <div className='row'>
        <StripeContainer />
      </div>
    </div>
  );
}
