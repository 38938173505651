import styled, { css } from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export default function Button({
  children,
  variant = 'contained',
  color = 'primary', // *primary
  size = 'full', // *full | sm | md | lg
  fontWeight = 'normal',
  text,
  onClick,
  disabled = false,
  isLoading = false,
  ...rest
}) {
  return (
    <Wrapper size={size}>
      {isLoading && (
        <LoadingWrapper>
          <CircularProgress size={22} style={{ color: 'white' }} />
        </LoadingWrapper>
      )}
      <StyledButton
        variant={variant}
        color={color}
        fontWeight={fontWeight}
        onClick={onClick}
        disabled={disabled || isLoading}
        isLoading={isLoading}
        {...rest}
      >
        {children || text}
      </StyledButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;

  /* width */
  width: 100%;
  max-width: ${({ size }) => {
    switch (size) {
      case 'xs':
        return '100px';
      case 'sm':
        return '150px';
      case 'md':
        return '250px';
      case 'lg':
        return '400px';
      default:
        return '100%';
    }
  }};
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const StyledButton = styled.button`
  padding: 6px 1px;
  width: 100%;
  min-width: 80px;
  text-align: center;
  font-size: 1.3rem;
  transition: all 0.2s;
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  font-weight: ${({ fontWeight }) => fontWeight};
  cursor: ${({ disabled, isLoading }) =>
    disabled ? 'not-allowed' : isLoading ? 'progress' : 'pointer'};

  /* text color */
  color: ${({ color }) => {
    return `var(--color-${color}-dark)`;
  }};

  /* border-color */
  border-color: ${({ color }) => `var(--color-${color})`};

  /* background-color */
  background-color: ${({ variant, color }) =>
    variant === 'contained' ? `var(--color-${color})` : `transparent`};

  /* hover */
  &:hover {
    border-color: ${({ color }) => `var(--color-${color}-dark)`};
    background-color: ${({ color }) => `var(--color-${color}-dark)`};
    color: white;
  }
`;
