import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Recaptcha from 'react-recaptcha';

import { useAuth } from '../../../contexts/auth.context';
import TextInput from '../../../components/form/TextInput';
import Button from '../../../components/form/Button';
import imgLogo from '../../../assets/img/ebasell-logo.svg';
import './styles.scss';

export default function SignIn() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { signIn, currentUser, userIsAdmin, checkUserIsAdmin } = useAuth();

  if (currentUser && userIsAdmin) history.push('/admin');

  const recaptchaLoaded = () => {
    // console.log('recaptcha loaded');
  };

  const verifyCallback = (response) => {
    if (response) {
      // console.log(response);
      setIsVerified(true);
    }
  };

  async function handleFormSubmit(e) {
    e.preventDefault();

    setError('');
    setIsLoading(true);

    if (isVerified) {
      try {
        const userCredential = await signIn(email, password);
        // console.log('userCredential', userCredential.user.uid);
        // console.log({ email: currentUser.email, userIsAdmin: userIsAdmin });

        const isAdmin = checkUserIsAdmin(userCredential.user.uid);
        // console.log('admin signin - isAdmin', isAdmin);

        if (isAdmin) {
          history.push('/admin');
          // console.log('user is admin');
        } else {
          // console.log('user is user');
          setError(
            'Error: Login email and password incorrect, or you have no premission to login.',
          );
        }
      } catch (error) {
        setError(`Error: ${error.message}`);
        // return formRef.current.scrollIntoView();
      }
    }

    setIsLoading(false);
  }

  return (
    <div id='pageAdminSignIn'>
      <div className='formWrapper'>
        <form onSubmit={handleFormSubmit}>
          <div className='logo'>
            <img src={imgLogo} alt='' />
          </div>

          {error && <div className='messageBox'>{error}</div>}

          <div className='formGroup'>
            <TextInput
              type='email'
              placeholder={`Email`}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              autoFoucs
            />
          </div>

          <div className='formGroup'>
            <TextInput
              type='password'
              placeholder={`Password`}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
          </div>

          <div className='formGroup'>
            <div className='recaptcha'>
              <Recaptcha
                sitekey='6LeeNlocAAAAACefFS5vvWLowerLqD59vupYPgl9'
                render='explicit'
                onloadCallback={recaptchaLoaded}
                verifyCallback={verifyCallback}
              />
            </div>
          </div>

          <div className='formGroup'>
            <Button
              type='submit'
              variant='contained'
              color='secondary'
              size='sm'
              fontWeight='bold'
              text='LOGIN'
              isLoading={isLoading}
            />
          </div>
        </form>
        <div>
          Don't have an account?&nbsp;
          <HashLink to='/'>Back to website</HashLink>.
        </div>
      </div>
    </div>
  );
}
