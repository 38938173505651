import { useState, useEffect } from 'react';
import { db } from '../data/firestore/config';

export default function useGetDbDoc({ collection, docId }) {
  const [data, setData] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const unsubscrib = db
      .collection(collection)
      .doc(docId)
      .get()
      .then(doc => {
        if (doc.exists) {
          setData(doc.data());
        } else {
          setFetchError('No such document!');
        }
        setIsFetching(false);
      })
      .catch(error => {
        console.log(error.message);
        setFetchError(error);
        setIsFetching(false);
      });

    return unsubscrib;
  }, [collection, docId]);

  return { data, fetchError, isFetching };
}
