import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

import useListenDbDoc from '../../../hooks/useListenDbDoc';
import Button from '../../../components/form/Button';
import LoadingSpin from '../../../components/LoadingSpin';
import PopupBox from '../../../components/PopupBox';
import Editor from '../../../components/admin/editors/EditSlideBanners';
import { setDbData } from '../../../utils';

const columns = [
  {
    name: 'Edit',
    selector: (row) => row.btnEdit,
    maxWidth: '40px',
  },
  {
    name: 'Seq.',
    selector: (row) => row.seq,
    maxWidth: '30px',
  },
  {
    name: 'Move',
    selector: (row) => row.btnMoveUpDown,
    maxWidth: '50px',
  },
  {
    name: 'Image',
    selector: (row) => (
      <img src={`${row?.imageLgUrl}`} style={{ height: '50px' }} alt='' />
    ),
    sortable: false,
  },
  {
    name: 'Delete',
    selector: (row) => row.btnDelete,
    maxWidth: '40px',
  },
];

export default function SlideBanners() {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editorData, setEditorData] = useState(null);
  const [index, setIndex] = useState(-1);
  const { data, fetchError, isFetching } = useListenDbDoc({
    collection: 'slideBanners',
    docId: '001',
  });

  useEffect(() => {
    if (isFetching) return;

    let tempData = [];
    setIsLoading(true);

    const noOfItems = data.values.length;

    data.values.forEach((doc, index) => {
      tempData.push({
        ...doc,
        seq: index + 1,
        btnEdit: createEditButton({ doc, index }),
        btnMoveUpDown: createMoveUpDownButton({ index, noOfItems }),
        btnDelete: createDeleteButton(index),
      });
    });

    setTableData(tempData);
    setIsLoading(false);
  }, [data, isFetching]);

  function createEditButton({ data: doc, index }) {
    return (
      <EditIcon
        fontSize='large'
        className='clickable'
        // color=''
        style={{ color: '#33a35e' }}
        onClick={() => handleEditItem({ data, index })}
      />
    );
  }

  function createMoveUpDownButton({ index, noOfItems }) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: '22px' }}>
          {index > 0 && (
            <KeyboardArrowUpRoundedIcon
              fontSize='large'
              className='clickable'
              style={{ color: '#33a35e' }}
              onClick={() => handleMoveUp(index)}
            />
          )}
        </div>

        <div style={{ width: '22px' }}>
          {noOfItems > 0 && index < noOfItems - 1 && (
            <KeyboardArrowDownRoundedIcon
              fontSize='large'
              className='clickable'
              style={{ color: '#33a35e' }}
              onClick={() => handleMoveDown(index)}
            />
          )}
        </div>
      </div>
    );
  }

  function createDeleteButton(index) {
    return (
      <DeleteIcon
        fontSize='large'
        className='clickable'
        color='error'
        onClick={() => handleDeleteItem(index)}
      />
    );
  }

  function handleAddNewItem(data) {
    setEditorData(data);
    setIndex(-1);
    setIsShowPopup(true);
  }

  function handleEditItem({ data, index }) {
    setEditorData(data);
    setIndex(index);
    setIsShowPopup(true);
  }

  function handleMoveUp(index) {
    if (!index || index === 0) return;

    const temp = data.values[index - 1];
    data.values[index - 1] = data.values[index];
    data.values[index] = temp;

    try {
      setDbData({
        collection: 'slideBanners',
        docId: '001',
        data: data,
      });
    } catch (error) {
      // setError(error.message);
    } finally {
      // setIsLoading(false);
    }
  }

  function handleMoveDown(index) {
    const temp = data.values[index + 1];
    data.values[index + 1] = data.values[index];
    data.values[index] = temp;

    try {
      setDbData({
        collection: 'slideBanners',
        docId: '001',
        data: data,
      });
    } catch (error) {
      // setError(error.message);
    } finally {
      // setIsLoading(false);
    }
  }

  function handleDeleteItem(index) {
    if (index <= -1) return;

    const confirmBox = window.confirm(`Do you want to delete this item?`);
    if (confirmBox) {
      data.values.splice(index, 1);

      try {
        setDbData({
          collection: 'slideBanners',
          docId: '001',
          data: data,
        });
      } catch (error) {
        // setError(error.message);
      } finally {
        // setIsLoading(false);
      }
    }
  }

  function handleClosePopupBox() {
    setIsShowPopup(false);
  }

  return (
    <div className='container-full'>
      <div className='row'>
        <h1>Slide Banners</h1>
        <div>
          <Button
            text='Add New Banner'
            size='sm'
            color='secondary'
            fontWeight='bold'
            onClick={() => handleAddNewItem(data)}
          />
        </div>
      </div>

      {!isLoading && !isFetching ? (
        <div className='row'>
          <div className='paper'>
            <DataTable
              columns={columns}
              data={tableData}
              pagination
              defaultSortFieldId={1}
              progressPending={isLoading}
            />
          </div>
        </div>
      ) : (
        <LoadingSpin />
      )}

      {isShowPopup && (
        <PopupBox
          handleClosePopupBox={handleClosePopupBox}
          backdropClickable={false}
        >
          <Editor
            data={editorData}
            index={index}
            handleClosePopupBox={handleClosePopupBox}
          />
        </PopupBox>
      )}
    </div>
  );
}
