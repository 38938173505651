import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import { useCart } from '../../contexts/cart.context';
import { useLang } from './../../contexts/lang.context';
import { useAuth } from './../../contexts/auth.context';
import TextInput from '../form/TextInput';
import Button from '../form/Button';
import { setDbData } from './../../utils';
import { db } from '../../data/firestore/config';
import { HashLink } from 'react-router-hash-link';
import Dropdown from './../form/Dropdown/index';
import sendOrderConfirmToCustomer from '../../utils/sendOrderConfirmToCustomer';
import sendOrderConfirmToAdmin from '../../utils/sendOrderConfirmToAdmin';
import iconCreditCards from './../../assets/img/icon-creditcards.jpg';
import iconFps from './../../assets/img/icon-fps.jpg';
import countries from '../../data/deliveryCountries.json';
import text from './text';

const cardOptions = {
  hidePostalCode: true,
  style: {
    base: {
      fontSize: '16px',
      // lineHeight: '2.4',
      borderColor: '#000000',
      backgroundColor: '#fff',
      color: '#000000',
      letterSpacing: '0.025em',
      fontSmoothing: 'antialiased',
      fontFamily: 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

export default function PaymentForm() {
  const history = useHistory();
  const { lang } = useLang();
  const { cart, cartDispatch, cartActions } = useCart();
  const { currentUser } = useAuth();
  const [deliveryMethod, setDeliveryMethod] = useState('SelfCollect');
  const [deliveryCountry, setDeliveryCountry] = useState(null);
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressLine3, setAddressLine3] = useState('');
  const [contactName, setContactName] = useState('');
  const [locationTelCode, setLocationTelCode] = useState('');
  const [tel, setTel] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('bankin');
  const [billingName, setBillingName] = useState('');
  // const [isLoading,setIsLoading]=useState(false)

  // Stripe status
  const [isSucceeded, setIsSucceeded] = useState(false);
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');

  // temp remark
  const stripe = useStripe();
  const elements = useElements();

  const freeShippingAmount = 1000;
  const cartTotalAmount = cart.reduce(
    (total, item) => total + item.qty * item.unitPrice,
    0,
  );

  let quickError = false;
  let quickErrorMessage = '';
  let quickPaymentSuccess = false;

  async function handleSubmit(e) {
    e.preventDefault();

    resetStatus();

    const transactionId = uuidv4();
    const transactionData = {
      transactionId,
      userId: currentUser.uid,
      createdAt: Date.now(),
      deliveryMethod,
      deliveryCountry,
      deliveryAddressLine1: addressLine1,
      deliveryAddressLine2: addressLine2,
      deliveryAddressLine3: addressLine3,
      contactName: contactName,
      // locationTelCode: locationTelCode,
      tel,
      billingName,
      email: currentUser.email,
      orderItems: cart,
      currency: 'hkd',
      amount: cartTotalAmount,
      deliveryStatus: '',
      paymentDetails: {
        paymentType: paymentMethod === 'bankin' ? 'bankin' : 'card',
        paymentStatus: paymentMethod === 'bankin' ? 'bankin' : 'pending',
      },
    };

    // Save order data to db, payment status is pending
    saveOrder(transactionData);

    // Connect to stripe to get client secret string
    let paymentIntent = {};
    if (paymentMethod === 'card') {
      // console.log('connect to stripe to get client secret string...');

      if (!quickError) {
        const stripeTransactionData = {
          statementDescriptor: 'ebasell',
          description: `Order Transaction ID: ${transactionId}`,
          amount: cartTotalAmount * 100,
          currency: 'hkd',
        };

        paymentIntent = await createPaymentIntent(stripeTransactionData);
      }

      // Send card details to stripe to process payment
      // console.log('paymentIntent', paymentIntent.clientSecret);
      if (!quickError && paymentIntent.clientSecret) {
        await sendCardDetails(paymentIntent.clientSecret);
      }
    }

    // Update order payment status
    if (!quickError) {
      // Update local data
      transactionData.paymentDetails.paymentStatus = 'successed';

      // Update db
      await db
        .collection('transactions')
        .doc(transactionId)
        .set(
          {
            paymentDetails: {
              paymentProvider: paymentMethod === 'card' ? 'stripe' : '',
              paymentId:
                paymentMethod === 'card' ? paymentIntent?.paymentId : '',
              paymentType: paymentMethod === 'card' ? 'card' : 'bankin',
              paymentStatus: paymentMethod === 'card' ? 'successed' : 'bankin',
              // paymentStatus: quickError ? 'failed' : 'successed',
              paymentMessage: quickError ? quickErrorMessage : '',
            },
          },
          {
            merge: true,
          },
        );
    }

    // Send notification emails
    // console.log(transactionData, cart);
    sendOrderConfirmToCustomer({ transactionData, cart });
    // console.log('Order confirmation email sent to customer.');
    sendOrderConfirmToAdmin({ transactionData, cart });
    // console.log('Order notification email sent to admin.');

    // setIsProcessing(false);
    // if (true) return;
    // console.log('send order confirm - transaction data', transactionData);
    // console.log('send order confirm - cart data', cart);

    // End
    setIsProcessing(false);

    // if (!quickError && quickPaymentSuccess) {
    if (!quickError) {
      history.push(`/paymentstatus/${transactionId}`);

      // clear shopping cart
      cartDispatch({ type: cartActions.CLEAR_CART });
    }
  }

  // Reset error and status
  function resetStatus() {
    quickError = false;
    quickErrorMessage = '';
    quickPaymentSuccess = false;

    setError('');
    // setIsSucceeded(false);
    setIsProcessing(true);
  }

  // Save order details into database
  function saveOrder(data) {
    console.log('Saving order...');

    try {
      setDbData({
        collection: 'transactions',
        docId: data.transactionId,
        data,
      });
    } catch (error) {
      quickError = true;
      quickErrorMessage = error.message;
      console.log(error.message);
      setError(error.message);
      return;
    }
  }

  // Connect to stripe api to get client secret
  async function createPaymentIntent(orderData) {
    console.log('creating payment intent...');

    // let paymentId = '';
    // let clientSecret = '';
    let paymentIntent = {};

    await axios
      .post(process.env.REACT_APP_STRIPE_API_URL, orderData)
      .then((res) => {
        paymentIntent = res.data;
        // console.log('payment-res.data', paymentIntent);
        // paymentId = res.data.paymentId;
        // clientSecret = res.data.clientSecret;
      })
      .catch((error) => {
        quickError = true;
        quickErrorMessage = error.message;
        console.log(error);
        setError(error.message);
      });

    const { paymentId, clientSecret } = paymentIntent;

    return { paymentId, clientSecret };
  }

  // Send card details to stripe
  async function sendCardDetails(clientSecret) {
    // console.log('sending card details...');

    if (!clientSecret) return;

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: billingName,
          email: currentUser.email,
        },
      },
    });

    // console.log('send card result', result);

    if (!result.error) {
      quickPaymentSuccess = true;
      // setIsSucceeded(true);
      console.log('Payment successed');
    } else {
      quickError = true;
      quickErrorMessage = result.error.message;
      setError(`${text.PAYMENT_FAILED[`lang${lang}`]} ${result.error.message}`);
      console.log(result.error.message);
    }
  }

  function handleDeliveryMethodChange(e) {
    setDeliveryMethod(e.target.value);
  }

  function handlePaymentMethodChange(e) {
    setPaymentMethod(e.target.value);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='formWrapper'>
        <div>
          <h3>{text.DELIVERY_OPTION[`lang${lang}`]}</h3>

          {cartTotalAmount < freeShippingAmount && (
            <FreeShippingNotice>
              <div>Free shipping on orders over HKD 1,000.00</div>
              <div className='small'>
                Click here to{' '}
                <HashLink to='/products?promotion=true#'>
                  continue shopping
                </HashLink>{' '}
                or <HashLink to='/cart#'>edit the cart</HashLink>.
              </div>
            </FreeShippingNotice>
          )}

          <div className='formGroup'>
            <div>
              <RadioLabel>
                <input
                  type='radio'
                  name='deliveryMethod'
                  value='SelfCollect'
                  checked={deliveryMethod === 'SelfCollect'}
                  onChange={(e) => handleDeliveryMethodChange(e)}
                />
                {`${text.SELF_COLLECT[`lang${lang}`]}`}
              </RadioLabel>
              <RadioLabel>
                <input
                  type='radio'
                  name='deliveryMethod'
                  value='HKStandardDelivery'
                  checked={deliveryMethod === 'HKStandardDelivery'}
                  onChange={(e) => handleDeliveryMethodChange(e)}
                  disabled={cartTotalAmount < freeShippingAmount}
                />
                <span
                  style={{
                    color:
                      cartTotalAmount < freeShippingAmount ? '#999' : '#221F20',
                  }}
                >{`${text.HK_STANDARD_DELIVERY[`lang${lang}`]}`}</span>
              </RadioLabel>
            </div>
          </div>

          <br />

          {deliveryMethod === 'HKStandardDelivery' ? (
            <>
              <h3>{text.DELIVERY_ADDRESS[`lang${lang}`]}</h3>
              <div className='formGroup'>
                <Dropdown
                  options={countries}
                  id='code'
                  label='name'
                  prompt={text.DELIVERY_COUNTRY[`lang${lang}`]}
                  value={deliveryCountry}
                  onChange={(val) => setDeliveryCountry(val)}
                />
              </div>

              <div className='formGroup'>
                <TextInput
                  type='text'
                  placeholder={`${text.ADDRESS_LINE[`lang${lang}`]} 1 *`}
                  onChange={(e) => setAddressLine1(e.target.value)}
                  value={addressLine1}
                  required={deliveryMethod === 'SelfCollect' ? false : true}
                  disabled={deliveryMethod === 'SelfCollect' ? true : false}
                />
              </div>

              <div className='formGroup'>
                <TextInput
                  type='text'
                  placeholder={`${text.ADDRESS_LINE[`lang${lang}`]} 2`}
                  onChange={(e) => setAddressLine2(e.target.value)}
                  value={addressLine2}
                  disabled={deliveryMethod === 'SelfCollect' ? true : false}
                />
              </div>

              <div className='formGroup'>
                <TextInput
                  type='text'
                  placeholder={`${text.ADDRESS_LINE[`lang${lang}`]} 3`}
                  onChange={(e) => setAddressLine3(e.target.value)}
                  value={addressLine3}
                  disabled={deliveryMethod === 'SelfCollect' ? true : false}
                />
              </div>
            </>
          ) : (
            <>
              <h3>{text.SELF_COLLECT_ADDRESS[`lang${lang}`]}</h3>
              <p>
                Unit 1, G/F., Kai Fuk Industrial Centre, 1 Wang Tung Street,
                Kowloon Bay, Kowloon, Hong Kong
              </p>
            </>
          )}

          {/* <br />
          <h3>{text.CONTACT[`lang${lang}`]}</h3>
          <div className='formGroup'>
            <TextInput
              type='text'
              placeholder={`${text.CONTACT_NAME[`lang${lang}`]} *`}
              onChange={(e) => setContactName(e.target.value)}
              value={contactName}
              required
            />
          </div>

          <div className='formGroup'>
            <div style={{ width: '120px' }}>
              <TextInput
                type='text'
                placeholder='+852 *'
                onChange={(e) => setLocationTelCode(e.target.value)}
                value={locationTelCode}
                required
              />
            </div>
            <TextInput
              type='tel'
              placeholder={`${text.PHONE[`lang${lang}`]} *`}
              onChange={(e) => setTel(e.target.value)}
              value={tel}
              required
            />
          </div> */}
        </div>

        <div>
          <h3>{text.CONTACT[`lang${lang}`]}</h3>
          <div className='formGroup'>
            <TextInput
              type='text'
              placeholder={`${text.CONTACT_NAME[`lang${lang}`]} *`}
              onChange={(e) => setContactName(e.target.value)}
              value={contactName}
              required
            />
          </div>

          <div className='formGroup'>
            {/* <div style={{ width: '120px' }}>
              <TextInput
                type='text'
                placeholder='+852 *'
                onChange={(e) => setLocationTelCode(e.target.value)}
                value={locationTelCode}
                required
              />
            </div> */}
            <TextInput
              type='tel'
              placeholder={`${text.TEL[`lang${lang}`]} *`}
              onChange={(e) => setTel(e.target.value)}
              value={tel}
              required
            />
          </div>

          <br />
          <h3>{text.PAYMENT_METHOD[`lang${lang}`]}</h3>

          <div>
            <RadioLabel>
              <input
                type='radio'
                name='paymentMethod'
                value='bankin'
                checked={paymentMethod === 'bankin'}
                onChange={(e) => handlePaymentMethodChange(e)}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '15px',
                }}
              >
                <div>
                  <h4 style={{ padding: 0, margin: 0 }}>FPS</h4>
                </div>
                <div>
                  <img src={iconFps} style={{ height: '46px' }} alt='FPS' />
                </div>
              </div>
            </RadioLabel>

            {paymentMethod === 'bankin' && (
              <div className='mt-1 ml-3'>
                <p>FPS Proxy ID No.: 101694891</p>
                <p>
                  Please check the contact and delivery information is correct
                  then click "Confirm and Submit Order" button. We will send you
                  a confirmation email with order Transaction ID.
                </p>
                <p>
                  After complete your payment, please email the payment record
                  screen(with reference no.) and the order "Transaction ID" to{' '}
                  <a href='mailto:sales@fordexelectric.com'>
                    sales@fordexelectric.com
                  </a>
                  .
                </p>
              </div>
            )}
          </div>

          <RadioLabel>
            <input
              type='radio'
              name='paymentMethod'
              value='card'
              checked={paymentMethod === 'card'}
              onChange={(e) => handlePaymentMethodChange(e)}
            />
            <div>
              <div style={{ display: 'flex', gap: '15px' }}>
                <h4 style={{ padding: 0, margin: 0 }}>Credit Card</h4>
                <img
                  src={iconCreditCards}
                  style={{ height: 16, display: 'inline' }}
                  alt=''
                />
              </div>
            </div>
          </RadioLabel>

          {paymentMethod === 'card' && (
            <div className='mt-1 ml-3'>
              <div className='formGroup'>
                <TextInput
                  type='text'
                  placeholder={`${text.CARD_NAME[`lang${lang}`]} *`}
                  onChange={(e) => setBillingName(e.target.value)}
                  value={billingName}
                  required
                />
              </div>
              <div
                style={{
                  border: '1px solid #58595b',
                  borderRadius: '5px',
                  padding: '8px 10px',
                }}
              >
                <CardElement options={cardOptions} />
              </div>
            </div>
          )}

          <div className='btnContainer'>
            <Button
              type='submit'
              size='full'
              color='secondary'
              fontWeight='bold'
              text={text.CONFIRM_ORDER[`lang${lang}`]}
              isLoading={isProcessing}
            />
          </div>

          {error && <AlertBox>{error}</AlertBox>}

          {/* <TempNotice>
            <div style={{ fontWeight: 'bold' }}>測試用信用卡</div>
            <div>Visa卡號：4242 4242 4242 4242</div>
            <div>Mastercard卡號：5555 5555 5555 4444</div>
            <div>銀聯卡號：6200 0000 0000 0005</div>
            <div>卡到期日(月月/年年)：任何未來日期</div>
            <div>卡安全碼：任何三位數字</div>
          </TempNotice> */}
        </div>
      </div>
    </form>
  );
}

const TempNotice = styled.div`
  border: 2px solid #ccc;
  width: 100%;
  padding: 10px 20px;

  div {
    padding: 5px 0;
    color: #666;
  }
`;

const AlertBox = styled.div`
  background-color: var(--color-warning);
  color: white;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 30px;
  padding: 10px 20px;
  font-size: 1.5rem;
`;

const RadioLabel = styled.label`
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 5px 0;

  * {
    width: auto;
  }
`;

const FreeShippingNotice = styled.div`
  background-color: #ceebdf;
  padding: 12px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .small {
    font-size: 1.3rem;
  }
`;
